import { BaseContent } from '../components/BaseContent';
import CreateDataSourceSettingForm from '../components/layoutparts/CreateDataSourceSettingForm';
import EditDataSourceSettingForm from '../components/layoutparts/EditDataSourceSettingForm';
import { useParams } from 'react-router-dom';

export default function DataSourceSettingPage() {
  const { connectionId: connectionId } = useParams();

  return (
    <BaseContent title="データソース設定">
      {connectionId === undefined ? (
        <CreateDataSourceSettingForm connection={undefined} />
      ) : (
        <EditDataSourceSettingForm connectionId={connectionId} />
      )}
    </BaseContent>
  );
}
