import { ConnectionReq } from '../../types/Responses';
import { DataSource } from '../../types/dataSource';

const DEFAULT_SNOWFLAKE_PORT = 443;

export const extractConnectionReqFromFormik = (
  connectionType: DataSource,
  formikValues: ConnectionReq
): ConnectionReq => {
  if (connectionType === DataSource.SnowFlake) {
    return {
      name: formikValues.name,
      user: formikValues.user,
      passwd: formikValues.passwd,
      db: formikValues.db,
      account: formikValues.account,
      role: formikValues.role,
      warehouse: formikValues.warehouse,
      schema: formikValues.schema,
      port: formikValues.port ? Number(formikValues.port) : DEFAULT_SNOWFLAKE_PORT,
    };
  }
  return {
    name: formikValues.name,
    host: formikValues.host,
    port: Number(formikValues.port),
    user: formikValues.user,
    passwd: formikValues.passwd,
    db: formikValues.db,
    redshiftIamRole: formikValues.redshiftIamRole,
  };
};
