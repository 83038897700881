import { Dispatch, useContext } from 'react';

import { createContext } from 'react';

type TAuth = {
  isSignedIn: boolean;
  isLoading: boolean;
  role: string;
  userId: string;
  userName: string;
  email: string;
  leaderGroupList: string[];
};
type TAuthContext = {
  auth: TAuth;
  setAuth: Dispatch<React.SetStateAction<TAuth>>;
};

export const initialAuthValue = {
  isSignedIn: false,
  isLoading: false,
  role: '',
  userId: '',
  userName: '',
  email: '',
  leaderGroupList: [] as string[],
};

export const AuthContext = createContext({} as TAuthContext);
export const useAuth = () => useContext(AuthContext);
