import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import { Button, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ConfirmDialog from './ConfirmDialog';
import jwtAxios from '../../common/axios';
import { useNavigate } from 'react-router-dom';
import { useConnectionsSwr } from '../../common/swr/useConnectionsSwr';
import { useQueryDeleteSwr } from '../../common/swr/useQueryDeleteSwr';
import { useSnackbar } from '../../hooks/SnackBar';
import { useEffect, useState } from 'react';
import { BaseDataGrid } from '../BaseDataGrid';
import { useProgress } from '../../hooks/useProgress';
import { Connections } from '../../common/types';
import { useSWRConfig } from 'swr';
import { MAX_ARRAY_SIZE } from '../../common/const/maxArraySize';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/use-auth';

export default function DataSourceTable() {
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();
  const auth = useAuth();
  const navigation = useNavigate();

  // APIs
  const { connections, isLoading, isError, mutate } = useConnectionsSwr();

  const { cache } = useSWRConfig();

  const [deletedDataSource, setDeletedDataSource] = useState<Connections | undefined>(undefined);

  const {
    queries,
    isLoading: isLoadingQueryDelete,
    isError: isErrorQueryDelete,
  } = useQueryDeleteSwr(deletedDataSource?.connectionId ?? '');

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(`データソース一覧取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  useEffect(() => {
    if (isErrorQueryDelete)
      showSnackbar(
        `削除対象条件数取得APIエラー (${isErrorQueryDelete.response.data['detail']})`,
        'error'
      );
  }, [isErrorQueryDelete, showSnackbar]);

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleConfirmDelete = (dataSource: Connections) => {
    setDeletedDataSource(dataSource);
    setDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeletedDataSource(undefined);
    setDialogOpen(false);
  };

  const handleDelete = () => {
    setDialogOpen(false);
    showProgress(true);

    const connectionType = deletedDataSource?.type ?? '';

    jwtAxios
      .delete(
        `/api/${connectionType.toLocaleLowerCase()}/connection/?connection_id=${
          deletedDataSource?.connectionId
        }`
      )
      .then(function () {
        showSnackbar('データソースを削除しました。', 'success');
        if (connections.length < MAX_ARRAY_SIZE) {
          const updatedUser = connections.filter((connection) => {
            return connection.connectionId !== deletedDataSource?.connectionId;
          });
          mutate(updatedUser, true);
        } else {
          cache.delete(`api/connections/`);
        }
        // 再検証
        void mutate();
      })
      .catch(function (error) {
        showProgress(false);
        showSnackbar(
          `データソースの削除に失敗しました。 (${error.response.data['detail']})`,
          'error'
        );
      })
      .finally(function () {
        setDeletedDataSource(undefined);
        showProgress(false);
      });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'データソース名',
      flex: 2,
      renderCell: (params: GridRenderCellParams<Connections & { account?: string }>) => {
        return (
          <div>
            <Typography>{params.row.name}</Typography>
            <Typography variant="caption">
              {params.row.host ? params.row.host : params.row.account ?? ''}
            </Typography>
          </div>
        );
      },
    },
    { field: 'type', headerName: 'データソースタイプ', flex: 1 },
    { field: 'db', headerName: 'データベース', flex: 1 },
    { field: 'user', headerName: 'ユーザー名', flex: 1 },
    {
      field: 'connectionId',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.5,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Connections>) => (
        <div>
          {/* 編集 */}
          <Tooltip title="編集" placement="top">
            <IconButton
              aria-label="expand row"
              size="small"
              sx={{ marginRight: 2 }}
              onClick={() => {
                navigation(`/datasource/new/${params.value}`, {
                  state: { connectionType: params.row.type },
                });
              }}
              disabled={auth.license?.isExpired}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          {/* 削除 */}
          <Tooltip title="削除" placement="top">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleConfirmDelete(params.row)}
            >
              <DeleteOutline />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      {!isLoading ? (
        <Grid container justifyContent="flex-end">
          <Button
            sx={{ position: 'relative', bottom: '50px' }}
            type="submit"
            variant="contained"
            component={Link}
            to={`/datasource/new/`}
            color="primary"
            disabled={
              auth.license?.isExpired
                ? true
                : auth.license?.datasourceNum
                  ? auth.license.datasourceNum <= connections.length
                  : true
            }
          >
            新規データソース作成
          </Button>
        </Grid>
      ) : null}

      <div style={{ height: 800, width: '100%' }}>
        <BaseDataGrid
          loading={isLoading}
          rows={connections ? connections : []}
          columns={columns}
          getRowIdFunc={(row: Connections) => row.connectionId}
        />
      </div>
      <ConfirmDialog
        isOpen={dialogOpen}
        tile={deletedDataSource?.name ? `${deletedDataSource?.name}を削除しますか？` : ''}
        okNode={
          <Button
            onClick={handleDelete}
            variant="contained"
            color="error"
            autoFocus
            disabled={isLoadingQueryDelete || queries?.deleteNum === undefined}
          >
            削除
          </Button>
        }
        cancelFunc={handleCancelDelete}
        maxWidth={'sm'}
      >
        {queries?.deleteNum === 0 ? (
          <>削除した項目は、復元できません。</>
        ) : (
          <>
            このデータソースを参照するデータ抽出条件が
            <span style={{ color: 'red', fontWeight: 'bold' }}>{queries?.deleteNum}</span>
            件あります。
            <br />
            データソースを削除するとこれらも削除されます。
            <br />
            削除した項目は、復元できません。
          </>
        )}
      </ConfirmDialog>
    </div>
  );
}
