import {
  ArrowForward,
  ArrowBack,
  CancelSharp,
  Logout,
  SyncLock,
  WarningAmberOutlined,
  WarningAmber,
  ErrorOutline,
  HelpOutline,
  LockReset,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  DialogContentText,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  Menu,
  MenuItem,
  Popover,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { GetPresignedUrlReq, SubscribeMessage } from '../common/types/Responses';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ConfirmDialog from './layoutparts/ConfirmDialog';
import { AccountCircleOutlined, Download, NotificationsNoneOutlined } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import Timer from './Timer';
import jwtAxios from '../common/axios';
import { useAuth } from '../hooks/use-auth';
import { useSnackbar } from '../hooks/SnackBar';
import { utcToJstFormat } from '../common/func/functions';
import { useWebSocket, useWebSocketClient } from '../hooks/useWebSocket';
import { NavLink } from 'react-router-dom';
import { useProgress } from '../hooks/useProgress';
import { generateUuid } from '../modules/common';
import { keysToCamel } from '../common/func/converter';
import { VariantType, useSnackbar as useNotistackSnackbar } from 'notistack';
import { useLicenseSwr } from '../common/swr/useLicenseSwr';
import { AppBar as MUIAppBar } from '@mui/material';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const AppBar: React.FC<Props> = ({ open, setOpen }: Props) => {
  const auth = useAuth();
  const { enqueueSnackbar } = useNotistackSnackbar();
  const { isLicValidity, showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();

  const [toggleIcon, setToggleIcon] = useState(<MenuIcon />);

  const { license, isLoading, isValidating } = useLicenseSwr(true);
  useEffect(() => {
    if (!isLoading) {
      auth.setLicenseDataRevalidateOnMount(license);
    }
  }, [isLoading, isValidating]);

  const websocketClient = useWebSocketClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const API_GW_WS_ENDPOINT = (window as any)._env_.REACT_APP_API_GW_WS_ENDPOINT;
  useWebSocket(API_GW_WS_ENDPOINT, () => {
    const subscribeMessage: SubscribeMessage | null = websocketClient.data
      ? (websocketClient.data[API_GW_WS_ENDPOINT] as SubscribeMessage)
      : null;

    if (subscribeMessage?.downloadState === '2') {
      const variant: VariantType = 'info';
      enqueueSnackbar(
        `${subscribeMessage.queryName}のデータ抽出ができました。詳細は画面右上の通知を確認してください。`,
        {
          variant,
        }
      );
    } else if (subscribeMessage?.downloadState === '3') {
      const variant: VariantType = 'warning';
      enqueueSnackbar(
        `${subscribeMessage.queryName}のデータ抽出に失敗しました。${subscribeMessage.errorMsg}`,
        { variant }
      );
    }
  });

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // 通知
  const [notifyAnchorEl, setNotifyAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleNotifyClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setNotifyAnchorEl(event.currentTarget);
  };

  const handleNotifyClose = () => {
    websocketClient.readMessages();
    setNotifyAnchorEl(null);
  };
  const notifyOpen = Boolean(notifyAnchorEl);
  const notifyId = open ? 'simple-popover' : undefined;

  // Download
  const [download, setDownload] = useState({ url: '', left: 0 });
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const onClickDownload = (message: SubscribeMessage) => {
    showProgress(true);
    const params: GetPresignedUrlReq = {
      userName: message.userName,
      timestamp: message.timestamp,
    };
    jwtAxios
      .get(`api/presigned_url/?user_name=${params.userName}&timestamp=${params.timestamp}`)
      .then((result) => {
        const data = keysToCamel(result.data);
        toggleIsLicValidity(false);
        if (result.data.licmessage) {
          showSnackbar(`ライセンス有効期限が1ヶ月を切っています`, 'warning');
        }

        showProgress(false);
        const presignedUrl = data.presignedUrl;
        setDownload({ url: presignedUrl, left: 60 });
        setDialogOpen(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
        showProgress(false);
        showSnackbar(`データの取得に失敗しました。 (${error.response.data['detail']})`, 'error');
      });
  };

  const [reRendering, setReRendering] = useState<number>(1);

  const onClickDeleteMessage = (message: SubscribeMessage) => {
    websocketClient.deleteMessage(message);
    setReRendering(reRendering + 1);
  };

  const onClickDeleteAllMessage = () => {
    websocketClient.deleteAllMessage();
    setReRendering(reRendering + 1);
  };

  const handleSignOut = () => {
    auth.signOut();
  };

  const [dialogResetMfaOpen, setDialogResetMfaOpen] = useState(false);
  const handleResetMfaOpenClose = () => {
    setDialogResetMfaOpen(false);
  };

  const onClickResetMfa = () => {
    showProgress(true);
    setDialogResetMfaOpen(false);
    jwtAxios
      .post(`/api/mfa/user_reset/`)
      .then(() => {
        handleSignOut();
        setDialogResetMfaOpen(false);
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        showSnackbar(`MFAリセットできません。 (${error.response.data['detail']})`, 'error');
      })
      .finally(() => {
        showProgress(false);
      });
  };

  return (
    <StyledAppBar position="absolute" open={open} elevation={0}>
      {auth.license?.licenseKey && auth.license.isExpired ? (
        <Box sx={{ flexGrow: 1 }}>
          <MUIAppBar position="static" sx={{ backgroundColor: '#d32f2f' }}>
            <Toolbar>
              <ErrorOutline sx={{ mr: 2 }} />
              <Typography variant="h6" component="div">
                ライセンスが切れています
              </Typography>
            </Toolbar>
          </MUIAppBar>
        </Box>
      ) : null}

      {auth.license?.licenseKey && !auth.license.isExpired && auth.license.isApproaching ? (
        <Box sx={{ flexGrow: 1 }}>
          <MUIAppBar position="static" sx={{ backgroundColor: '#ed6c02' }}>
            <Toolbar>
              <WarningAmber sx={{ mr: 2 }} />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                ライセンス有効期限が1ヶ月を切っています
              </Typography>
            </Toolbar>
          </MUIAppBar>
        </Box>
      ) : null}

      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
          backgroundColor: '#263238',
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px',
          }}
          onMouseEnter={() => setToggleIcon(open ? <ArrowBack /> : <ArrowForward />)}
          onMouseLeave={() => setToggleIcon(<MenuIcon />)}
        >
          {toggleIcon}
        </IconButton>
        <img src={`${process.env.PUBLIC_URL}/logo_w.png`} />
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1, ml: 2 }}>
          CSA Downloader
        </Typography>
        {isLicValidity ? (
          <Box sx={{ color: 'warning.main' }}>
            <Badge>
              <WarningAmberOutlined />
              <Typography sx={{ lineHeight: '1.7' }} variant="subtitle2">
                ライセンスが無効です
              </Typography>
            </Badge>
          </Box>
        ) : null}
        {API_GW_WS_ENDPOINT ? (
          <IconButton color="inherit" aria-describedby={notifyId} onClick={handleNotifyClick}>
            <Badge badgeContent={websocketClient.getMessages().newMessageNumber} color="secondary">
              <NotificationsNoneOutlined />
            </Badge>
          </IconButton>
        ) : null}
        <Popover
          id={notifyId}
          open={notifyOpen}
          anchorEl={notifyAnchorEl}
          onClose={handleNotifyClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <List sx={{ padding: 0, width: 400 }}>
            <ListSubheader sx={{ paddingY: 1 }}>
              <Grid container alignItems={'center'}>
                <Grid item sx={{ marginRight: 'auto' }}>
                  <Typography variant="subtitle1">
                    <strong>お知らせ</strong>
                    {websocketClient.getMessages().messages.length === 0 ? (
                      <strong>はまだありません</strong>
                    ) : null}
                  </Typography>
                </Grid>
                <Grid item>
                  {websocketClient.getMessages().messages.length === 0 ? null : (
                    <Button onClick={onClickDeleteAllMessage}>すべて削除</Button>
                  )}
                </Grid>
              </Grid>
            </ListSubheader>
            {websocketClient.getMessages().messages.length ? <Divider /> : null}
            {websocketClient.getMessages().messages.map((message, index) => {
              let status = '';
              if (message.downloadState === '2') {
                status = '完了';
              } else if (message.downloadState === '3') {
                status = '失敗';
              }
              const dateStr = utcToJstFormat(message.updatedAt);
              return (
                <div key={generateUuid()}>
                  <ListItem sx={{ padding: 0 }}>
                    <Card
                      elevation={0}
                      sx={{
                        width: 400,
                        paddingX: 1,
                        backgroundColor: message.read ? '#eeeeee' : '',
                        borderRadius: 0,
                      }}
                    >
                      <CardHeader
                        action={
                          <Tooltip title="通知を削除" placement="top">
                            <IconButton
                              edge="end"
                              aria-label="download"
                              onClick={() => {
                                onClickDeleteMessage(message);
                              }}
                              sx={{ marginLeft: 2 }}
                            >
                              <CancelSharp />
                            </IconButton>
                          </Tooltip>
                        }
                        title={
                          <Typography variant="body1">
                            <strong>{message.queryName}</strong> のデータ抽出が{' '}
                            <strong>{status}</strong> しました。
                            {status === '完了' && 'ダウンロードできます。'}
                            {status === '失敗' && message.errorMsg}
                          </Typography>
                        }
                      />
                      <CardActions
                        disableSpacing
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        {message.downloadState === '2' ? (
                          <Button
                            size="small"
                            startIcon={<Download />}
                            onClick={() => {
                              onClickDownload(message);
                            }}
                            sx={{ marginRight: 'auto' }}
                            disabled={auth.license?.isExpired}
                          >
                            <strong>ダウンロード</strong>
                          </Button>
                        ) : null}
                        <Typography variant="caption">{dateStr}</Typography>
                      </CardActions>
                    </Card>
                  </ListItem>
                  {websocketClient.getMessages().messages.length === index + 1 ? null : <Divider />}
                </div>
              );
            })}
          </List>
        </Popover>

        <IconButton color="inherit" onClick={handleClick}>
          <AccountCircleOutlined sx={{ margin: '0 8px 0 0' }} />
          <Typography component="h2" variant="h6" color="inherit">
            {auth.userName}
          </Typography>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <a
            target="_blank"
            href="https://docs.csa-dl.cs-analytics.info/v1.1.0/index.html"
            style={{ textDecoration: 'none', color: '#000000' }}
            rel="noreferrer"
          >
            <MenuItem>
              <HelpOutline sx={{ marginRight: 1 }} />
              ドキュメント
            </MenuItem>
          </a>
          {auth.isSettingMfa ? (
            <MenuItem
              onClick={() => {
                setDialogResetMfaOpen(true);
              }}
            >
              <LockReset sx={{ marginRight: 1 }} />
              MFA
            </MenuItem>
          ) : null}
          <NavLink to={'/passwordchange'} style={{ textDecoration: 'none', color: '#000000' }}>
            <MenuItem>
              <SyncLock sx={{ marginRight: 1 }} />
              パスワード変更
            </MenuItem>
          </NavLink>
          <MenuItem onClick={handleSignOut}>
            <Logout sx={{ marginRight: 1 }} />
            サインアウト
          </MenuItem>
        </Menu>
      </Toolbar>
      <ConfirmDialog
        isOpen={dialogOpen}
        tile={'CSVのダウンロード'}
        okNode={
          <Button
            variant="contained"
            startIcon={<Download />}
            href={download.url}
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            ダウンロード
          </Button>
        }
        cancelFunc={handleDialogClose}
        maxWidth={'xs'}
      >
        ダウンロード有効時間：残り
        <Timer seconds={download.left} doFinish={() => setDialogOpen(false)} />秒
      </ConfirmDialog>

      <ConfirmDialog
        isOpen={dialogResetMfaOpen}
        okNode={
          <Button
            variant="contained"
            href={download.url}
            onClick={() => {
              onClickResetMfa();
            }}
          >
            MFAリセット
          </Button>
        }
        cancelFunc={handleResetMfaOpenClose}
      >
        <Stack gap={2}>
          <Stack>
            <DialogContentText>MFAをリセットできます。</DialogContentText>
            <DialogContentText>認証アプリを変更する場合などにご利用ください。</DialogContentText>
          </Stack>
          <DialogContentText>
            MFAをリセットすると即座にサインアウトします。再度サインインして認証アプリの再設定をしてください。
          </DialogContentText>
          <DialogContentText>MFAをリセットしますか？</DialogContentText>
        </Stack>
      </ConfirmDialog>
    </StyledAppBar>
  );
};

export default AppBar;
