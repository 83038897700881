import CreateGroupForm from './CreateGroupForm';
import { useGroupSwr } from '../../common/swr/useGroupSwr';
import { useEffect } from 'react';
import { useSnackbar } from '../../hooks/SnackBar';
import Progress from './Progress';

type Props = {
  firstId: string;
};

export default function EditGroupForm({ firstId: firstId }: Props) {
  const { showSnackbar } = useSnackbar();

  // APIs
  const { group, isLoading, isError, isValidating } = useGroupSwr(firstId, true);

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(`グループ取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  return (
    <>{isLoading || isValidating ? <Progress open={true} /> : <CreateGroupForm group={group} />}</>
  );
}
