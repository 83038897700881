import { GetQueriesResp } from '../types/Responses';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWR from 'swr';

const config: SWRConfiguration = {};

export const useQueriesSwr = () => {
  const { data, error, mutate } = useSWR<GetQueriesResp>(
    `api/queries/`,
    fetcherKeysToCamel,
    config
  );
  const queries = data ? data : [];
  return {
    queries: queries.sort(
      (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    ),
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
