import {
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useMfaConfigSwr } from '../common/swr/useMfaConfigSwr';
import Progress from '../components/layoutparts/Progress';
import { useProgress } from '../hooks/useProgress';
import jwtAxios from '../common/axios';
import { useSnackbar } from '../hooks/SnackBar';
import { useAuth } from '../hooks/use-auth';
import ConfirmDialog from '../components/layoutparts/ConfirmDialog';

export default function SecurityPage() {
  const { mfaConfig, isLoading } = useMfaConfigSwr();
  const [mfaConfiguration, setMfaConfiguration] = useState('');
  const { showProgress } = useProgress();
  const { showSnackbar } = useSnackbar();
  const auth = useAuth();

  const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);

  const openDialogConfirmOpen = () => {
    setDialogConfirmOpen(true);
  };

  const closeDialogConfirmOpen = () => {
    setDialogConfirmOpen(false);
  };

  useEffect(() => setMfaConfiguration(mfaConfig.mfaConfiguration), [mfaConfig]);

  const handleChangeMfaSetting = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMfaConfiguration((event.target as HTMLInputElement).value);
  };
  const setMfa = () => {
    showProgress(true);
    closeDialogConfirmOpen();
    jwtAxios
      .put(`/api/mfa/system_config/`, { mfa_configuration: mfaConfiguration })
      .then(async function () {
        showSnackbar('MFA設定が完了しました。', 'success');
        await auth.fetchUser();
      })
      .catch(function (error) {
        showSnackbar(`MFA設定に失敗しました。 (${error.response.data['detail']})`, 'error');
      })
      .finally(() => {
        showProgress(false);
      });
  };

  return isLoading ? (
    <Progress open={true} />
  ) : (
    <Grid container>
      <Grid item xs={2} md={2} lg={1}>
        <Stack alignItems="center" justifyContent="center" sx={{ minHeight: '42px' }}>
          <Typography>MFA</Typography>
        </Stack>
      </Grid>
      <Grid item xs={10} md={10} lg={11}>
        <Grid>
          <FormControl>
            <RadioGroup value={mfaConfiguration} onChange={handleChangeMfaSetting}>
              <FormControlLabel value={'OPTIONAL'} control={<Radio />} label="使用する" />
              <FormControlLabel value={'OFF'} control={<Radio />} label="使用しない" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid mt={2}>
          <Button variant="contained" sx={{ minWidth: 120 }} onClick={openDialogConfirmOpen}>
            保存
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        isOpen={dialogConfirmOpen}
        tile={'MFA利用ステータスを変更しますか？'}
        okNode={
          <Button variant="contained" onClick={setMfa}>
            変更
          </Button>
        }
        cancelFunc={closeDialogConfirmOpen}
        maxWidth={'xs'}
      ></ConfirmDialog>
    </Grid>
  );
}
