import { SchemaTableSetting } from '../../common/types/Responses';
import { Button, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction, useState } from 'react';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';
import utc from 'dayjs/plugin/utc';
import Timeline from '@mui/lab/Timeline';
import { TimelineConnector, TimelineItem, timelineItemClasses, TimelineSeparator } from '@mui/lab';
import { generateUuid } from '../../modules/common';
import {
  EQUAL_OPERATOR,
  JOIN_TYPES,
  MAX_NUMBER_OF_JOINED_TABLES,
} from '../../common/const/joinTable';
import CancelIcon from '@mui/icons-material/Cancel';
import TableChartIcon from '@mui/icons-material/TableChart';
import ConfirmDialog from './ConfirmDialog';
import { Join, JoinedTable, SimpleQueryForm, TableInfo } from '../../common/types';
import ChooseTableDialog from './ChooseTableDialog';
import JoinSettingDialog from './JoinSettingDialog';
import JoinTableIcon from '../join-icons';

dayjs.extend(utc);

type Props = {
  formik: FormikProps<SimpleQueryForm>;
  schemaRows: SchemaTableSetting[];
  fetchedTables: TableInfo[];
  joinedTables: JoinedTable[];
  handleJoinedTables: Dispatch<SetStateAction<JoinedTable[]>>;
  isLoading?: boolean;
  isColumnLoading: boolean;
};

const SchemaConditionParts: React.FC<Props> = ({
  formik,
  schemaRows,
  fetchedTables,
  joinedTables,
  handleJoinedTables,
  isLoading,
  isColumnLoading,
}: Props) => {
  // 選択している行
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
  const [selectedMainTable, setSelectedMainTable] = useState<SchemaTableSetting>();
  const [selectedJoinTable, setSelectedJoinTable] = useState<SchemaTableSetting>();
  // ダイアログの表示/非表示
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  // Popup: Table Options For Joining
  const [joinTableDialogOpen, setJoinTableDialogOpen] = useState<boolean>(false);
  // Popup: Change Main Table Confirmation
  const [confirmChangeTableDialogOpen, setConfirmChangeTableDialogOpen] = useState<boolean>(false);
  // Popup: Setting Join Type and Conditions
  const [joinDialogOpen, setJoinDialogOpen] = useState<boolean>(false);
  // Index of the currently-modified join
  const [joinIndex, setJoinIndex] = useState<number | undefined>(undefined);
  // Track data of the currently-modified join
  const [joinSetting, setJoinSetting] = useState<Join>({
    schemaN: '',
    tableUuid: '',
    tableN: '',
    type: JOIN_TYPES.INNER_JOIN.CODE,
    conditions: [],
  });

  // ダイアログを開く
  const onClickDialogOpen = () => {
    setDialogOpen(true);
  };

  // ダイアログを開く（変更）
  const onClickSettingWhereCondition = (condition: SchemaTableSetting) => {
    setSelectedMainTable({ schemaN: condition.schemaN, tableN: condition.tableN });
    setSelectionModel([`${condition.schemaN + condition.tableN}`]);
    setDialogOpen(true);
  };

  // 設定するボタン
  const handleDialogOk = () => {
    if (formik.values.simple.join.length > 0) {
      setConfirmChangeTableDialogOpen(true);
    } else {
      changeMainJoinTable();
    }
  };

  const changeMainJoinTable = () => {
    formik.setFieldValue('schemaN', selectedMainTable?.schemaN);
    formik.setFieldValue('tableN', selectedMainTable?.tableN);

    // Reset Join Condition
    formik.setFieldValue('simple.join', []);
    // 抽出する項目の設定 リセット
    formik.setFieldValue('simple.select', []);
    // 抽出条件の設定 リセット
    formik.setFieldValue('simple.where', []);
    // 並び順の設定 リセット
    formik.setFieldValue('simple.orderby', []);

    const mainTableUuid = generateUuid();
    if (selectedMainTable)
      handleJoinedTables([
        {
          ...selectedMainTable,
          tableUuid: mainTableUuid,
          columns: fetchedTables
            .find(
              (e) =>
                e.schemaN === selectedMainTable?.schemaN && e.tableN === selectedMainTable?.tableN
            )
            ?.columns?.map((e) => {
              return {
                columnUuid: generateUuid(),
                tableUuid: mainTableUuid,
                name: e.name,
                dataType: e.dataType[0],
              };
            }),
        },
      ]);

    setConfirmChangeTableDialogOpen(false);
    handleDialogClose();
  };

  // キャンセルボタン
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedMainTable(undefined);
    setSelectionModel([]);
  };

  const onClickJoiningTables = (index: number | undefined = undefined) => {
    if (index !== undefined) {
      setJoinIndex(index);
      setSelectedJoinTable({
        schemaN: formik.values.simple.join[index].schemaN,
        tableN: formik.values.simple.join[index].tableN,
      });
      setSelectionModel([
        `${formik.values.simple.join[index].schemaN}${formik.values.simple.join[index].tableN}`,
      ]);
    }
    setJoinTableDialogOpen(true);
  };

  const handleJoinTableDialogOk = () => {
    if (selectedJoinTable) {
      const newJoinTable = {
        ...selectedJoinTable,
        tableUuid: generateUuid(),
        type: JOIN_TYPES.INNER_JOIN.CODE,
        conditions: [
          {
            localKey: undefined,
            foreignKey: undefined,
            operator: EQUAL_OPERATOR,
          },
        ],
      };

      let joinSetting = formik.values.simple.join;
      if (joinIndex !== undefined) {
        joinSetting = joinSetting.map((join) => {
          const newConditions = join.conditions.filter(
            (condition) =>
              condition.localKey?.tableUuid !== formik.values.simple.join[joinIndex].tableUuid
          );
          return {
            ...join,
            conditions:
              newConditions.length > 0
                ? newConditions
                : [{ localKey: undefined, foreignKey: undefined, operator: EQUAL_OPERATOR }],
          };
        });
        joinSetting[joinIndex] = newJoinTable;

        formik.setFieldValue('simple.join', joinSetting);
      } else if (formik.values.simple.join.length < MAX_NUMBER_OF_JOINED_TABLES - 1) {
        joinSetting.push(newJoinTable);
      }
      updateJoinedTables(joinSetting);
    }
    handleJoinTableDialogClose();
  };

  const updateJoinedTables = (joinSetting: Join[]) => {
    const tables = [
      joinedTables[0],
      ...joinSetting.map((e: Join, index: number) => {
        return {
          schemaN: e.schemaN,
          tableUuid: e.tableUuid,
          tableN: e.tableN,
          alias: `JOIN_TABLE_${index + 1}`,
          columns:
            joinedTables.find((e1) => e1.tableUuid === e.tableUuid)?.columns ??
            fetchedTables
              .find((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN)
              ?.columns?.map((e1) => {
                return {
                  columnUuid: generateUuid(),
                  tableUuid: e.tableUuid,
                  name: e1.name,
                  dataType: e1.dataType[0],
                };
              }),
        };
      }),
    ];

    formik.setFieldValue('simple', {
      join: joinSetting,
      select: formik.values.simple.select.filter((e) =>
        tables.some((e1) => e1.tableUuid === e.tableUuid)
      ),
      where: formik.values.simple.where.filter((e) =>
        tables.some((e1) => e1.tableUuid === e.tableUuid)
      ),
      orderby: formik.values.simple.orderby.filter((e) =>
        tables.some((e1) => e1.tableUuid === e.tableUuid)
      ),
    });
    handleJoinedTables(tables);
  };

  const handleJoinTableDialogClose = () => {
    setJoinTableDialogOpen(false);
    setJoinIndex(undefined);
    setSelectedJoinTable(undefined);
    setSelectionModel([]);
  };

  const handleChangeJoinSetting = (index: number) => {
    setJoinIndex(index);
    setJoinSetting(JSON.parse(JSON.stringify(formik.values.simple.join[index])));
    setJoinDialogOpen(true);
  };

  const handleJoinDialogOk = () => {
    let finalSetting = joinSetting.conditions.filter((e) => e.localKey || e.foreignKey);
    if (finalSetting.length === 0) {
      finalSetting = [{ localKey: undefined, foreignKey: undefined, operator: EQUAL_OPERATOR }];
    }
    if (joinIndex !== undefined) {
      formik.values.simple.join[joinIndex] = { ...joinSetting, conditions: finalSetting };
    }
    handleJoinDialogClose();
  };

  const handleJoinDialogClose = () => {
    setJoinDialogOpen(false);
    setJoinIndex(undefined);
    setJoinSetting({
      schemaN: '',
      tableUuid: '',
      tableN: '',
      type: JOIN_TYPES.INNER_JOIN.CODE,
      conditions: [],
    });
  };

  const handleRemovingJoinedTable = (index: number) => {
    const joinSetting = formik.values.simple.join.map((join) => {
      const newConditions = join.conditions.filter(
        (condition) => condition.localKey?.tableUuid !== formik.values.simple.join[index].tableUuid
      );
      return {
        ...join,
        conditions:
          newConditions.length > 0
            ? newConditions
            : [{ localKey: undefined, foreignKey: undefined, operator: EQUAL_OPERATOR }],
      };
    });

    formik.setFieldValue(
      'simple.join',
      joinSetting.filter((e: Join, i: number) => i !== index)
    );
    updateJoinedTables(joinSetting.filter((e: Join, i: number) => i !== index));
  };

  return (
    <>
      <Grid container marginTop={4} justifyContent="center">
        <Grid item xs={12}>
          <Typography>
            スキーマとテーブル名 <span style={{ color: 'red' }}>*</span>
          </Typography>
        </Grid>
      </Grid>

      {formik.values.schemaN ? (
        <div style={{ overflowX: 'auto' }}>
          <Grid container flexWrap={'nowrap'}>
            <Grid item>
              <Paper variant="outlined">
                <Grid container padding={2} alignItems="center" flexWrap={'nowrap'}>
                  <Grid item paddingRight={2}>
                    <Typography
                      variant="caption"
                      whiteSpace={'nowrap'}
                      style={{ color: '#00000080' }}
                    >
                      スキーマ
                    </Typography>
                    <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>
                      {formik.values.schemaN}
                    </Typography>
                  </Grid>

                  <Grid item paddingRight={2}>
                    <Typography
                      variant="caption"
                      whiteSpace={'nowrap'}
                      style={{ color: '#00000080' }}
                    >
                      テーブル
                    </Typography>
                    <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>
                      {formik.values.tableN}
                    </Typography>
                  </Grid>

                  <Grid item paddingRight={1}>
                    <Button
                      variant="text"
                      style={{ color: '#5F6368' }}
                      onClick={() =>
                        onClickSettingWhereCondition({
                          schemaN: formik.values.schemaN,
                          tableN: formik.values.tableN,
                        })
                      }
                    >
                      <TableChartIcon />
                    </Button>
                  </Grid>

                  <Grid
                    item
                    style={{
                      ...(formik.values.simple.join.length >= MAX_NUMBER_OF_JOINED_TABLES - 1 && {
                        cursor: 'not-allowed',
                      }),
                    }}
                  >
                    <Button
                      variant="outlined"
                      style={{
                        borderColor: '#5F6368',
                        ...(formik.values.simple.join.length >= MAX_NUMBER_OF_JOINED_TABLES - 1 && {
                          backgroundColor: 'rgb(95 99 104 / 20%)',
                          cursor: 'not-allowed',
                        }),
                      }}
                      disabled={formik.values.simple.join.length >= MAX_NUMBER_OF_JOINED_TABLES - 1}
                      onClick={() => onClickJoiningTables()}
                    >
                      <JoinTableIcon type={'icon'} className={'join-icon'} />
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {formik.values.simple.join.length > 0 && (
              <Grid item display={'inline-flex'} flexWrap={'nowrap'}>
                <Grid container alignItems="center" flexWrap={'nowrap'}>
                  {formik.values.simple.join.length > 1 && (
                    <Grid item alignSelf="flex-start">
                      <hr className={'parent-link'} />
                    </Grid>
                  )}
                  <Grid item>
                    <Timeline
                      style={{ marginBlock: 0, padding: 0 }}
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        }, // Set padding of timeline to 0
                      }}
                    >
                      {formik.values.simple.join.map((value, index) => {
                        const isFirst = index === 0;
                        const isLast =
                          index !== 0 && index === formik.values.simple.join.length - 1;
                        const isError = value.conditions.some((e) => !e.localKey || !e.foreignKey);
                        return (
                          <TimelineItem
                            key={`join_diagram_${value.tableUuid}_${index}`}
                            style={{ marginBottom: isLast ? '20px' : '0' }}
                          >
                            <>
                              {formik.values.simple.join.length > 1 && (
                                <TimelineSeparator
                                  style={{
                                    marginTop: isFirst ? '40px' : '0',
                                    marginBottom: isLast ? '40px' : '0',
                                  }}
                                >
                                  <TimelineConnector />
                                </TimelineSeparator>
                              )}

                              <Grid
                                container
                                id={`simple.join[${index}]`}
                                alignItems={'center'}
                                marginTop={index > 0 ? '20px' : '0'}
                                flexWrap={'nowrap'}
                              >
                                <Grid item>
                                  <JoinTableIcon
                                    type={value.type}
                                    error={isError}
                                    className={
                                      'join-type' +
                                      (isFirst
                                        ? ' first-join'
                                        : isLast
                                          ? ' last-join'
                                          : ' middle-join')
                                    }
                                    onClick={() => handleChangeJoinSetting(index)}
                                  />
                                  <hr
                                    className="join-link"
                                    style={{
                                      borderColor: isError ? 'red' : '',
                                    }}
                                  />
                                </Grid>

                                <Grid item className={'join-table'}>
                                  <Paper
                                    variant="outlined"
                                    style={{ borderColor: isError ? 'red' : 'rgba(0, 0, 0, 0.12)' }}
                                  >
                                    <Grid
                                      container
                                      padding={2}
                                      alignItems="center"
                                      flexWrap={'nowrap'}
                                    >
                                      <Grid item paddingRight={2}>
                                        <Typography
                                          variant="caption"
                                          whiteSpace={'nowrap'}
                                          style={{ color: '#00000080' }}
                                        >
                                          スキーマ
                                        </Typography>
                                        <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>
                                          {value.schemaN}
                                        </Typography>
                                      </Grid>

                                      <Grid item paddingRight={2}>
                                        <Typography
                                          variant="caption"
                                          whiteSpace={'nowrap'}
                                          style={{ color: '#00000080' }}
                                        >
                                          テーブル
                                        </Typography>
                                        <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>
                                          {value.tableN}
                                        </Typography>
                                      </Grid>

                                      <Grid item paddingRight={2}>
                                        <Typography
                                          variant="caption"
                                          whiteSpace={'nowrap'}
                                          style={{ color: '#00000080' }}
                                        >
                                          テーブル別名
                                        </Typography>
                                        <Typography fontWeight={'bold'} whiteSpace={'nowrap'}>
                                          {
                                            joinedTables.find(
                                              (e) => e.tableUuid === value.tableUuid
                                            )?.alias
                                          }
                                        </Typography>
                                      </Grid>

                                      <Grid item paddingRight={1}>
                                        <Button
                                          variant="text"
                                          style={{ color: '#5F6368' }}
                                          onClick={() => onClickJoiningTables(index)}
                                        >
                                          <TableChartIcon />
                                        </Button>
                                      </Grid>

                                      <Grid item>
                                        <Tooltip title="削除" placement="top">
                                          <IconButton
                                            style={{ color: '#5F6368' }}
                                            onClick={() => handleRemovingJoinedTable(index)}
                                          >
                                            <CancelIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Grid>

                                {isError && (
                                  <Grid item paddingLeft={1} alignItems="center">
                                    <Grid
                                      container
                                      spacing={1}
                                      alignItems="center"
                                      flexWrap={'nowrap'}
                                    >
                                      <Grid item>
                                        <img
                                          src={`${process.env.PUBLIC_URL}/warning.svg`}
                                          className={'warning-icon'}
                                          alt={'Missing additional condition'}
                                        />
                                      </Grid>
                                      <Grid item>
                                        <Typography
                                          color={'red'}
                                          variant="caption"
                                          whiteSpace={'nowrap'}
                                        >
                                          結合条件にエラーがあります
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )}
                              </Grid>
                            </>
                          </TimelineItem>
                        );
                      })}
                    </Timeline>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      ) : (
        <Grid container flexWrap={'nowrap'}>
          <Grid item xs={12}>
            {formik.values.datasource ? (
              <Button variant="text" onClick={onClickDialogOpen}>
                テーブルを選択
              </Button>
            ) : (
              <Typography component="span" variant="caption">
                データソース選択してください。
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      <JoinSettingDialog
        isOpen={joinDialogOpen}
        isColumnLoading={isColumnLoading}
        joinIndex={joinIndex}
        joinSetting={joinSetting}
        handleJoinSetting={setJoinSetting}
        joinedTables={joinedTables}
        okFunc={handleJoinDialogOk}
        cancelFunc={handleJoinDialogClose}
      />

      <ChooseTableDialog
        isOpen={dialogOpen}
        title={'テーブルを選択'}
        isLoading={isLoading}
        rows={schemaRows}
        selectionModel={selectionModel}
        handleSelectionModel={setSelectionModel}
        selectedTable={selectedMainTable}
        handleSelectedTable={setSelectedMainTable}
        cancelFunc={handleDialogClose}
        okFunc={handleDialogOk}
      />

      <ChooseTableDialog
        isOpen={joinTableDialogOpen}
        title={'結合テーブル選択'}
        isLoading={isLoading}
        rows={schemaRows}
        selectionModel={selectionModel}
        handleSelectionModel={setSelectionModel}
        selectedTable={selectedJoinTable}
        handleSelectedTable={setSelectedJoinTable}
        cancelFunc={handleJoinTableDialogClose}
        okFunc={handleJoinTableDialogOk}
      />

      <ConfirmDialog
        tile={
          (formik.values.schemaN && formik.values.tableN
            ? `${formik.values.schemaN}.${formik.values.tableN}`
            : '') + 'を更新しますか'
        }
        isOpen={confirmChangeTableDialogOpen}
        okLabel={'更新'}
        okFunc={() => changeMainJoinTable()}
        cancelFunc={() => setConfirmChangeTableDialogOpen(false)}
      >
        ベーステーブル変更すると、結合テーブルも削除します。
      </ConfirmDialog>
    </>
  );
};

export default SchemaConditionParts;
