import CreateDataSourceSettingForm from './CreateDataSourceSettingForm';
import { useEffect } from 'react';
import { useSnackbar } from '../../hooks/SnackBar';
import { useLocation } from 'react-router-dom';
import { useConnectionSwr } from '../../common/swr/useConnectionSwr';
import { DataSource } from '../../common/types/dataSource';
import Progress from './Progress';

type Props = {
  connectionId: string;
};

export default function EditDataSourceSettingForm({ connectionId: connectionId }: Props) {
  const { showSnackbar } = useSnackbar();

  // get type of connection from location state
  const location = useLocation();
  const connectionType: DataSource = location.state.connectionType
    ? (location.state.connectionType as DataSource)
    : DataSource.RedShift;

  // APIs
  const { connection, isLoading, error } = useConnectionSwr(connectionId, connectionType);

  // swr error
  useEffect(() => {
    if (error)
      showSnackbar(`データソース一覧取得APIエラー (${error.response.data['detail']})`, 'error');
  }, [error, showSnackbar]);

  return (
    <>
      {isLoading ? (
        <Progress open={true} />
      ) : (
        <CreateDataSourceSettingForm connection={connection} connectionType={connectionType} />
      )}
    </>
  );
}
