import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

export type ProgressContextType = {
  open: boolean;
  showProgress: (open: boolean) => void;
};

export const ProgressContext = React.createContext<ProgressContextType>({
  open: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  showProgress: (_open: boolean) => {},
});

export const ProgressContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const context: ProgressContextType = React.useContext(ProgressContext);
  const [open, setOpen] = React.useState(context.open);

  const newContext: ProgressContextType = React.useMemo(
    () => ({
      open,
      showProgress: (open: boolean) => {
        setOpen(open);
      },
    }),
    [open, setOpen]
  );

  return (
    <ProgressContext.Provider value={newContext}>
      {children}
      <Backdrop
        open={newContext.open}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 3 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </ProgressContext.Provider>
  );
};

export function useProgress(): ProgressContextType {
  return React.useContext(ProgressContext);
}
