import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { useSnackbar } from '../hooks/SnackBar';
import { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useProgress } from '../hooks/useProgress';

const theme = createTheme({
  palette: {
    background: {
      default: '#E4E4E4',
    },
  },
});

export default function ForgotPasswordConfirm() {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();
  const navigate = useNavigate();

  const { userName: userName } = useParams();

  const [username] = useState(userName);

  const changePassword = async () => {
    if (formik.values.newPassword != formik.values.newPasswordConfirm) {
      showSnackbar('パスワードが一致していません。', 'error');
      return;
    }

    showProgress(true);

    const result = await auth.forgotPasswordConfirm(
      username ? username : '',
      formik.values.verificationCode,
      formik.values.newPassword
    );
    if (result.success) {
      showProgress(false);
      showSnackbar(result.message, 'success');
      formik.resetForm();
      navigate('/completepasswordreset');
    } else {
      showProgress(false);
      showSnackbar(result.message, 'error');
    }
  };

  const initialValues = {
    verificationCode: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const newPasswordYup = yup
    .string()
    .required('必須項目です。')
    .min(8, '8文字以上入力してください。')
    .matches(/(?=.*[a-z])/, '小文字を含めてください。')
    .matches(/(?=.*[A-Z])/, '大文字を含めてください。')
    .matches(/(?=.*[0-9])/, '数字を含めてください。')
    .matches(/(?=.*[ -/:-@[-`{-~])/, '記号を含めてください。');

  const validationSchema = yup.object({
    verificationCode: yup.string().required('必須項目です。'),
    newPassword: newPasswordYup,
    newPasswordConfirm: newPasswordYup,
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      changePassword();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item xs={12}>
            <CssBaseline />
            <Box
              sx={{
                width: 500,
                marginTop: 14,
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#FFFFFF',
                padding: 4,
              }}
            >
              <Typography component="h1" variant="h4">
                パスワードの再設定
              </Typography>
              <Typography component="h1" variant="body1">
                メールアドレス:{userName}
              </Typography>
              <Typography component="h1" variant="body1">
                上記メールアドレス宛に、検証コードを送付しました。
              </Typography>
              <Typography component="h1" variant="body1">
                検証コードと新しいパスワードを入力してください。
              </Typography>
              <Box component="form" noValidate onSubmit={formik.handleSubmit}>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Typography>
                      検証コード <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      id="verificationCode"
                      name="verificationCode"
                      placeholder="検証コードを入力"
                      value={formik.values.verificationCode}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.verificationCode && Boolean(formik.errors.verificationCode)
                      }
                      helperText={formik.touched.verificationCode && formik.errors.verificationCode}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Typography>
                      新しいパスワード <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                      id="newPassword"
                      name="newPassword"
                      placeholder="新しいパスワードを入力"
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                      helperText={formik.touched.newPassword && formik.errors.newPassword}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={12}>
                    <Typography>
                      新しいパスワード（確認用） <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      type="password"
                      autoComplete="new-password"
                      id="newPasswordConfirm"
                      name="newPasswordConfirm"
                      placeholder="新しいパスワードを入力"
                      value={formik.values.newPasswordConfirm}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.newPasswordConfirm &&
                        Boolean(formik.errors.newPasswordConfirm)
                      }
                      helperText={
                        formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} alignItems="end" direction="column">
                  <Grid item xs={12}>
                    <NavLink to={'/signin'}>
                      <Typography variant="body2">サインイン画面へ戻る</Typography>
                    </NavLink>
                  </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  パスワードを再設定する
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
