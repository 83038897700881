import * as yup from 'yup';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridRowSelectionModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import {
  FormikErrors,
  FormikTouched,
  FormikValues,
  setNestedObjectValues,
  useFormik,
} from 'formik';
import {
  GetQueryResp,
  GetColumn,
  GetColumnsResp,
  PostQueryReq,
  PostPreviewColumn,
  PostPreviewReq,
  PostTempDownloadReq,
  PutQueryReq,
  TableDetails,
  EnclosedText,
} from '../../common/types/Responses';
import { useEffect, useState, ChangeEvent, useRef } from 'react';
import Progress from './Progress';
import jwtAxios from '../../common/axios';
import { useNavigate } from 'react-router-dom';
import { useConnectionsSwr } from '../../common/swr/useConnectionsSwr';
import { useSnackbar } from '../../hooks/SnackBar';
import { useTablesSwr } from '../../common/swr/useTablesSwr';
import { BookmarkAddOutlined, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useColumnsSwr, conditionType } from '../../common/swr/useColumnsSwr';
import { BaseDataGrid } from '../BaseDataGrid';
import { generateUuid } from '../../modules/common';
import { useProgress } from '../../hooks/useProgress';
import ConfirmDialogDesregard from './ConfirmDialogDesregard';
import { useConnectionSwr } from '../../common/swr/useConnectionSwr';
import { keysToCamel, keysToSnake } from '../../common/func/converter';
import { useSWRConfig } from 'swr';
import { useQueriesSwr } from '../../common/swr/useQueriesSwr';
import { VariantType, useSnackbar as useNotistackSnackbar } from 'notistack';
import { ENCLOSED_TEXT_OPTIONS } from '../../common/const/enclosedText';
import { useAuth } from '../../hooks/use-auth';

const validationSchema = yup.object({
  datasource: yup.string().required('データソース名は必須です。'),
  queryName: yup
    .string()
    .required('タイトルは必須です。')
    .max(30, 'タイトルは30文字以下で入力してください。'),
  description: yup.string().max(300, '説明は300文字以下で入力してください。'),
  query: yup.string().required('SQL文は必須です。'),
  enclosedText: yup.string().required('ストリング値の引用符は必須です。'),
});

type Props = {
  query?: GetQueryResp;
};

export default function CreateSqlSettingForm({ query }: Props) {
  const auth = useAuth();
  const { showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();
  const navigation = useNavigate();
  const { enqueueSnackbar } = useNotistackSnackbar();
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dataSourceType, setDataSourceType] = useState<string>('');

  const queryNameRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);

  const { mutate } = useQueriesSwr();
  const { cache } = useSWRConfig();

  // formik
  const formik = useFormik({
    initialValues: {
      uuid: query ? query.uuid : '',
      datasource: query ? query.datasource : '',
      queryName: query ? query.queryName : '',
      description: query ? query.description : '',
      query: query ? query.query : '',
      schemaN: query ? query.schemaN : '',
      tableN: query ? query.tableN : '',
      enclosedText: query && query.enclosedText ? query.enclosedText : EnclosedText.None,
      csvBomOption: query ? query.csvBomOption : true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values;
    },
  });
  // APIs
  const {
    connections,
    isLoading: connectionsIsLoading,
    isError: connectionsIsError,
  } = useConnectionsSwr();

  const {
    tables,
    isLoading: tablesIsLoading,
    isError: tablesIsError,
  } = useTablesSwr(
    formik.values.datasource ? [formik.values.datasource] : [],
    formik.values.datasource !== ''
  );

  const {
    connection,
    isLoading: connectionIsLoading,
    isValidating: connectionIsValidating,
  } = useConnectionSwr(
    formik.values.datasource,
    dataSourceType,
    formik.values.datasource != '' && dataSourceType != ''
  );

  // プレビューのローディング
  const [previewLoading, setPreviewLoading] = useState(false);

  // セレクトのラベルを非表示にする
  const [hideSelectLabel, setHideSelectLabel] = useState(false);
  const [hideEnclosedTextSelectLabel, setHideEnclosedTextSelectLabel] = useState(false);
  const [hideSchemaSelectLabel, setHideSchemaSelectLabel] = useState(false);
  const [hideTableSelectLabel, setHideTableSelectLabel] = useState(false);

  // swr error
  useEffect(() => {
    if (connectionsIsError)
      showSnackbar(
        `データソース一覧取得APIエラー (${connectionsIsError.response.data['detail']})`,
        'error'
      );
    if (tablesIsError)
      showSnackbar(`テーブル一覧取得APIエラー (${tablesIsError.response.data['detail']})`, 'error');
  }, [connectionsIsError, tablesIsError, showSnackbar]);

  // 一覧用
  const [tableRows, setTableRows] = useState<TableDetails>([]);

  // スキーマ（選択行）
  const [selectionSchema, setSelectionSchema] = useState<string>('');

  // テーブル（選択行）
  const [selectionTable, setSelectionTable] = useState<string>('');

  // カラム名（選択行）
  const [selectionColumnModel, setSelectionColumnModel] = useState<GridRowSelectionModel>([]);

  // データ型（選択行）
  const [selectionDataTypeModel, setSelectionDataTypeModel] = useState<GridRowSelectionModel>([]);

  const [schemaTableRows, setSchemaTableRows] = useState<{ schemaName: string }[]>([]);
  useEffect(() => {
    let rows: string[] = [];
    tableRows.map((row) => {
      rows.push(row.schemaName);
    });
    rows = Array.from(new Set(rows));
    setSchemaTableRows(
      rows.map((i) => {
        return {
          schemaName: i,
        };
      })
    );
  }, [tableRows]);

  const [tableTableRows, setTableTableRows] = useState<{ tableName: string }[]>([]);
  useEffect(() => {
    let rows: string[] = [];
    tableRows.map((row) => {
      if (row.schemaName === selectionSchema) {
        rows.push(row.tableName);
      }
    });
    rows = Array.from(new Set(rows));
    setTableTableRows(
      rows.map((i) => {
        return {
          tableName: i,
        };
      })
    );
  }, [selectionSchema, tableRows]);

  const [columnTableRows, setColumnTableRows] = useState<GetColumnsResp>([]);

  const { columns, isLoading: columnsIsLoading } = useColumnsSwr(
    formik.values.datasource,
    formik.values.schemaN,
    formik.values.tableN,
    conditionType.advanced
  );

  useEffect(() => {
    if (columns !== undefined) {
      setColumnTableRows(columns);
    }
  }, [columns]);

  const schemaColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'カラム名',
      flex: 1,
    },
    {
      field: 'dataType.type',
      headerName: 'データ型',
      flex: 1,
      valueGetter: (params) => {
        return params.row.dataType[0].type;
      },
    },
  ];

  useEffect(() => {
    // 行データ
    const newTableRows: TableDetails = [];
    connections?.map((connection) => {
      tables?.map((table) => {
        table.map((schema) => {
          schema.tables.map((tableName) => {
            newTableRows.push({
              connectionId: connection.connectionId,
              name: connection.name,
              host: connection.host,
              port: Number(connection.port),
              user: connection.user,
              db: connection.db,
              schemaName: schema.schemaN,
              tableName: tableName,
            });
          });
        });
      });
    });
    setTableRows(newTableRows);
  }, [tablesIsLoading, formik.values.datasource]);

  // プレビュー
  const [preview, setPreview] = useState(false);
  const [previewColumn, setPreviewColumn] = useState<GridColDef[]>([]);
  const [previewRow, setPreviewRow] = useState<GridRowsProp>([]);

  // 条件保存、ダウンロードボタンのdisabled制御
  const [submitButton, setSubmitButton] = useState(false);

  useEffect(() => {
    if (formik.values.queryName && formik.values.datasource && formik.values.query) {
      setSubmitButton(true);
    } else {
      setSubmitButton(false);
    }
  }, [formik.values.queryName, formik.values.datasource, formik.values.query]);

  const handleScrollToFirstError = (error: FormikErrors<FormikValues>) => {
    const fieldOrdered: string[] = ['queryName', 'description'];

    // find first error from formik
    const firstErrorField = fieldOrdered.find((i) => !!error[i]);

    // map field with elementRef
    const mapFieldRef: Record<string, React.RefObject<HTMLElement>> = {
      queryName: queryNameRef,
      description: descriptionRef,
    };
    if (!firstErrorField) return;

    // find first element with id
    const errorElement = mapFieldRef[firstErrorField];

    // scroll into view with element
    if (errorElement) errorElement.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  const handlePreview = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
        handleScrollToFirstError(error);
      } else {
        setPreviewLoading((prevLoading) => !prevLoading);

        const params: PostPreviewReq = {
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          query: formik.values.query,
          enclosedText: formik.values.enclosedText,
        };
        jwtAxios
          .post('/api/preview/', keysToSnake(params))
          .then((response) => {
            const columns: GridColDef[] = [];
            const previewColumns = response.data.columns.map((c: PostPreviewColumn) =>
              keysToCamel(c)
            );
            previewColumns.forEach((column: PostPreviewColumn, columnIndex: number) => {
              const columnDef: GridColDef = {
                field: `${column.name}_${columnIndex}`,
                headerName: `${column.name} (${column.dataType[0].type})`,
                flex: 1,
                minWidth: 200,
              };
              columns.push(columnDef);
            });
            const rows: GridValidRowModel[] = [];
            response.data.results.forEach((result: GridRowsProp) => {
              const row: { [prop: string]: GridValidRowModel } = {};
              result.forEach((value, resultIndex) => {
                const columnName = previewColumns[resultIndex].name;
                row[`${columnName}_${resultIndex}`] = value;
              });
              rows.push(row);
            });
            setPreviewLoading((prevLoading) => !prevLoading);
            setPreview(true);
            setPreviewColumn(columns);
            setPreviewRow(rows);
            showSnackbar('プレビューを取得しました。', 'success');
          })
          .catch((error) => {
            setPreviewLoading((prevLoading) => !prevLoading);
            setPreview(false);
            setPreviewColumn([]);
            setPreviewRow([]);
            showSnackbar(
              `プレビューの取得に失敗しました。 (${error.response.data['detail']})`,
              'error'
            );
          });
      }
    });
  };

  const handleTempDownload = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
        handleScrollToFirstError(error);
      } else {
        showProgress(true);
        const req: PostTempDownloadReq = {
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          query: formik.values.query,
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };

        const variant: VariantType = 'success';
        enqueueSnackbar(`${formik.values.queryName}のデータ抽出を開始しました。`, { variant });

        jwtAxios
          .post('/api/temp_download/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`データ抽出に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const handlePost = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
        handleScrollToFirstError(error);
      } else {
        showProgress(true);
        const req: PostQueryReq = {
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          schemaN: '',
          tableN: '',
          query: formik.values.query,
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };
        jwtAxios
          .post('/api/query/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
            showSnackbar('設定を保存しました。', 'success');
            mutate();
            cache.delete(`api/queries/`);
            navigation('/queries');
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`設定の保存に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const handlePut = () => {
    formik.validateForm().then((error) => {
      if (formik.values.uuid === undefined) {
        return;
      }
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
        handleScrollToFirstError(error);
      } else {
        showProgress(true);
        const req: PutQueryReq = {
          uuid: formik.values.uuid,
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          query: formik.values.query,
          schemaN: '',
          tableN: '',
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };
        jwtAxios
          .put('/api/query/', keysToSnake(req))
          .then(() => {
            showProgress(false);
            showSnackbar('設定を更新しました。', 'success');
            mutate();
            cache.delete(`api/queries/`);
            navigation('/queries');
          })
          .catch((error) => {
            showProgress(false);
            showSnackbar(`設定の更新に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const getConnectionTypeById = (connectionId: string): string => {
    return connections.find((item) => item.connectionId === connectionId)?.type ?? '';
  };

  const handleChangeDataSource = (event: SelectChangeEvent) => {
    const connectionId = event.target.value;
    const connectionType = getConnectionTypeById(connectionId);

    formik.setFieldValue('datasource', connectionId);
    setDataSourceType(connectionType);
  };

  const handleChangeEnclosedText = (event: SelectChangeEvent) => {
    const enclosedText = event.target.value;
    formik.setFieldValue('enclosedText', enclosedText);
  };

  const handleChangeCsvBomOption = (event: ChangeEvent, checked: boolean) => {
    formik.setFieldValue('csvBomOption', checked);
  };

  const onBlurQueryName = () => {
    formik.setFieldTouched('queryName');
  };

  const onBlurDescription = () => {
    formik.setFieldTouched('description');
  };

  useEffect(() => {
    if (query) {
      const connectionType = getConnectionTypeById(query.datasource);
      setDataSourceType(connectionType);
    }
  }, [connections, query]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* タイトル */}
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography sx={{ mt: 2 }}>
              タイトル <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              ref={queryNameRef}
              id="queryName"
              name="queryName"
              placeholder="例）東京、誕生日順"
              value={formik.values.queryName}
              onChange={formik.handleChange}
              error={formik.touched.queryName && Boolean(formik.errors.queryName)}
              helperText={formik.touched.queryName && formik.errors.queryName}
              fullWidth
              onBlur={onBlurQueryName}
            />
          </Grid>
        </Grid>

        {/* 説明 */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>説明</Typography>
            <TextField
              ref={descriptionRef}
              id="description"
              name="description"
              placeholder="例）東京在住ユーザーの誕生日昇順"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              onBlur={onBlurDescription}
            />
          </Grid>
        </Grid>

        {/* データソース名 */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>
              データソース名（データベース名）
              {query ? null : <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <FormControl fullWidth>
              {formik.values.datasource || hideSelectLabel ? null : (
                <InputLabel id="datasource-select-label">選択してください</InputLabel>
              )}
              <Select
                labelId="datasource-select-label"
                id="datasource"
                defaultValue={''}
                value={connections.length ? formik.values.datasource : ''}
                onChange={handleChangeDataSource}
                onOpen={() => {
                  setHideSelectLabel(true);
                }}
                error={formik.touched.datasource && Boolean(formik.errors.datasource)}
                disabled={!!formik.values.uuid}
              >
                {connections.map((connection) => (
                  <MenuItem key={generateUuid()} value={connection.connectionId}>
                    {`${connection.name} (${
                      connectionIsLoading && connectionIsValidating
                        ? '取得中...'
                        : connection?.db || ''
                    })`}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {formik.touched.datasource && formik.errors.datasource}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {/* テーブル表示 */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Button
              variant="text"
              color="inherit"
              onClick={() => setOpen(!open)}
              endIcon={open ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              disableRipple
              sx={{
                padding: 0,
                '&.MuiButtonBase-root:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <Typography>テーブル表示</Typography>
            </Button>
          </Grid>
        </Grid>

        <Grid container mt={2} justifyContent="center" spacing={2}>
          {open ? (
            <Grid item xs={6}>
              <Grid container justifyContent="center" spacing={2}>
                {/* スキーマ */}
                <Grid item xs={6}>
                  <Typography>スキーマ</Typography>
                  <FormControl fullWidth>
                    {selectionSchema || hideSchemaSelectLabel ? null : (
                      <InputLabel>
                        {connectionIsLoading && connectionIsValidating
                          ? '取得中...'
                          : formik.values.datasource
                            ? 'スキーマを選択'
                            : ''}
                      </InputLabel>
                    )}
                    <Select
                      defaultValue={''}
                      value={selectionSchema}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectionTable('');
                        setSelectionColumnModel([]);
                        setSelectionDataTypeModel([]);
                        setColumnTableRows([]);
                        setSelectionSchema(event.target.value);

                        if (event.target.value.length) {
                          formik.setFieldValue('schemaN', event.target.value);
                          formik.setFieldValue('tableN', '');
                        }
                      }}
                      onOpen={() => {
                        setHideSchemaSelectLabel(true);
                      }}
                      disabled={!formik.values.datasource}
                    >
                      {schemaTableRows.map((schema) => (
                        <MenuItem key={generateUuid()} value={schema.schemaName}>
                          {schema.schemaName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* テーブル */}
                <Grid item xs={6}>
                  <Typography>テーブル</Typography>
                  <FormControl fullWidth>
                    {selectionTable || hideTableSelectLabel ? null : (
                      <InputLabel>{selectionSchema ? 'テーブルを選択' : ''}</InputLabel>
                    )}
                    <Select
                      defaultValue={''}
                      value={selectionTable}
                      onChange={(event: SelectChangeEvent) => {
                        setSelectionColumnModel([]);
                        setSelectionDataTypeModel([]);
                        setSelectionTable(event.target.value);

                        if (event.target.value.length) {
                          formik.setFieldValue('tableN', event.target.value);
                        }
                      }}
                      onOpen={() => {
                        setHideTableSelectLabel(true);
                      }}
                      disabled={!selectionSchema}
                    >
                      {tableTableRows.map((table) => (
                        <MenuItem key={generateUuid()} value={table.tableName}>
                          {table.tableName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                {/* カラム名、データ型 */}
                <Grid item xs={12} mt={2}>
                  <Typography>カラム</Typography>
                  <Paper variant="outlined" elevation={0}>
                    <Box sx={{ height: 344, width: '100%', overflowY: 'scroll' }}>
                      <BaseDataGrid
                        loading={selectionTable.length !== 0 && columnsIsLoading}
                        rows={columnTableRows ? columnTableRows : []}
                        columns={schemaColumns}
                        getRowIdFunc={(row: GetColumn) => row.name}
                        disableSelectionOnClick={false}
                        hideFooter={true}
                        initialState={{
                          sorting: {
                            sortModel: [{ field: 'name', sort: 'asc' }],
                          },
                          pagination: {
                            paginationModel: { pageSize: 100 },
                          },
                        }}
                        sx={{
                          '.MuiDataGrid-columnHeaders': {
                            backgroundColor: '#EEEEEE',
                            borderRadius: 0,
                          },
                          border: 0,
                          '.MuiDataGrid-row .MuiDataGrid-cell': { border: 0 },
                        }}
                        selectionModel={selectionColumnModel}
                        onSelectionModelChangeFunc={(selectionModel: GridRowSelectionModel) => {
                          setSelectionColumnModel(selectionModel);

                          const selectedRowId = new Set(selectionModel);
                          if (columnTableRows) {
                            const selectedRows = columnTableRows.filter((row) =>
                              selectedRowId.has(row.name)
                            );
                            if (selectedRows.length) {
                              setSelectionDataTypeModel([selectedRows[0].dataType[0].type]);
                            }
                          }
                        }}
                      />
                    </Box>
                  </Paper>

                  {/* パンくず */}
                  <Paper
                    variant="outlined"
                    elevation={0}
                    sx={{
                      height: '36px',
                      paddingLeft: 2,
                      paddingY: 1,
                      backgroundColor: '#FAFAFA',
                      borderRadius: 0,
                      borderBottomLeftRadius: '4px',
                      borderBottomRightRadius: '4px',
                      borderTop: 0,
                    }}
                  >
                    <Typography variant="body2" color="text.secondary">{`${selectionSchema} ${
                      selectionTable.length ? ' > ' + selectionTable : ''
                    } ${selectionColumnModel.length ? ' > ' + selectionColumnModel : ''} ${
                      selectionDataTypeModel.length ? ' > ' + selectionDataTypeModel : ''
                    }`}</Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {/* SQL文 */}
          <Grid item xs={open ? 6 : 12}>
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Typography>
                  SQL文 <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  id="query"
                  placeholder="SQL文を入力してください"
                  multiline
                  rows={20}
                  value={formik.values.query}
                  onChange={formik.handleChange}
                  error={formik.touched.query && Boolean(formik.errors.query)}
                  helperText={formik.touched.query && formik.errors.query}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* ストリング値の引用符 */}
        {dataSourceType === 'Snowflake' ? (
          <Grid container marginTop={4} justifyContent="center">
            <Grid item xs={12}>
              <Typography>
                ストリング値の引用符 <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControl fullWidth>
                {formik.values.enclosedText || hideEnclosedTextSelectLabel ? null : (
                  <InputLabel id="enclosed-text-select-label">選択してください</InputLabel>
                )}
                <Select
                  labelId="enclosedTextSelectLabel"
                  id="enclosedText"
                  value={connections.length ? formik.values.enclosedText : ''}
                  onChange={handleChangeEnclosedText}
                  onOpen={() => {
                    setHideEnclosedTextSelectLabel(true);
                  }}
                  error={formik.touched.enclosedText && Boolean(formik.errors.enclosedText)}
                >
                  {ENCLOSED_TEXT_OPTIONS.map((enclosedTextOption) => (
                    <MenuItem key={generateUuid()} value={enclosedTextOption.value}>
                      {enclosedTextOption.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.touched.enclosedText && formik.errors.enclosedText}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}

        {/* csvオプション */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>出力オプション</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="csvBomOption"
                  checked={formik.values.csvBomOption}
                  onChange={handleChangeCsvBomOption}
                />
              }
              label="CSVファイルをMS Excelで読み込む(サイズ上限1GB)"
            />
          </Grid>
        </Grid>

        {/* プレビュー */}
        <Paper elevation={0} sx={{ padding: 2, marginY: 4, backgroundColor: '#FAFAFA' }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {preview && !previewLoading ? (
                <Paper>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <BaseDataGrid
                      rows={previewRow}
                      columns={previewColumn}
                      getRowIdFunc={(row: GridRowModel) => `${row.rowNum + generateUuid()}`}
                    />
                  </div>
                </Paper>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            {previewLoading ? (
              <Grid item marginTop={2}>
                <CircularProgress color="inherit" size={20} />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Button
                variant="text"
                onClick={() => handlePreview()}
                fullWidth
                sx={{ marginTop: preview ? 2 : 0 }}
                disabled={
                  auth.license?.isExpired ? true : previewLoading || !submitButton ? true : false
                }
              >
                プレビュー
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Grid container justifyContent="flex-end">
          <Box component="span" display="flex" justifyContent="space-evenly" alignItems="center">
            {formik.values.uuid === '' ? (
              <>
                <Button
                  type="submit"
                  variant="outlined"
                  startIcon={<BookmarkAddOutlined />}
                  onClick={() => handlePost()}
                  sx={{ marginRight: 2 }}
                  disabled={auth.license?.isExpired ? true : submitButton ? false : true}
                >
                  条件保存
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => handleTempDownload()}
                  disabled={auth.license?.isExpired ? true : submitButton ? false : true}
                >
                  データ抽出実行
                </Button>
              </>
            ) : (
              <>
                <Button sx={{ marginRight: 1 }} color="inherit" onClick={() => setDialogOpen(true)}>
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<BookmarkAddOutlined />}
                  onClick={() => handlePut()}
                  disabled={auth.license?.isExpired ? true : submitButton ? false : true}
                >
                  条件更新
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </form>
      <Progress open={connectionsIsLoading} />
      <ConfirmDialogDesregard
        isOpen={dialogOpen}
        cancelFunc={() => setDialogOpen(false)}
        okFunc={() => navigation('/queries')}
      />
    </>
  );
}
