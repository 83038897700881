import { BaseContent } from '../components/BaseContent';
import PasswordChange from '../components/PasswordChange';

export default function ForgotPasswordConfirm() {
  return (
    <BaseContent title="パスワード変更">
      <PasswordChange />
    </BaseContent>
  );
}
