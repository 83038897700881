import { BaseContent } from '../components/BaseContent';
import DataSourceTable from '../components/layoutparts/DataSourceTable';

export default function DataSourcesPage() {
  return (
    <BaseContent title="データソース一覧">
      <DataSourceTable />
    </BaseContent>
  );
}
