import jwtAxios from '../common/axios';
import { v4 as uuidv4 } from 'uuid';
import { GroupsTable, GroupsUser, MinimalGroup, SchemasTable, UserRole } from '../common/types';
import { keysToSnake } from '../common/func/converter';

// uuid
export const generateUuid = () => {
  return uuidv4();
};

// timestamp
export const getTimestampString = () => {
  const date = new Date();
  return date.getTime().toString();
};

// sec ->string
export const getSecond2String = (duration: number) => {
  const hour = Math.floor(duration / 3600000);
  const minute = Math.floor((duration - 3600000 * hour) / 60000);

  const hh = ('00' + hour).slice(-2);
  const mm = ('00' + minute).slice(-2);
  const ms = ('00000' + (duration % 60000)).slice(-5);

  const time = `${hh}:${mm}:${ms.slice(0, 2)}.${ms.slice(2, 5)}`;

  return time;
};

interface userData {
  firstId?: string;
  userName: string;
  email: string;
  adminRole: UserRole;
  group: MinimalGroup[];
}

export const createUser = async (data: userData) => {
  try {
    const params = {
      userName: data.userName,
      adminRole: data.adminRole,
      email: data.email,
      group: data.group,
    };
    await jwtAxios.post(`/api/user/`, keysToSnake(params));
    return { success: true, response: 'create' };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { success: false, error: error };
  }
};

export const updateUser = async (data: userData) => {
  try {
    const params = {
      firstId: data.firstId,
      userName: data.userName,
      adminRole: data.adminRole,
      email: data.email,
      group: data.group,
    };
    await jwtAxios.put(`/api/user/`, keysToSnake(params));
    return { success: true, response: 'update' };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { success: false, error: error };
  }
};

interface GroupData {
  firstId?: string;
  groupName: string;
  users: GroupsUser[];
  tables: GroupsTable[];
  schemas: SchemasTable[];
}

export const deepCopyObjectList = <T>(targetList?: T[]) => {
  const copyList = targetList
    ? Array.from(
        new Map(targetList.map((element: T) => [JSON.stringify(element), element])).values()
      )
    : [];

  return copyList;
};

export const createGroup = async (data: GroupData) => {
  try {
    await jwtAxios.post(`/api/group/`, keysToSnake(data));
    return { success: true, response: 'create' };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { success: false, error: error, status: error.response.status };
  }
};

export const updateGroup = async (data: GroupData) => {
  try {
    await jwtAxios.put(`/api/group/`, keysToSnake(data));
    return { success: true, response: 'update' };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { success: false, error: error, status: error.response.status };
  }
};

export const moveArrayItem: <T>(array: T[], from: number, to: number) => T[] = (
  array,
  from,
  to
) => {
  const newArray = array.slice();
  const maxTo = to < 0 ? newArray.length + to : to;

  newArray.splice(maxTo, 0, newArray.splice(from, 1)[0]);

  return newArray;
};
