import jwtAxios from '../axios';
import { isObject, keysToCamel } from '../func/converter';

export const fetcher = (url: string) => jwtAxios.get(url).then((res) => res.data);

export const fetcherKeysToCamel = (url: string) =>
  jwtAxios.get(url).then((res) => {
    if (isObject(res.data)) {
      return keysToCamel(res.data);
    } else if (Array.isArray(res.data)) {
      return res.data.map((r) => keysToCamel(r));
    }

    return res.data;
  });

export const arrayFetcher = (...urls: string[]) => {
  return Promise.all(urls.map((url) => fetcher(url)));
};

export const arrayFetcherKeysToCamel = (...urls: string[]) => {
  return Promise.all(urls.map((url) => fetcherKeysToCamel(url)));
};
