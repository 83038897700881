import { BaseContent } from '../components/BaseContent';
import DownloadHistoryTable from '../components/layoutparts/DownloadHistoryTable';

export default function DownloadHistoryPage() {
  return (
    <BaseContent title="データ抽出履歴">
      <DownloadHistoryTable />
    </BaseContent>
  );
}
