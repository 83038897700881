import { DataSource, DataSourceOption } from '../types/dataSource';

export const DATA_SOURCE_NAME = {
  [DataSource.RedShift]: 'Redshift',
  [DataSource.SnowFlake]: 'Snowflake',
};

export const DATA_SOURCE_OPTIONS: DataSourceOption[] = [
  {
    label: DATA_SOURCE_NAME[DataSource.RedShift],
    value: DataSource.RedShift,
  },
  {
    label: DATA_SOURCE_NAME[DataSource.SnowFlake],
    value: DataSource.SnowFlake,
  },
];
