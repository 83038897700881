import React from 'react';

const JoinIcon: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} viewBox="0 0 44 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 28C10.1 28 6.79167 26.6417 4.075 23.925C1.35833 21.2083 0 17.9 0 14C0 10.1 1.35833 6.79167 4.075 4.075C6.79167 1.35833 10.1 0 14 0C14.9 0 15.775 0.0833333 16.625 0.25C17.475 0.416667 18.3 0.65 19.1 0.95C18.5333 1.41667 18 1.925 17.5 2.475C17 3.025 16.5333 3.6 16.1 4.2C15.7667 4.13333 15.425 4.08333 15.075 4.05C14.725 4.01667 14.3667 4 14 4C11.2333 4 8.875 4.975 6.925 6.925C4.975 8.875 4 11.2333 4 14C4 16.7667 4.975 19.125 6.925 21.075C8.875 23.025 11.2333 24 14 24C14.3667 24 14.725 23.9833 15.075 23.95C15.425 23.9167 15.7667 23.8667 16.1 23.8C16.5333 24.4 17 24.975 17.5 25.525C18 26.075 18.5333 26.5833 19.1 27.05C18.3 27.35 17.475 27.5833 16.625 27.75C15.775 27.9167 14.9 28 14 28ZM30 28C29.1 28 28.225 27.9167 27.375 27.75C26.525 27.5833 25.7 27.35 24.9 27.05C25.4667 26.5833 26 26.075 26.5 25.525C27 24.975 27.4667 24.4 27.9 23.8C28.2667 23.8667 28.6167 23.9167 28.95 23.95C29.2833 23.9833 29.6333 24 30 24C32.7667 24 35.125 23.025 37.075 21.075C39.025 19.125 40 16.7667 40 14C40 11.2333 39.025 8.875 37.075 6.925C35.125 4.975 32.7667 4 30 4C29.6333 4 29.2833 4.01667 28.95 4.05C28.6167 4.08333 28.2667 4.13333 27.9 4.2C27.4667 3.6 27 3.025 26.5 2.475C26 1.925 25.4667 1.41667 24.9 0.95C25.7 0.65 26.525 0.416667 27.375 0.25C28.225 0.0833333 29.1 0 30 0C33.9 0 37.2083 1.35833 39.925 4.075C42.6417 6.79167 44 10.1 44 14C44 17.9 42.6417 21.2083 39.925 23.925C37.2083 26.6417 33.9 28 30 28ZM22 25.5C20.1 24.2 18.625 22.5333 17.575 20.5C16.525 18.4667 16 16.3 16 14C16 11.7 16.525 9.53333 17.575 7.5C18.625 5.46667 20.1 3.8 22 2.5C23.9 3.8 25.375 5.46667 26.425 7.5C27.475 9.53333 28 11.7 28 14C28 16.3 27.475 18.4667 26.425 20.5C25.375 22.5333 23.9 24.2 22 25.5Z"
        fill="#5F6368"
      />
    </svg>
  );
};

export default JoinIcon;
