import { SxProps } from '@mui/material';
import {
  DataGrid,
  GridInputRowSelectionModel,
  GridRowsProp,
  GridRowSelectionModel,
  jaJP,
  GridColDef,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { GridInitialStateCommunity } from '@mui/x-data-grid/models/gridStateCommunity';

type BaseDataGridProps = {
  loading?: boolean;
  rows: GridRowsProp;
  columns: GridColDef<GridValidRowModel>[];
  selectionModel?: GridInputRowSelectionModel;
  getRowIdFunc: any;
  hideFooter?: boolean;
  headerHeight?: number;
  getRowHeightAuto?: boolean;
  disableSelectionOnClick?: boolean;
  initialState?: GridInitialStateCommunity;
  onSelectionModelChangeFunc?: (selectionModel: GridRowSelectionModel) => void;
  sx?: SxProps;
};

export const BaseDataGrid: React.FC<BaseDataGridProps> = (props) => {
  return (
    <DataGrid
      autoHeight
      loading={props.loading === undefined ? false : props.loading}
      rows={props.rows}
      columns={props.columns}
      rowSelectionModel={props.selectionModel}
      getRowId={(row) => props.getRowIdFunc(row)}
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
      hideFooterSelectedRowCount
      hideFooter={props.hideFooter === undefined ? false : props.hideFooter}
      columnHeaderHeight={props.headerHeight === undefined ? 56 : props.headerHeight}
      getRowHeight={props.getRowHeightAuto ? () => 'auto' : undefined}
      disableRowSelectionOnClick={
        props.disableSelectionOnClick === undefined ? true : props.disableSelectionOnClick
      }
      pageSizeOptions={[10, 25, 50, 100]}
      initialState={
        props.initialState
          ? props.initialState
          : {
              sorting: {
                sortModel: [{ field: 'createdAt', sort: 'desc' }],
              },
              pagination: {
                // フッターを非表示にすると表示件数とページングの機能が利用できなくなるので100件表示を設定する
                paginationModel: {
                  pageSize: props.hideFooter ? 100 : 10,
                },
              },
            }
      }
      slotProps={{
        pagination: {
          labelRowsPerPage: '表示件数',
        },
      }}
      sx={{
        '.MuiDataGrid-iconSeparator': { display: 'none' },
        '.MuiDataGrid-columnHeaders': { backgroundColor: '#EEEEEE' },
        '.MuiDataGrid-columnHeader': { outline: 'none !important' },
        '.MuiDataGrid-cell': { outline: 'none !important' },
        ...props.sx,
      }}
      onRowSelectionModelChange={(selectionModel: GridRowSelectionModel) => {
        if (props.onSelectionModelChangeFunc) {
          props.onSelectionModelChangeFunc(selectionModel);
        }
      }}
    />
  );
};
