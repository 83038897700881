import { EditOutlined, DeleteOutlined } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ConfirmDialogDelete from './ConfirmDialogDelete';

import { renderCellDate } from '../../common/func/functions';
import { useGroupsSwr } from '../../common/swr/useGroupsSwr';

import { useEffect, useState } from 'react';
import jwtAxios from '../../common/axios';
import { useSnackbar } from '../../hooks/SnackBar';
import { IconButton, Tooltip } from '@mui/material';
import { BaseDataGrid } from '../BaseDataGrid';
import { Group } from '../../common/types';
import { useNavigate } from 'react-router';
import { useProgress } from '../../hooks/useProgress';
import { useAuth } from '../../hooks/use-auth';
import { useSWRConfig } from 'swr';
import { MAX_ARRAY_SIZE } from '../../common/const/maxArraySize';

export default function GroupsTable() {
  const { showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();
  const navigation = useNavigate();

  const auth = useAuth();
  const notAdmin = !(auth.role === 'admin' || auth.role === 'superadmin');

  // APIs
  const { groups, isLoading, isError, mutate } = useGroupsSwr();

  const { cache } = useSWRConfig();

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(`グループ取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  const [deletedGroupName, setDeletedGroupName] = useState('');
  const [deletedGroupId, setDeletedGroupId] = useState('');

  // Group delete confirm
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDelete = () => {
    setDialogOpen(false);
    showProgress(true);
    jwtAxios
      .delete(`/api/group/?first_id=${deletedGroupId}`)
      .then(function () {
        toggleIsLicValidity(false);
        showProgress(false);
        showSnackbar('グループを削除しました。', 'success');
        if (groups.length < MAX_ARRAY_SIZE) {
          const updatedGroups = groups.filter((groups) => {
            return groups.firstId !== deletedGroupId;
          });
          mutate(updatedGroups, true);
        } else {
          cache.delete(`api/groups/`);
        }
        cache.delete(`api/users/`);
        // 再検証
        void mutate();
      })
      .catch(function (error) {
        toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
        showProgress(false);
        showSnackbar(`グループの削除に失敗しました。 (${error.response.data['detail']})`, 'error');
      });
  };

  const columns: GridColDef[] = [
    { field: 'groupName', headerName: 'グループ名', flex: 2 },
    {
      field: 'userNum',
      headerName: '所属ユーザー数',
      flex: 2,
      valueGetter(params) {
        return params.row.users.length;
      },
    },
    {
      field: 'createdAt',
      headerName: '作成日',
      flex: 2,
      renderCell: (params: GridRenderCellParams<Group>) => {
        return renderCellDate(params, 'createdAt');
      },
    },
    {
      field: 'firstId',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      align: 'right',
      renderCell: (params: GridRenderCellParams<Group>) => (
        <div>
          <Tooltip title="編集" placement="top">
            <IconButton
              sx={{ marginRight: 2 }}
              onClick={() => {
                navigation(`/group/new/${params.value}`);
              }}
              disabled={auth.license?.isExpired}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title={notAdmin ? '削除権限がありません' : '削除'} placement="top">
            <span>
              <IconButton
                onClick={() => {
                  setDeletedGroupName(params.row.groupName);
                  setDeletedGroupId(params.row.firstId);
                  setDialogOpen(true);
                }}
                disabled={auth.license?.isExpired ? true : notAdmin}
              >
                <DeleteOutlined />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div style={{ height: 800, width: '100%' }}>
        <BaseDataGrid
          loading={isLoading}
          rows={groups ? groups : []}
          columns={columns}
          getRowIdFunc={(row: Group) => row.firstId}
        />
      </div>
      <ConfirmDialogDelete
        isOpen={dialogOpen}
        tile={deletedGroupName}
        cancelFunc={() => setDialogOpen(false)}
        okFunc={handleDelete}
      />
    </div>
  );
}
