import { ConnectionReq, GetConnectionResp } from '../../types/Responses';
import { checkSnowflakeData } from './checkSnowflakeData';

export const extractConnectionReqFromResp = (connection?: GetConnectionResp): ConnectionReq => {
  if (checkSnowflakeData(connection)) {
    return {
      connectionId: connection.connectionId ?? '',
      name: connection.name ?? '',
      user: connection.user ?? '',
      passwd: connection.passwd ?? '',
      db: connection.db ?? '',
      account: connection.account ?? '',
      role: connection.role ?? '',
      warehouse: connection.warehouse ?? '',
      schema: connection.schema ?? '',
      port: connection.port ?? '',
    };
  }
  return {
    connectionId: connection?.connectionId ?? '',
    name: connection?.name ?? '',
    user: connection?.user ?? '',
    passwd: connection?.passwd ?? '',
    db: connection?.db ?? '',
    host: connection?.host ?? '',
    port: connection?.port ?? '',
    redshiftIamRole: connection?.redshiftIamRole ?? '',
  };
};
