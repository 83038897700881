import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Popover,
  PopoverActions,
  Stack,
  TextField,
  Tooltip,
  Typography,
  colors,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { FormikErrors, FormikProps } from 'formik';
import utc from 'dayjs/plugin/utc';
import { DndContainer } from '../DragAndDrop';
import CancelIcon from '@mui/icons-material/Cancel';
import { Column, JoinedTable, Select, SimpleQueryForm } from '../../common/types';

dayjs.extend(utc);

type Props = {
  formik: FormikProps<SimpleQueryForm>;
  joinedTables: JoinedTable[];
  isColumnLoading: boolean;
};

const SelectConditionParts: React.FC<Props> = ({
  formik,
  joinedTables,
  isColumnLoading,
}: Props) => {
  // target to show popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const popoverAction = useRef<PopoverActions>(null);

  const [search, setSearch] = useState<string>('');

  const handleUpdatePopoverPosition = () => {
    // find scroll element (main)
    const mainElement = document.querySelector('main.MuiBox-root');
    let nextTimeout = 100;
    setTimeout(() => {
      if (mainElement) {
        // get y & height of add button
        const anchorElY = anchorEl?.getBoundingClientRect().y ?? 0;
        const anchorElHeight = anchorEl?.getBoundingClientRect().height ?? 0;
        // calculate distance
        const distance = window.innerHeight - anchorElY - anchorElHeight - 320;

        // if button is over bottom by popup scroll button into view
        if (distance < 0) {
          mainElement.scrollTo({
            top: mainElement.scrollTop - distance,
            behavior: 'smooth',
          });
          // delay for update position for popover
          nextTimeout += (-distance / 72) * 100;
        }
      }
      setTimeout(() => {
        popoverAction.current?.updatePosition();
      }, nextTimeout);
    }, 100);
  };

  // 追加ボタン 押したら配列に保存する
  const onClickAdd = (row: Column) => {
    formik.setFieldValue('simple.select', [
      ...formik.values.simple.select,
      { tableUuid: row.tableUuid, columnUuid: row.columnUuid, alias: '' },
    ]);
  };

  // 削除ボタン（メインページ）
  const onClickDeleteIcon = (index: number) => {
    formik.setFieldValue(
      'simple.select',
      formik.values.simple.select.filter((e, i) => i !== index)
    );
  };

  const onClickShowDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSelectAllColumn = () => {
    if (notSelectedWithSearchColumn) {
      formik.setFieldValue('simple.select', [
        ...formik.values.simple.select,
        ...notSelectedWithSearchColumn.map((i) => {
          return { tableUuid: i.tableUuid, columnUuid: i.columnUuid, alias: '' };
        }),
      ]);
    }
  };

  const onClickClearAllColumn = () => {
    formik.setFieldValue('simple.select', []);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const columnsRow = joinedTables.map((e) => e.columns ?? []).flat(1);

  const notSelectedWithSearchColumn = columnsRow
    ?.filter((i) => !formik.values.simple.select.find((j) => j.columnUuid === i.columnUuid))
    .filter((i) => {
      if (!search) return true;
      const table = joinedTables.find((e) => e.tableUuid === i.tableUuid);
      return `${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${i.name} (${i.dataType.type})`.includes(
        search
      );
    });

  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? 'select-condition-popover' : undefined;

  useEffect(() => {
    if (anchorEl && columnsRow.length) {
      handleUpdatePopoverPosition();
    }
  }, [anchorEl, formik.values.simple.select, columnsRow]);

  const renderColumnSearch = () => {
    return (
      <Grid
        container
        sx={{
          gap: 2,
          mb: 1,
          paddingTop: 2,
          position: 'sticky',
          top: 0,
          backgroundColor: colors.common.white,
          zIndex: 1,
        }}
      >
        <Grid item xs={9}>
          <TextField
            value={search}
            onChange={onChangeSearch}
            fullWidth
            placeholder="項目を入力してください"
          />
        </Grid>
        <Grid item xs={2}>
          <Button onClick={onClickSelectAllColumn}>全選択</Button>
        </Grid>
      </Grid>
    );
  };

  const renderColumnArea = () => {
    if (!formik.values?.tableN || !formik.values?.datasource)
      return (
        <>
          {renderColumnSearch()}
          <Grid container item xs={12}>
            <Typography style={{ color: 'red' }}>
              データソースおよびテーブルを選択してください。
            </Typography>
          </Grid>
        </>
      );
    if (isColumnLoading)
      return (
        <Box flex={1} padding={2} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={20} />
        </Box>
      );
    if (columnsRow.length && notSelectedWithSearchColumn.length) {
      return (
        <>
          {renderColumnSearch()}
          {notSelectedWithSearchColumn.map((row) => {
            const table = joinedTables.find((e) => e.tableUuid === row.tableUuid);
            return (
              <MenuItem
                onClick={() => onClickAdd(row)}
                key={`select_option_${row.columnUuid}`}
                value={row.columnUuid}
              >
                <Box width="100%" flexDirection="row" display="flex">
                  <Box flex={1}>
                    <ListItemText
                      sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                      secondary={row.dataType.type}
                      primary={`${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${row?.name} (${row?.dataType.type})`}
                    />
                  </Box>
                  <Box sx={{ paddingLeft: 1 }}>
                    <ListItemText primary="+ 追加" />
                  </Box>
                </Box>
              </MenuItem>
            );
          })}
        </>
      );
    }
    return (
      <>
        {renderColumnSearch()}
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ color: 'red' }}>有効な項目がありません。</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  useEffect(() => {
    formik.values.simple.select
      .filter((sel) => !!sel)
      .forEach((sel, index) => {
        formik.setFieldTouched(`simple.select[${index}].alias`);
      });
  }, [formik.values.simple.select]);

  return (
    <>
      <Grid container marginTop={4} justifyContent="center">
        <Grid item xs={12}>
          <Typography>抽出する項目の設定</Typography>
        </Grid>
      </Grid>

      <DndContainer
        onDropEnd={(data) => formik.setFieldValue('simple.select', data)}
        data={formik.values.simple.select}
        renderItem={(row, index) => {
          const table = joinedTables.find((e) => e.tableUuid === row.tableUuid);
          const column = table?.columns?.find((e) => e.columnUuid === row.columnUuid);
          const orgIndex = formik.values.simple.select.findIndex(
            (sel) => sel.columnUuid === row.columnUuid
          );
          return (
            <Stack
              direction={'row'}
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Typography sx={{ fontWeight: 'bold', wordBreak: 'break-word', mt: 1, mr: 2 }}>
                {`${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${column?.name} (${column?.dataType.type})`}
              </Typography>

              <Stack
                direction={'row'}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <Typography sx={{ mt: 1, width: 100 }}>カラム別名</Typography>

                <TextField
                  id={`simple.select[${index}].alias`}
                  name={`simple.select[${index}].alias`}
                  value={formik.values.simple.select[orgIndex]?.alias ?? ''}
                  onChange={formik.handleChange}
                  error={
                    orgIndex !== -1 &&
                    Boolean(
                      formik.touched.simple?.select &&
                        formik.touched.simple?.select[orgIndex]?.alias
                    ) &&
                    Boolean(
                      formik.errors.simple?.select &&
                        (formik.errors.simple?.select[orgIndex] as FormikErrors<Select>)?.alias
                    )
                  }
                  helperText={
                    orgIndex !== -1 &&
                    formik.touched.simple?.select &&
                    formik.touched.simple?.select[orgIndex]?.alias &&
                    formik.errors.simple?.select &&
                    (formik.errors.simple?.select[orgIndex] as FormikErrors<Select>)?.alias
                  }
                  onBlur={() => {
                    formik.setFieldTouched(`simple.select[${index}].alias`);
                  }}
                  size="small"
                  sx={{ width: 340 }}
                />

                <Tooltip title="削除" placement="top">
                  <IconButton
                    size={'small'}
                    sx={{ color: '#5F6368', mt: 0.3 }}
                    onClick={() => onClickDeleteIcon(index)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          );
        }}
      />

      <Grid container marginTop={1.5} flexDirection={'column'}>
        <Box>
          <Box>
            <Typography variant="caption">
              項目を追加した後に、項目の並び順を設定するには、ドラッグアンドドロップを使用できます。
            </Typography>
          </Box>
          <Button aria-describedby={popoverId} variant="text" onClick={onClickShowDropDown}>
            + 追加
          </Button>
          {formik.values.simple.select.length ? (
            <Button variant="text" onClick={onClickClearAllColumn}>
              全解除
            </Button>
          ) : null}
        </Box>
        <Popover
          id={popoverId}
          action={popoverAction}
          open={isOpenPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {
              maxHeight: 300,
              width: 'min(450px, 100%)',
              paddingX: 2,
              paddingBottom: 2,
              position: 'relative',
            },
          }}
        >
          {renderColumnArea()}
        </Popover>
      </Grid>
    </>
  );
};

export default SelectConditionParts;
