import { fetcherKeysToCamel } from './fetcher';
import useSWR from 'swr';
import { QueryDelete } from '../types';

export const useQueryDeleteSwr = (connectionId: string) => {
  const { data, error } = useSWR<QueryDelete>(
    connectionId ? `api/delete_query/?connection_id=${connectionId}` : null,
    fetcherKeysToCamel
  );
  return {
    queries: data,
    isLoading: !error && !data,
    isError: error,
  };
};
