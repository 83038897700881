import * as yup from 'yup';

import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  createUser,
  deepCopyObjectList as deepCopyObjectList,
  updateUser,
} from '../../modules/common';
import React, { useEffect, useMemo, useState } from 'react';

import { MinimalGroup, User, UserRole } from '../../common/types';
import { useGroupsSwr } from '../../common/swr/useGroupsSwr';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../hooks/SnackBar';
import ConfirmDialogDesregard from './ConfirmDialogDesregard';
import { Cancel } from '@mui/icons-material';
import ModalAddItems from './ModalAddItems';
import { BaseDataGrid } from '../BaseDataGrid';
import { useProgress } from '../../hooks/useProgress';
import { useUsersSwr } from '../../common/swr/useUsersSwr';
import { useSWRConfig } from 'swr';
import { MAX_ARRAY_SIZE } from '../../common/const/maxArraySize';

type GroupRow = {
  firstId: string;
  groupName: string;
  role: string;
};

const objectSchema = yup.object().shape({
  groupId: yup.string().required(),
  role: yup.string().required(),
});

const validationSchema = yup.object({
  userName: yup.string().required('ユーザー名は必須です。'),
  email: yup
    .string()
    .email('メールアドレスを入力してください。')
    .required('メールアドレスは必須です。'),
  adminRole: yup.string().required('ユーザー種別を選択してください。'),
  group: yup.array().of(objectSchema),
});

type Props = {
  user: User | undefined;
};

export default function CreateUserForm(props: Props) {
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();
  const navigation = useNavigate();

  // formik
  const formik = useFormik({
    initialValues: {
      firstId: props.user?.firstId ? props.user?.firstId : '',
      userName: props.user?.userName ? props.user?.userName : '',
      adminRole: props.user?.adminRole ? (props.user?.adminRole as UserRole) : '',
      email: props.user?.email ? props.user?.email : '',
      group: deepCopyObjectList<MinimalGroup>(props.user?.group),
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      values;
    },
  });

  // APIs
  const { groups, isLoading, isError } = useGroupsSwr();

  const { users, mutate } = useUsersSwr();
  const { cache } = useSWRConfig();

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(`グループ取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [groupDialogOpen, setGroupDialogOpen] = useState(false);

  // 一覧用
  const [groupRows, setGroupRows] = useState<GroupRow[]>([]);
  const [dialogGroupRows, setDialogGroupRows] = useState<GroupRow[]>([]);
  const [tmpGroupRows, setTmpGroupRows] = useState<GroupRow[]>([]);

  // 全グループ
  const allGroups = useMemo(() => {
    return groups.map((group) => {
      return {
        firstId: group.firstId,
        groupName: group.groupName,
        role: 'general',
      };
    });
  }, [isLoading]);

  useEffect(() => {
    // 行データ
    // 一覧用にroleを付け足す
    const initialGroupRows: GroupRow[] = [];
    allGroups.map((group) => {
      const userGroup = props.user?.group.find((g) => g.groupId === group.firstId);
      if (userGroup) {
        initialGroupRows.push({
          firstId: group.firstId,
          groupName: group.groupName,
          role: userGroup.role,
        });
      }
    });
    setGroupRows(initialGroupRows);
  }, [isLoading]);

  const handlePost = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
      } else {
        showProgress(true);
        const userData: Omit<User, 'firstId' | 'updatedAt'> = {
          userName: formik.values.userName,
          email: formik.values.email,
          adminRole: formik.values.adminRole as UserRole,
          group: formik.values.group,
        };
        createUser(userData).then((response) => {
          if (response.success) {
            showProgress(false);
            showSnackbar('ユーザーを作成しました。', 'success');
            cache.delete(`api/users/`);
            cache.delete(`api/groups/`);
            navigation('/users');
          } else {
            showProgress(false);
            showSnackbar(
              `ユーザーの作成に失敗しました。 (${response.error.response.data['detail']})`,
              'error'
            );
          }
        });
      }
    });
  };

  const handlePut = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
      } else {
        showProgress(true);
        const userData = {
          firstId: formik.values.firstId,
          userName: formik.values.userName,
          email: formik.values.email,
          adminRole: formik.values.adminRole as UserRole,
          group: formik.values.group,
        };
        updateUser(userData).then((response) => {
          if (response.success) {
            showProgress(false);
            showSnackbar('ユーザーを更新しました。', 'success');
            if (users.length < MAX_ARRAY_SIZE) {
              const updatedUser = users.map((user) => {
                if (user.firstId == formik.values.firstId) {
                  return { ...user, ...userData };
                }
                return user;
              });
              mutate(updatedUser, true);
            } else {
              mutate();
              cache.delete(`api/users/`);
            }
            cache.delete(`api/groups/`);
            navigation('/users');
          } else {
            showProgress(false);
            showSnackbar(
              `ユーザーの更新に失敗しました。 (${response.error.response.data['detail']})`,
              'error'
            );
          }
        });
      }
    });
  };

  // グループ追加モーダルOpen時
  const handleAddGroup = () => {
    // 追加用グループ
    const addDialogGroupRows: GroupRow[] = [];
    allGroups.forEach((group) => {
      const gGroup = groupRows.some((g) => g.firstId === group.firstId);
      if (gGroup) {
        return;
      }
      const tGroup = tmpGroupRows.some((g) => g.firstId === group.firstId);
      if (tGroup) {
        return;
      }
      addDialogGroupRows.push({
        firstId: group.firstId,
        groupName: group.groupName,
        role: 'general',
      });
    });
    setDialogGroupRows(addDialogGroupRows);
    setGroupDialogOpen(true);
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    const groupId = event.target.name;
    const role = event.target.value;
    // rowsを書き換える
    const newGroupRows = groupRows.map((group: GroupRow) => {
      if (group.firstId === groupId) {
        group['role'] = role;
        return group;
      } else {
        return group;
      }
    });
    setGroupRows(newGroupRows);
    const changedGroup = formik.values.group.map((group: MinimalGroup) => {
      if (group.groupId === groupId) {
        return {
          groupId: groupId,
          role: role,
        };
      } else {
        return group;
      }
    });
    formik.setFieldValue('group', changedGroup);
  };
  const handleDialogRoleChange = (event: SelectChangeEvent) => {
    const firstId = event.target.name;
    const role = event.target.value;
    // rowsを書き換える
    const newGroupRows = tmpGroupRows.map((group: GroupRow) => {
      if (group.firstId === firstId) {
        group['role'] = role;
        return group;
      } else {
        return group;
      }
    });
    setTmpGroupRows(newGroupRows);
  };
  const handleAddDialogRoleChange = (event: SelectChangeEvent) => {
    const firstId = event.target.name;
    const role = event.target.value;
    // rowsを書き換える
    const newGroupRows = dialogGroupRows.map((group: GroupRow) => {
      if (group.firstId === firstId) {
        group['role'] = role;
        return group;
      } else {
        return group;
      }
    });
    setDialogGroupRows(newGroupRows);
  };

  const deleteGroup = (groupId: GroupRow) => {
    const changedGroup = groupRows.filter((g) => g !== groupId);
    formik.setFieldValue(
      'group',
      changedGroup.map((g) => {
        return {
          groupId: g.firstId,
          role: g.role,
        };
      })
    );
    setGroupRows(changedGroup);
  };

  const addGroup = () => {
    const groups = [...groupRows, ...tmpGroupRows];
    formik.setFieldValue(
      'group',
      groups.map((u) => {
        return {
          groupId: u.firstId,
          role: u.role,
        };
      })
    );
    setGroupRows(groups);
  };

  const columns: GridColDef[] = [
    { field: 'groupName', headerName: 'グループ名', flex: 2 },
    {
      field: 'role',
      headerName: '権限',
      flex: 2,
      renderCell: (params: GridRenderCellParams<GroupRow>) => (
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <Select
            labelId={`${params.row.firstId}-select-label`}
            id={`${params.row.firstId}-select-label`}
            name={`${params.row.firstId}`}
            value={params.row.role}
            onChange={handleRoleChange}
            defaultValue="general"
          >
            <MenuItem value={'leader'}>編集</MenuItem>
            <MenuItem value={'general'}>閲覧</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      align: 'right',
      renderCell: (params: GridRenderCellParams<GroupRow>) => (
        <Tooltip title="削除" placement="top">
          <IconButton onClick={() => deleteGroup(params.row)}>
            <Cancel />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const dialogGroupsColumns: GridColDef[] = [
    { field: 'groupName', headerName: 'グループ名', flex: 2 },
    {
      field: 'role',
      headerName: '権限',
      flex: 2,
      renderCell: (params: GridRenderCellParams<GroupRow>) => (
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <Select
            labelId={`${params.row.firstId}-select-label`}
            id={`${params.row.firstId}-select-label`}
            name={`${params.row.firstId}`}
            value={params.row.role}
            onChange={handleDialogRoleChange}
            defaultValue="general"
          >
            <MenuItem value={'leader'}>編集</MenuItem>
            <MenuItem value={'general'}>閲覧</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<GroupRow>) => (
        <Tooltip title="削除" placement="top">
          <IconButton
            onClick={() => {
              setDialogGroupRows([...dialogGroupRows, params.row]);
              setTmpGroupRows(tmpGroupRows.filter((group) => group !== params.row));
            }}
          >
            <Cancel />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  const dialogAddGroupsColumns: GridColDef[] = [
    { field: 'groupName', headerName: 'グループ名', flex: 2 },
    {
      field: 'role',
      headerName: '権限',
      flex: 2,
      renderCell: (params: GridRenderCellParams<GroupRow>) => (
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <Select
            labelId={`${params.row.firstId}-select-label`}
            id={`${params.row.firstId}-select-label`}
            name={`${params.row.firstId}`}
            value={params.row.role}
            onChange={handleAddDialogRoleChange}
            defaultValue="general"
          >
            <MenuItem value={'leader'}>編集</MenuItem>
            <MenuItem value={'general'}>閲覧</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<GroupRow>) => (
        <Button
          onClick={() => {
            setDialogGroupRows(dialogGroupRows.filter((group) => group !== params.row));
            setTmpGroupRows([...tmpGroupRows, params.row]);
          }}
          variant="outlined"
          color="primary"
          autoFocus
        >
          + 追加
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item xs={12}>
          <form onSubmit={formik.handleSubmit}>
            <Grid item xs={12}>
              <Typography sx={{ mt: 3 }}>
                ユーザー名
                <Typography component="span" sx={{ color: 'error.main' }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                id="userName"
                name="userName"
                placeholder="ユーザー名"
                value={formik.values.userName}
                onChange={formik.handleChange}
                error={formik.touched.userName && Boolean(formik.errors.userName)}
                helperText={formik.touched.userName && formik.errors.userName}
              />
              <Typography sx={{ mt: 3 }}>
                メールアドレス
                <Typography component="span" sx={{ color: 'error.main' }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                id="email"
                name="email"
                type="email"
                placeholder="メールアドレス"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <Typography sx={{ mt: 3 }}>
                ユーザー種別
                <Typography component="span" sx={{ color: 'error.main' }}>
                  *
                </Typography>
              </Typography>
              {formik.values.adminRole === 'superadmin' ? (
                <Typography margin={1}>システム管理者</Typography>
              ) : (
                <FormControl
                  error={formik.touched.adminRole && Boolean(formik.errors.adminRole)}
                  variant="standard"
                >
                  <RadioGroup
                    row
                    name="adminRole"
                    value={formik.values.adminRole}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel value="admin" control={<Radio />} label="管理者ユーザー" />
                    <FormControlLabel value="notAdmin" control={<Radio />} label="一般ユーザー" />
                  </RadioGroup>
                  <FormHelperText id="my-helper-text">
                    {formik.touched.adminRole && formik.errors.adminRole}
                  </FormHelperText>
                </FormControl>
              )}
              {formik.values.adminRole === 'notAdmin' ? (
                <div style={{ width: '100%' }}>
                  <Typography sx={{ mt: 3 }}>グループの設定</Typography>
                  {(isLoading ? groupRows.length || props.user?.group.length : groupRows.length) ? (
                    <BaseDataGrid
                      loading={isLoading}
                      rows={groupRows}
                      columns={columns}
                      getRowIdFunc={(row: GroupRow) => row.firstId}
                      hideFooter={true}
                      headerHeight={0}
                      sx={{
                        '.MuiDataGrid-columnHeaders': {
                          border: 0,
                        },
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  <Button color="primary" onClick={() => handleAddGroup()}>
                    + 追加
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </Grid>
          </form>
        </Grid>

        <Box
          component="span"
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ mt: 3 }}
        >
          {formik.values.firstId === '' ? (
            <Grid container justifyContent="flex-end">
              <Button sx={{ mr: 1 }} color="inherit" onClick={() => setDialogOpen(true)}>
                キャンセル
              </Button>
              <Button
                disabled={Object.keys(formik.errors).length > 0}
                variant="contained"
                onClick={() => handlePost()}
              >
                新規作成
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Button sx={{ mr: 1 }} color="inherit" onClick={() => setDialogOpen(true)}>
                キャンセル
              </Button>
              <Button
                disabled={Object.keys(formik.errors).length > 0}
                variant="contained"
                onClick={() => handlePut()}
              >
                更新
              </Button>
            </Grid>
          )}
        </Box>
      </Grid>
      <ConfirmDialogDesregard
        isOpen={dialogOpen}
        cancelFunc={() => setDialogOpen(false)}
        okFunc={() => navigation('/users')}
      />
      <ModalAddItems
        isOpen={groupDialogOpen}
        cancelFunc={() => {
          setTmpGroupRows([]);
          setGroupDialogOpen(false);
        }}
        okFunc={() => {
          addGroup();
          setTmpGroupRows([]);
          setGroupDialogOpen(false);
        }}
      >
        <Typography variant="h6" marginBottom={2}>
          グループの設定
        </Typography>
        <Paper>
          <div style={{ display: 'flex', height: '100%' }}>
            <BaseDataGrid
              loading={isLoading}
              rows={dialogGroupRows}
              columns={dialogAddGroupsColumns}
              getRowIdFunc={(row: GroupRow) => row.firstId}
            />
          </div>
        </Paper>
        <Typography variant="h6" marginTop={3} marginBottom={2}>
          追加したグループ
        </Typography>
        {tmpGroupRows.length ? (
          <Paper elevation={0}>
            <div style={{ display: 'flex', height: '100%' }}>
              <BaseDataGrid
                loading={isLoading}
                rows={tmpGroupRows}
                columns={dialogGroupsColumns}
                getRowIdFunc={(row: GroupRow) => row.firstId}
                hideFooter={true}
                headerHeight={0}
                sx={{
                  '.MuiDataGrid-columnHeaders': {
                    border: 0,
                  },
                }}
              />
            </div>
          </Paper>
        ) : (
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="body2">追加したグループが表示されます</Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </ModalAddItems>
    </div>
  );
}
