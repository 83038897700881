import * as React from 'react';

import { AlertColor, SnackbarOrigin } from '@mui/material';
import { GlobalSnackbar } from '../components/layoutparts/GlobalSnackbar';

export type SnackbarContextType = {
  message: string;
  severity: AlertColor;
  isLicValidity: boolean;
  toggleIsLicValidity: (validity: boolean, status?: number, message?: string) => void;
  vertical?: SnackbarOrigin['vertical'];
  horizontal?: SnackbarOrigin['horizontal'];
  showSnackbar: (
    message: string,
    severity: AlertColor,
    vertical?: SnackbarOrigin['vertical'],
    horizontal?: SnackbarOrigin['horizontal']
  ) => void;
};

export const SnackbarContext = React.createContext<SnackbarContextType>({
  message: '',
  severity: 'error',
  isLicValidity: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  showSnackbar: (_message: string, _severity: AlertColor) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  toggleIsLicValidity: (_validity: boolean, _status?: number, _message?: string) => {},
});

export const SnackbarContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const context: SnackbarContextType = React.useContext(SnackbarContext);
  const [message, setMessage] = React.useState(context.message);
  const [severity, setSeverity] = React.useState(context.severity);
  const [isLicValidity, setIsLicValidity] = React.useState(context.isLicValidity);
  const [vertical, setVertical] = React.useState(context.vertical);
  const [horizontal, setHorizontal] = React.useState(context.horizontal);

  const newContext: SnackbarContextType = React.useMemo(
    () => ({
      message,
      severity,
      isLicValidity,
      vertical,
      horizontal,
      showSnackbar: (
        message: string,
        severity: AlertColor,
        vertical?: SnackbarOrigin['vertical'],
        horizontal?: SnackbarOrigin['horizontal']
      ) => {
        setMessage(message);
        setSeverity(severity);
        setVertical(vertical);
        setHorizontal(horizontal);
      },
      toggleIsLicValidity: (validity: boolean, status?: number, message?: string) => {
        if (!validity && isLicValidity) {
          setIsLicValidity(validity);
          return;
        }
        if (validity && !isLicValidity && status === 403 && message === 'Invalid license') {
          setIsLicValidity(validity);
          return;
        }
      },
    }),
    [
      message,
      severity,
      setMessage,
      setSeverity,
      setVertical,
      setHorizontal,
      isLicValidity,
      setIsLicValidity,
    ]
  );

  const handleClose = React.useCallback(() => {
    setMessage('');
  }, [setMessage]);

  return (
    <SnackbarContext.Provider value={newContext}>
      {children}
      <GlobalSnackbar
        open={newContext.message !== ''}
        message={newContext.message}
        severity={newContext.severity}
        vertical={newContext.vertical}
        horizontal={newContext.horizontal}
        onClose={handleClose}
      />
    </SnackbarContext.Provider>
  );
};

export function useSnackbar(): SnackbarContextType {
  return React.useContext(SnackbarContext);
}
