import { MFAConfigResp } from '../../common/types/Responses';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWR from 'swr';

const config: SWRConfiguration = { revalidateOnMount: true };

export const useMfaConfigSwr = () => {
  const { data, error } = useSWR<MFAConfigResp>(
    `/api/mfa/system_config/`,
    fetcherKeysToCamel,
    config
  );
  const mfaConfig = data ?? { mfaConfiguration: 'OFF' };
  return {
    mfaConfig,
    isLoading: !error && !data,
    isError: error,
  };
};
