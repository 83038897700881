import React from 'react';
import InnerJoin from './InnerJoin';
import InnerJoinError from './InnerJoinError';
import JoinIcon from './JoinIcon';
import LeftJoin from './LeftJoin';
import LeftJoinError from './LeftJoinError';
import RightJoin from './RightJoin';
import RightJoinError from './RightJoinError';

type Props = {
  type: string;
  error?: boolean;
  className?: string;
  onClick?: () => void;
};

const JoinTableIcon: React.FC<Props> = ({ type, error, className, onClick }) => {
  switch (type) {
    case 'inner':
      return error ? (
        <InnerJoinError className={className} onClick={onClick} />
      ) : (
        <InnerJoin className={className} onClick={onClick} />
      );
    case 'left':
      return error ? (
        <LeftJoinError className={className} onClick={onClick} />
      ) : (
        <LeftJoin className={className} onClick={onClick} />
      );
    case 'right':
      return error ? (
        <RightJoinError className={className} onClick={onClick} />
      ) : (
        <RightJoin className={className} onClick={onClick} />
      );
    default:
      return <JoinIcon className={className} />;
  }
};

export default JoinTableIcon;
