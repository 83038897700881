import { Connections } from '../types';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWR from 'swr';
import { useSnackbar } from '../../hooks/SnackBar';

const config: SWRConfiguration = {};

export const useConnectionsSwr = () => {
  const { toggleIsLicValidity } = useSnackbar();
  const {
    data,
    error: connectionsError,
    mutate,
  } = useSWR<Connections[]>(`api/connections/`, fetcherKeysToCamel, config);
  const connections = data ? data : [];

  if (connectionsError) {
    toggleIsLicValidity(
      true,
      connectionsError.response.status,
      connectionsError.response.data['licmessage']
    );
  } else {
    toggleIsLicValidity(false);
  }

  return {
    connections: connections,
    isLoading: !data,
    isError: connectionsError,
    mutate,
  };
};
