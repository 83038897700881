import { BaseContent } from '../components/BaseContent';
import CreateUserForm from '../components/layoutparts/CreateUserForm';
import EditUserForm from '../components/layoutparts/EditUserForm';
import { useParams } from 'react-router-dom';

export default function UserPage() {
  const { firstId: firstId } = useParams();

  return (
    <>
      {firstId === undefined ? (
        <BaseContent title="ユーザー作成">
          <CreateUserForm user={undefined}></CreateUserForm>
        </BaseContent>
      ) : (
        <BaseContent title="ユーザー編集">
          <EditUserForm firstId={firstId}></EditUserForm>
        </BaseContent>
      )}
    </>
  );
}
