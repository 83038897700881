import { BaseContent } from '../components/BaseContent';
import { Button, Grid } from '@mui/material';
import GroupsTable from '../components/layoutparts/GroupsTable';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

export default function GroupsPage() {
  const auth = useAuth();

  return (
    <BaseContent title="グループ一覧">
      <Grid container justifyContent="flex-end">
        {auth.role == 'admin' || auth.role == 'superadmin' ? (
          <Button
            sx={{ position: 'relative', bottom: '50px' }}
            component={Link}
            to={`/group/new/`}
            variant="contained"
            color="primary"
            disabled={auth.license?.isExpired}
          >
            新規グループ作成
          </Button>
        ) : null}
      </Grid>
      <GroupsTable></GroupsTable>
    </BaseContent>
  );
}
