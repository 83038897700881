import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import { License } from '../types';
import useSWR from 'swr';

const config: SWRConfiguration = {};

export const useLicenseSwr = (revalidateOnMount = false) => {
  config.revalidateOnMount = revalidateOnMount;
  const { data, error, isValidating, mutate } = useSWR<License>(
    `api/license/`,
    fetcherKeysToCamel,
    config
  );

  const initialLicense: License = {
    licenseKey: '',
    expireDate: '',
    userNum: 0,
    datasourceNum: 0,
    datasourceType: [],
    isValid: false,
    checkedAt: '',
    createdAt: '',
    updatedAt: '',
  };

  return {
    license: data ? data : initialLicense,
    isLoading: !error && !data,
    isError: error,
    isValidating: isValidating,
    mutate,
  };
};
