import { BaseContent } from '../components/BaseContent';
import QueriesTable from '../components/layoutparts/QueriesTable';

export default function QueriesPage() {
  return (
    <BaseContent title="保存したデータ抽出条件">
      <QueriesTable />
    </BaseContent>
  );
}
