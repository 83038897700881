import { useLocation, useParams } from 'react-router-dom';

import { BaseContent } from '../components/BaseContent';
import CreateSimpleQueryForm from '../components/layoutparts/CreateSimpleQueryForm';
import { Column, JoinedTable, SimpleQueryForm, TableInfo } from '../common/types';
import { EnclosedText, GetQueryResp, Join } from '../common/types/Responses';
import { useSnackbar } from '../hooks/SnackBar';
import { useEffect, useState } from 'react';
import { generateUuid } from '../modules/common';
import Progress from '../components/layoutparts/Progress';
import {
  conditionType,
  useColumnsOfMultipleTablesSwr,
} from '../common/swr/useColumnsOfMultipleTablesSwr';
import jwtAxios from '../common/axios';
import { keysToCamel } from '../common/func/converter';

type Props = {
  hiddenBaseContent?: boolean;
};

export default function SimpleQueryPage({ hiddenBaseContent }: Props) {
  return (
    <>
      {hiddenBaseContent ? (
        <>
          <SimpleQueryContent />
        </>
      ) : (
        <BaseContent title="シンプルな条件設定">
          <SimpleQueryContent />
        </BaseContent>
      )}
    </>
  );
}

const SimpleQueryContent = () => {
  // 更新処理の遷移
  const { uuid } = useParams();
  // クエリ一覧からの遷移(コピーして作成)
  const location = useLocation();
  const stateQuery: GetQueryResp = {
    uuid: '',
    datasource: location.state?.query?.datasource,
    queryName: location.state?.query?.queryName,
    description: location.state?.query?.description,
    query: location.state?.query?.query,
    schemaN: location.state?.query?.schemaN,
    tableN: location.state?.query?.tableN,
    simple: location.state?.query?.simple,
    csvBomOption: location.state?.query?.csvBomOption ?? false,
    createdAt: location.state?.query?.createdAt,
    updatedAt: location.state?.query?.updatedAt,
    lastDownloadAt: location.state?.query?.lastDownloadAt,
  };

  // データソース一覧からの遷移(データソースだけ選択)
  const datasource: SimpleQueryForm = {
    uuid: '',
    datasource: location.state?.connection?.connectionId,
    queryName: '',
    description: '',
    schemaN: '',
    tableN: '',
    simple: {
      select: [],
      join: [],
      where: [],
      orderby: [],
    },
    csvBomOption: true,
  };

  const { showSnackbar } = useSnackbar();

  // Save table detail info
  const [fetchedTables, setFetchedTables] = useState<TableInfo[]>([]);
  // Keep track of joined tables
  const [joinedTables, setJoinedTables] = useState<JoinedTable[]>([]);
  // Query data from API
  const [queryData, setQueryData] = useState<SimpleQueryForm>();

  // Lastest query data from API
  const [query, setQuery] = useState<GetQueryResp>();
  // Query API Error handling
  const [isError, setIsError] = useState<string>();
  // Loading till all data is prepared for modification
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      let queryRes;

      if (uuid) {
        let retryCount = 0;
        const maxRetries = 3;

        // APIs
        const fetchDataWithRetry = async () => {
          try {
            const response = await jwtAxios.get(`api/query/?uuid=${uuid}`);
            queryRes = keysToCamel(response.data);
            setQuery(queryRes);
          } catch (err: any) {
            if (retryCount < maxRetries) {
              retryCount++;
              fetchDataWithRetry();
            } else {
              setIsError(err?.response?.data['detail'] ?? 'Unknown error');
              setIsLoading(false);
            }
          }
        };

        await fetchDataWithRetry();
      } else if (location.state?.query) {
        queryRes = stateQuery;
        setQuery(stateQuery);
      } else {
        setIsLoading(false);
      }

      if (queryRes) {
        const joinedTablesRes = [
          {
            schemaN: queryRes.schemaN,
            tableUuid: generateUuid(),
            tableN: queryRes.tableN,
          },
          ...(queryRes.simple?.join?.map((e: Join) => {
            return {
              schemaN: e.schemaN,
              tableUuid: generateUuid(),
              tableN: e.table,
              alias: e.tableAlias ?? undefined,
            };
          }) ?? []),
        ];
        if (joinedTablesRes.length > 0) {
          setJoinedTables(joinedTablesRes);
        } else {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, []);

  const hasNotFetchedTables = joinedTables
    .filter(
      (value, index, array) =>
        array.findIndex((e) => e.schemaN === value.schemaN && e.tableN === value.tableN) === index
    )
    .filter((e) => !fetchedTables.some((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN));

  const {
    newTables,
    isLoading: isColumnLoading,
    isError: columnsIsError,
  } = useColumnsOfMultipleTablesSwr(
    query?.datasource ?? '',
    hasNotFetchedTables,
    conditionType.simple
  );

  useEffect(() => {
    if (
      newTables?.some(
        (e) => !fetchedTables.some((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN)
      )
    ) {
      setFetchedTables(newTables);
    }
  }, [newTables, isColumnLoading]);

  useEffect(() => {
    const updatedJoinedTables = joinedTables.map((e) => {
      return {
        ...e,
        columns: fetchedTables
          ?.find((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN)
          ?.columns?.map((e1) => {
            return {
              columnUuid: generateUuid(),
              tableUuid: e.tableUuid,
              name: e1.name,
              dataType: e1.dataType[0],
            };
          }),
      };
    });
    setJoinedTables(updatedJoinedTables);

    if (
      query &&
      joinedTables.every((e) =>
        fetchedTables.some((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN)
      )
    ) {
      setQueryData({
        uuid: query?.uuid ?? '',
        datasource: query?.datasource ?? '',
        queryName: query?.queryName ?? '',
        description: query?.description ?? '',
        schemaN: query?.schemaN ?? '',
        tableN: query?.tableN ?? '',
        simple: {
          select: convertData(
            query?.simple?.select ?? [],
            updatedJoinedTables,
            (table, column, item) => {
              return {
                tableUuid: table.tableUuid,
                columnUuid: column.columnUuid,
                alias: item.alias ?? '',
              };
            }
          ),
          join:
            query?.simple?.join.map((e) => {
              const table = e.tableAlias
                ? updatedJoinedTables.find((e1) => e1.alias === e.tableAlias)
                : updatedJoinedTables[0];
              return {
                schemaN: table?.schemaN ?? '',
                tableUuid: table?.tableUuid ?? '',
                tableN: table?.tableN ?? '',
                type: e.type,
                conditions: e.conditions.map((e1) => {
                  return {
                    localKey: convertData(
                      [{ tableAlias: e1.localKey?.tableAlias, column: e1.localKey?.column ?? '' }],
                      updatedJoinedTables,
                      (table, column) => column
                    )[0],
                    operator: e1.operator,
                    foreignKey: table?.columns?.find((e2) => e2.name === e1.foreignKey?.column),
                  };
                }),
              };
            }) ?? [],
          where: convertData(
            query?.simple?.where ?? [],
            updatedJoinedTables,
            (table, column, item) => {
              return {
                ...item,
                tableUuid: table.tableUuid,
                columnUuid: column.columnUuid,
              };
            }
          ),
          orderby: convertData(
            query?.simple?.orderby ?? [],
            updatedJoinedTables,
            (table, column, item) => {
              return {
                tableUuid: table.tableUuid,
                columnUuid: column.columnUuid,
                order: item.order,
              };
            }
          ),
        },
        enclosedText: query?.enclosedText ?? EnclosedText.None,
        csvBomOption: query?.csvBomOption ?? true,
      });

      // Finish preparing data for modification
      setIsLoading(false);
    }
  }, [fetchedTables]);

  function convertData<
    T extends {
      tableAlias?: string;
      column: string;
    },
    U,
  >(
    data: T[],
    joinedTables: JoinedTable[],
    mapFn: (table: JoinedTable, column: Column, item: T) => U
  ): U[] {
    return data.reduce((result: U[], item) => {
      const table = item.tableAlias
        ? joinedTables.find((e) => e.alias === item.tableAlias)
        : joinedTables[0];
      const column = table?.columns?.find((e) => e.name === item.column);
      if (table && column) {
        result.push(mapFn(table, column, item));
      }
      return result;
    }, []);
  }

  // swr error
  useEffect(() => {
    if (isError) showSnackbar(`ダウンロード条件取得APIエラー (${isError})`, 'error');
    if (columnsIsError) {
      showSnackbar(`カラム一覧取得APIエラー (${columnsIsError.response.data['detail']})`, 'error');
      // Stop loading when error occurs during fetching columns
      setIsLoading(false);
    }
  }, [isError, columnsIsError, showSnackbar]);

  if (uuid || location.state?.query) {
    if (isLoading) {
      return <Progress open={true} />;
    } else {
      return (
        <CreateSimpleQueryForm
          preFetchedTables={fetchedTables}
          preJoinedTables={joinedTables}
          query={queryData}
        />
      );
    }
  } else if (location.state?.connection) {
    return <CreateSimpleQueryForm query={datasource} />;
  } else {
    return <CreateSimpleQueryForm />;
  }
};
