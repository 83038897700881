import type { SWRConfiguration } from 'swr';
import { User } from '../types';
import { fetcherKeysToCamel } from './fetcher';
import useSWRImmutable from 'swr/immutable';

const config: SWRConfiguration = {};

export const useUserSwr = (firstId: string, revalidateOnMount = false) => {
  config.revalidateOnMount = revalidateOnMount;
  const { data, error, isValidating } = useSWRImmutable<User>(
    `api/user/?first_id=${firstId}`,
    fetcherKeysToCamel,
    config
  );

  return {
    user: data,
    isLoading: !error && !data,
    isError: error,
    isValidating: isValidating,
  };
};
