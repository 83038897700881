import { BaseContent } from '../components/BaseContent';
import UsersTable from '../components/layoutparts/UsersTable';

export default function UsersPage() {
  return (
    <BaseContent title="ユーザー一覧">
      <UsersTable></UsersTable>
    </BaseContent>
  );
}
