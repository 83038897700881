import { Box, Container, CssBaseline, Grid, Paper, Toolbar, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import AppBar from '../components/AppBar';
import { MenuDrawer } from '../components/MenuDrawer';
import PrivateRoute from '../components/PrivateRoute';
import { useState } from 'react';
import { useAuth } from '../hooks/use-auth';

const mdTheme = createTheme();

type BaseContentProps = {
  children?: React.ReactNode;
  title: string;
  discription?: string;
};

export const BaseContent: React.FC<BaseContentProps> = ({ title, discription, children }) => {
  const [open, setOpen] = useState(true);
  const auth = useAuth();

  return (
    <PrivateRoute>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar open={open} setOpen={setOpen} />
          <MenuDrawer open={open} setOpen={setOpen} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />
            <Container
              maxWidth="xl"
              sx={{
                mt:
                  auth.license?.licenseKey &&
                  (auth.license?.isExpired || auth.license.isApproaching)
                    ? 10
                    : 2,
                mb: 4,
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                    elevation={0}
                  >
                    <Typography variant="h5" mb={2}>
                      {title}
                    </Typography>
                    {discription ? (
                      <Typography variant="subtitle2" mb={2}>
                        {discription}
                      </Typography>
                    ) : (
                      <></>
                    )}

                    <></>
                    {children}
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </PrivateRoute>
  );
};
