import { Group } from '../types';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWR from 'swr';

const config: SWRConfiguration = {};

export const useGroupsSwr = () => {
  const { data, error, mutate } = useSWR<Group[]>(`api/groups/`, fetcherKeysToCamel, config);
  const groups = data === undefined ? [] : data;
  return {
    groups: groups,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
