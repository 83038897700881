import { GetConnectionResp } from '../types/Responses';
import { fetcherKeysToCamel } from './fetcher';
import { DataSource } from '../types/dataSource';
import useSWR from 'swr';

export const useConnectionSwr = (
  connectionId: string,
  connectionType: DataSource | string,
  shouldFetch = true
) => {
  const { data, error, isValidating } = useSWR<GetConnectionResp>(
    shouldFetch
      ? `api/${connectionType.toLowerCase()}/connection/?connection_id=${connectionId}`
      : null,
    fetcherKeysToCamel
  );

  return {
    connection: data,
    isLoading: !error && !data,
    error: error,
    isValidating: isValidating,
  };
};
