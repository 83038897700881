import { EnclosedText, EnclosedTextOption } from '../types/Responses';

export const ENCLOSED_TEXT_NAME = {
  [EnclosedText.None]: `なし`,
  [EnclosedText.SingleQuotation]: `'（引用符）`,
  [EnclosedText.DoubleQuotation]: `"（二重引用符）`,
};

export const ENCLOSED_TEXT_OPTIONS: EnclosedTextOption[] = [
  {
    label: ENCLOSED_TEXT_NAME[EnclosedText.None],
    value: EnclosedText.None,
  },
  {
    label: ENCLOSED_TEXT_NAME[EnclosedText.SingleQuotation],
    value: EnclosedText.SingleQuotation,
  },
  {
    label: ENCLOSED_TEXT_NAME[EnclosedText.DoubleQuotation],
    value: EnclosedText.DoubleQuotation,
  },
];
