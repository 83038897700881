import { useAuth } from '../hooks/use-auth';
import { FrontendUserRole } from '../common/types';
import { Forbidden } from '../pages/Forbidden';

type Props = {
  children?: React.ReactNode;
  allowUserRoles: FrontendUserRole[];
};

const RouteAuthGuard: React.FC<Props> = (props) => {
  const auth = useAuth();
  const isAllow = props.allowUserRoles.includes(auth.frontendUserRole);

  return isAllow ? <>{props.children}</> : <Forbidden />;
};

export default RouteAuthGuard;
