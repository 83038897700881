import * as yup from 'yup';

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Checkbox,
  TextField,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { GridColDef, GridRowModel, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';
import {
  FormikErrors,
  FormikTouched,
  FormikValues,
  setNestedObjectValues,
  useFormik,
} from 'formik';
import { ChangeEvent, useEffect, useState } from 'react';
import {
  PostQueryReq,
  PostPreviewColumn,
  PostPreviewReq,
  PostTempDownloadReq,
  PutQueryReq,
  SchemaTableSetting,
  EnclosedText,
} from '../../common/types/Responses';
import { JoinedTable, SimpleQueryForm, TableInfo } from '../../common/types';
import { useNavigate } from 'react-router-dom';
import jwtAxios from '../../common/axios';
import { useConnectionsSwr } from '../../common/swr/useConnectionsSwr';
import { useTablesSwr } from '../../common/swr/useTablesSwr';
import { useSnackbar } from '../../hooks/SnackBar';
import OrderByConditionParts from './OrderByConditionParts';
import Progress from './Progress';
import WhereConditionParts from './WhereConditionParts';
import SchemaConditionParts from './SchemaConditionParts';
import SelectConditionParts from './SelectConditionParts';
import { generateUuid } from '../../modules/common';
import { BookmarkAddOutlined } from '@mui/icons-material';
import { BaseDataGrid } from '../BaseDataGrid';
import { useProgress } from '../../hooks/useProgress';
import ConfirmDialogDesregard from './ConfirmDialogDesregard';
import { useConnectionSwr } from '../../common/swr/useConnectionSwr';
import { keysToCamel, keysToSnake } from '../../common/func/converter';
import { useSWRConfig } from 'swr';
import { useQueriesSwr } from '../../common/swr/useQueriesSwr';
import { VariantType, useSnackbar as useNotistackSnackbar } from 'notistack';
import { ENCLOSED_TEXT_OPTIONS } from '../../common/const/enclosedText';
import { useAuth } from '../../hooks/use-auth';
import SqlPreviewDialog from './SqlPreviewDialog';
import { formatSimpleQueryData } from '../../common/func/functions';
import {
  conditionType,
  useColumnsOfMultipleTablesSwr,
} from '../../common/swr/useColumnsOfMultipleTablesSwr';

const validationSchema = yup.object({
  datasource: yup.string().required('データソース名は必須です。'),
  schemaN: yup.string().required('スキーマは必須です。'),
  tableN: yup.string().required('テーブルは必須です。'),
  queryName: yup
    .string()
    .required('タイトルは必須です。')
    .max(30, 'タイトルは30文字以下で入力してください。'),
  description: yup.string().max(300, '説明は300文字以下で入力してください。'),
  simple: yup.object({
    select: yup
      .array()
      .of(
        yup.object({
          tableUuid: yup.string().required(),
          columnUuid: yup.string().required(),
          alias: yup
            .string()
            .test(
              'unique-alias',
              '重複入力はできません。別の名前を入力してください。',
              (value, context) => {
                if (value === undefined || value.trim() === '') return true;
                const selectArray = context.options.context?.simple.select;
                const aliasCount = selectArray.filter(
                  (item: any) => item.alias?.trim() === value.trim()
                ).length;
                return aliasCount === 1;
              }
            ),
        })
      )
      .required(),
    join: yup.array().of(
      yup.object({
        schemaN: yup.string().required(),
        tableUuid: yup.string().required(),
        tableN: yup.string().required(),
        type: yup.string().required(),
        conditions: yup
          .array()
          .of(
            yup.object({
              localKey: yup.object().required(),
              foreignKey: yup.object().required(),
              operator: yup.string().required(),
            })
          )
          .required()
          .min(1),
      })
    ),
    where: yup.array().required(),
    orderby: yup.array().required(),
  }),
  enclosedText: yup.string().required('ストリング値の引用符は必須です。'),
});

type Props = {
  preFetchedTables?: TableInfo[];
  preJoinedTables?: JoinedTable[];
  query?: SimpleQueryForm;
};

export default function CreateSimpleQueryForm({ preFetchedTables, preJoinedTables, query }: Props) {
  const auth = useAuth();
  const { showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();
  const navigation = useNavigate();
  const { enqueueSnackbar } = useNotistackSnackbar();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogSqlOpen, setDialogSqlOpen] = useState<boolean>(false);

  const [dataSourceType, setDataSourceType] = useState<string>('');

  const { mutate } = useQueriesSwr();
  const { cache } = useSWRConfig();

  // セレクトのラベルを非表示にする
  const [hideSelectLabel, setHideSelectLabel] = useState(false);
  const [hideEnclosedTextSelectLabel, setHideEnclosedTextSelectLabel] = useState(false);

  // プレビューのローディング
  const [previewLoading, setPreviewLoading] = useState(false);

  // 条件保存、ダウンロードボタンのdisabled制御
  const [submitButton, setSubmitButton] = useState(false);

  // preview
  const [preview, setPreview] = useState(false);
  const [previewColumn, setPreviewColumn] = useState<GridColDef[]>([]);
  const [previewRow, setPreviewRow] = useState<GridRowsProp>([]);

  // Save table detail info
  const [fetchedTables, setFetchedTables] = useState<TableInfo[]>(
    query ? preFetchedTables ?? [] : []
  );
  // Keep track of joined tables
  const [joinedTables, setJoinedTables] = useState<JoinedTable[]>(
    query ? preJoinedTables ?? [] : []
  );

  // formik
  const initialSimpleQueryForm: SimpleQueryForm = {
    uuid: query?.uuid ?? '',
    datasource: query?.datasource ?? '',
    queryName: query?.queryName ?? '',
    description: query?.description ?? '',
    schemaN: query?.schemaN ?? '',
    tableN: query?.tableN ?? '',
    simple: query?.simple ?? {
      join: [],
      select: [],
      where: [],
      orderby: [],
    },
    enclosedText: query?.enclosedText ?? EnclosedText.None,
    csvBomOption: query?.csvBomOption ?? true,
  };

  const formik = useFormik({
    initialValues: initialSimpleQueryForm,
    validationSchema,
    onSubmit: (values) => {
      values;
    },
  });

  // APIs
  const {
    connections,
    isLoading: connectionsIsLoading,
    isError: connectionsIsError,
  } = useConnectionsSwr();

  const {
    error: connectionIsError,
    isLoading: connectionIsLoading,
    isValidating: connectionIsValidating,
  } = useConnectionSwr(
    formik.values.datasource,
    dataSourceType,
    formik.values.datasource != '' && dataSourceType != ''
  );

  const {
    tables,
    isLoading: tablesIsLoading,
    isError: tablesIsError,
  } = useTablesSwr(
    formik.values.datasource ? [formik.values.datasource] : [],
    formik.values.datasource !== ''
  );

  const hasNotFetchedTables = joinedTables
    .filter(
      (value, index, array) =>
        array.findIndex((e) => e.schemaN === value.schemaN && e.tableN === value.tableN) === index
    )
    .filter((e) => !fetchedTables.some((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN));

  const {
    newTables,
    isLoading: isColumnLoading,
    isError: columnsIsError,
  } = useColumnsOfMultipleTablesSwr(
    formik.values.datasource,
    hasNotFetchedTables,
    conditionType.simple
  );

  useEffect(() => {
    if (
      newTables?.some(
        (e) => !fetchedTables.some((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN)
      )
    ) {
      setFetchedTables((oldVal) => [
        ...oldVal,
        ...newTables.filter(
          (e) => !fetchedTables.some((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN)
        ),
      ]);
    }
  }, [newTables, isColumnLoading]);

  useEffect(() => {
    setJoinedTables((oldVal) =>
      oldVal.map((e) => {
        return e.columns !== undefined
          ? e
          : {
              ...e,
              columns: fetchedTables
                ?.find((e1) => e1.schemaN === e.schemaN && e1.tableN === e.tableN)
                ?.columns?.map((e1) => {
                  return {
                    columnUuid: generateUuid(),
                    tableUuid: e.tableUuid,
                    name: e1.name,
                    dataType: e1.dataType[0],
                  };
                }),
            };
      })
    );
  }, [fetchedTables]);

  // swr error
  useEffect(() => {
    if (connectionsIsError)
      showSnackbar(
        `データソース一覧取得APIエラー (${connectionsIsError.response.data['detail']})`,
        'error'
      );
    if (connectionIsError)
      showSnackbar(
        `データソース取得APIエラー (${connectionIsError.response.data['detail']})`,
        'error'
      );
    if (tablesIsError)
      showSnackbar(`テーブル一覧取得APIエラー (${tablesIsError.response.data['detail']})`, 'error');
    if (columnsIsError)
      showSnackbar(`カラム一覧取得APIエラー (${columnsIsError.response.data['detail']})`, 'error');
  }, [connectionsIsError, connectionIsError, tablesIsError, columnsIsError, showSnackbar]);

  const schemaRows: SchemaTableSetting[] = [];

  tables.map((schemas) => {
    schemas.map((schema) => {
      schema.tables.map((table: string) => {
        schemaRows.push({ schemaN: schema.schemaN, tableN: table });
      });
    });
  });

  useEffect(() => {
    // 行データ
    const newSchemaNames: string[] = [];
    const newTableNames: string[] = [];
    tables?.map((table) => {
      table?.map((schema) => {
        newSchemaNames.push(schema.schemaN);
        schema.tables.map((tableName) => {
          newTableNames.push(tableName);
        });
      });
    });
  }, [tablesIsLoading]);

  const handleScrollToFirstError = (errors: FormikErrors<FormikValues>) => {
    const fieldOrdered: string[] = ['queryName', 'description'];

    // Find first error from formik
    const firstErrorField = Object.keys(errors).length > 0 ? Object.keys(errors)[0] : undefined;
    if (!firstErrorField) return;

    if (fieldOrdered.includes(firstErrorField)) {
      document
        .getElementById(firstErrorField)
        ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      return;
    }

    if (firstErrorField === 'simple' && typeof errors.simple === 'object') {
      if (Array.isArray(errors.simple.join)) {
        // Pattern: simple.join[0].conditions[0].localKey OR simple.join[0].conditions[0].foreignKey
        const firstIndex = errors.simple.join?.findIndex((e) => e !== undefined);
        if (errors.simple.join[firstIndex]) {
          document
            .getElementById(`simple.join[${firstIndex}]`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        return;
      }
      if (Array.isArray((errors.simple as FormikErrors<any>)?.select)) {
        const firstIndex = (
          (errors.simple as FormikErrors<any>).select as FormikErrors<any>[]
        ).findIndex((e) => e?.alias !== undefined);
        if (firstIndex !== undefined && firstIndex !== -1) {
          document
            .getElementById(`simple.select[${firstIndex}].alias`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          return;
        }
      }
    }
  };

  const handlePreview = () => {
    formik
      .validateForm()
      .then((errors) => {
        if (Object.keys(errors).length !== 0) {
          formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
          handleScrollToFirstError(errors);
        } else {
          setPreviewLoading((prevLoading) => !prevLoading);

          const params: PostPreviewReq = {
            datasource: formik.values.datasource,
            queryName: formik.values.queryName,
            description: formik.values.description,
            schemaN: formik.values.schemaN,
            tableN: formik.values.tableN,
            simple: formatSimpleQueryData(formik.values.simple, joinedTables),
            enclosedText: formik.values.enclosedText,
          };
          jwtAxios
            .post('/api/preview/', keysToSnake(params))
            .then((response) => {
              const previewColumns = response.data.columns.map((c: PostPreviewColumn) =>
                keysToCamel(c)
              );
              const columns: GridColDef[] = [];
              previewColumns.forEach((column: PostPreviewColumn, columnIndex: number) => {
                const columnDef: GridColDef = {
                  field: `${column.name}_${columnIndex}`,
                  headerName: `${column.name} (${column.dataType[0].type})`,
                  flex: 1,
                  minWidth: 200,
                };
                columns.push(columnDef);
              });

              const rows: GridValidRowModel[] = [];
              response.data.results.forEach((result: GridRowsProp) => {
                const row: { [prop: string]: GridValidRowModel } = {};

                result.forEach((value, resultIndex) => {
                  const columnName = previewColumns[resultIndex].name;
                  row[`${columnName}_${resultIndex}`] = value;
                });
                rows.push(row);
              });
              setPreviewLoading((prevLoading) => !prevLoading);
              setPreview(true);
              setPreviewColumn(columns);
              setPreviewRow(rows);
              showSnackbar('プレビューを取得しました。', 'success');
            })
            .catch((error) => {
              setPreviewLoading((prevLoading) => !prevLoading);
              setPreview(false);
              setPreviewColumn([]);
              setPreviewRow([]);
              showSnackbar(
                `プレビューの取得に失敗しました。 (${error.response.data['detail']})`,
                'error'
              );
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onClickShowSqlPreview = () => {
    formik.validateForm().then((errors) => {
      if (Array.isArray(errors?.simple?.join)) {
        // Pattern: simple.join[0].conditions[0].localKey OR simple.join[0].conditions[0].foreignKey
        const firstIndex = errors.simple.join.findIndex((e) => e !== undefined);
        if (errors.simple.join[firstIndex]) {
          document
            .getElementById(`simple.join[${firstIndex}]`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } else if (Array.isArray((errors.simple as FormikErrors<any>)?.select)) {
        const firstIndex = (
          (errors.simple as FormikErrors<any>).select as FormikErrors<any>[]
        ).findIndex((e) => e?.alias !== undefined);
        if (firstIndex !== undefined && firstIndex !== -1) {
          document
            .getElementById(`simple.select[${firstIndex}].alias`)
            ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } else {
        setDialogSqlOpen(true);
      }
    });
  };

  const handleTempDownload = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
        handleScrollToFirstError(errors);
      } else {
        showProgress(true);
        const req: PostTempDownloadReq = {
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          simple: formatSimpleQueryData(formik.values.simple, joinedTables),
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };

        const variant: VariantType = 'success';
        enqueueSnackbar(`${formik.values.queryName}のデータ抽出を開始しました。`, { variant });

        jwtAxios
          .post('/api/temp_download/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`データ抽出に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const handlePost = () => {
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
        handleScrollToFirstError(errors);
      } else {
        showProgress(true);
        const req: PostQueryReq = {
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          simple: formatSimpleQueryData(formik.values.simple, joinedTables),
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };
        jwtAxios
          .post('/api/query/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
            showSnackbar('設定を保存しました。', 'success');
            mutate();
            cache.delete(`api/queries/`);
            navigation('/queries');
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`設定の保存に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const handlePut = () => {
    formik.validateForm().then((errors) => {
      if (formik.values.uuid === undefined) {
        return;
      }
      if (Object.keys(errors).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(errors, true));
        handleScrollToFirstError(errors);
      } else {
        showProgress(true);
        const req: PutQueryReq = {
          uuid: formik.values.uuid,
          datasource: formik.values.datasource,
          queryName: formik.values.queryName,
          description: formik.values.description,
          simple: formatSimpleQueryData(formik.values.simple, joinedTables),
          schemaN: formik.values.schemaN,
          tableN: formik.values.tableN,
          enclosedText: formik.values.enclosedText,
          csvBomOption: formik.values.csvBomOption,
        };
        jwtAxios
          .put('/api/query/', keysToSnake(req))
          .then(() => {
            toggleIsLicValidity(false);
            showProgress(false);
            showSnackbar('設定を更新しました。', 'success');
            mutate();
            cache.delete(`api/queries/`);
            navigation('/queries');
          })
          .catch((error) => {
            toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
            showProgress(false);
            showSnackbar(`設定の更新に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const getConnectionTypeById = (connectionId: string): string => {
    return connections.find((item) => item.connectionId === connectionId)?.type ?? '';
  };

  const handleChangeDataSource = (event: SelectChangeEvent) => {
    const connectionId = event.target.value;
    const connectionType = getConnectionTypeById(connectionId);

    formik.setFieldValue('datasource', connectionId);

    // スキーマとテーブル名 リセット
    formik.setFieldValue('schemaN', '');
    formik.setFieldValue('tableN', '');
    // 抽出する項目の設定 リセット
    formik.setFieldValue('simple.select', []);
    // Reset Join Condition
    formik.setFieldValue('simple.join', []);
    // 抽出条件の設定 リセット
    formik.setFieldValue('simple.where', []);
    // 並び順の設定 リセット
    formik.setFieldValue('simple.orderby', []);
    setDataSourceType(connectionType);
    setFetchedTables([]);
    setJoinedTables([]);
  };

  const handleChangeEnclosedText = (event: SelectChangeEvent) => {
    const enclosedText = event.target.value;
    formik.setFieldValue('enclosedText', enclosedText);
  };

  const handleChangeCsvBomOption = (event: ChangeEvent, checked: boolean) => {
    formik.setFieldValue('csvBomOption', checked);
  };

  const onBlurQueryName = () => {
    formik.setFieldTouched('queryName');
  };

  const onBlurDescription = () => {
    formik.setFieldTouched('description');
  };

  const enableSqlButton =
    !auth.license?.isExpired &&
    formik.values.datasource &&
    formik.values.schemaN &&
    formik.values.tableN;

  useEffect(() => {
    if (
      formik.values.queryName &&
      formik.values.datasource &&
      formik.values.schemaN &&
      formik.values.tableN
    ) {
      setSubmitButton(true);
    } else {
      setSubmitButton(false);
    }
  }, [
    formik.values.queryName,
    formik.values.datasource,
    formik.values.schemaN,
    formik.values.tableN,
  ]);

  useEffect(() => {
    if (query) {
      const connectionType = getConnectionTypeById(query.datasource);
      setDataSourceType(connectionType);
    }
  }, [query, connections]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {/* タイトル */}
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography sx={{ mt: 2 }}>
              タイトル <span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              id="queryName"
              name="queryName"
              placeholder="例）東京、誕生日順"
              value={formik.values.queryName}
              onChange={formik.handleChange}
              error={formik.touched.queryName && Boolean(formik.errors.queryName)}
              helperText={formik.touched.queryName && formik.errors.queryName}
              fullWidth
              onBlur={onBlurQueryName}
            />
          </Grid>
        </Grid>

        {/* 説明 */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>説明</Typography>
            <TextField
              id="description"
              name="description"
              placeholder="例）東京在住ユーザーの誕生日昇順"
              value={formik.values.description}
              onChange={formik.handleChange}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
              fullWidth
              onBlur={onBlurDescription}
            />
          </Grid>
        </Grid>

        {/* データソース名 */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>
              データソース名（データベース名）
              {query ? null : <span style={{ color: 'red' }}>*</span>}
            </Typography>
            <FormControl fullWidth>
              {formik.values.datasource || hideSelectLabel ? null : (
                <InputLabel id="datasource-select-label">選択してください</InputLabel>
              )}
              <Select
                labelId="datasourceSelectLabel"
                id="datasource"
                value={connections.length ? formik.values.datasource : ''}
                onChange={handleChangeDataSource}
                onOpen={() => {
                  setHideSelectLabel(true);
                }}
                error={formik.touched.datasource && Boolean(formik.errors.datasource)}
                disabled={!!formik.values.uuid}
              >
                {connections.map((connection) => (
                  <MenuItem key={generateUuid()} value={connection.connectionId}>
                    {`${connection.name} (${
                      connectionIsLoading && connectionIsValidating
                        ? '取得中...'
                        : connection?.db || ''
                    })`}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error>
                {formik.touched.datasource && formik.errors.datasource}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        {/* スキーマとテーブル名 */}
        <SchemaConditionParts
          formik={formik}
          schemaRows={schemaRows}
          fetchedTables={fetchedTables}
          joinedTables={joinedTables}
          handleJoinedTables={setJoinedTables}
          isLoading={tablesIsLoading}
          isColumnLoading={isColumnLoading}
        />

        {/* 抽出する項目の設定 */}
        <SelectConditionParts
          formik={formik}
          joinedTables={joinedTables}
          isColumnLoading={isColumnLoading}
        />

        {/* 抽出条件の設定 */}
        <WhereConditionParts
          formik={formik}
          joinedTables={joinedTables}
          isColumnLoading={isColumnLoading}
        />

        {/* 並び順の設定 */}
        <OrderByConditionParts
          formik={formik}
          joinedTables={joinedTables}
          isColumnLoading={isColumnLoading}
        />

        <Box marginTop={4}>
          <Button disabled={!enableSqlButton} variant="outlined" onClick={onClickShowSqlPreview}>
            SQLを表示する
          </Button>
          <SqlPreviewDialog
            isOpen={dialogSqlOpen}
            joinedTables={joinedTables}
            formik={formik}
            onClose={() => setDialogSqlOpen(false)}
          />
        </Box>

        {/* ストリング値の引用符 */}
        {dataSourceType === 'Snowflake' ? (
          <Grid container marginTop={4} justifyContent="center">
            <Grid item xs={12}>
              <Typography>
                ストリング値の引用符 <span style={{ color: 'red' }}>*</span>
              </Typography>
              <FormControl fullWidth>
                {formik.values.enclosedText || hideEnclosedTextSelectLabel ? null : (
                  <InputLabel id="enclosed-text-select-label">選択してください</InputLabel>
                )}
                <Select
                  labelId="enclosedTextSelectLabel"
                  id="enclosedText"
                  value={connections.length ? formik.values.enclosedText : ''}
                  onChange={handleChangeEnclosedText}
                  onOpen={() => {
                    setHideEnclosedTextSelectLabel(true);
                  }}
                  error={formik.touched.enclosedText && Boolean(formik.errors.enclosedText)}
                >
                  {ENCLOSED_TEXT_OPTIONS.map((enclosedTextOption) => (
                    <MenuItem key={generateUuid()} value={enclosedTextOption.value}>
                      {enclosedTextOption.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>
                  {formik.touched.enclosedText && formik.errors.enclosedText}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}

        {/* csvオプション */}
        <Grid container marginTop={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography>出力オプション</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  name="csvBomOption"
                  checked={formik.values.csvBomOption}
                  onChange={handleChangeCsvBomOption}
                />
              }
              label="CSVファイルをMS Excelで読み込む(サイズ上限1GB)"
            />
          </Grid>
        </Grid>

        {/* プレビュー */}
        <Paper elevation={0} sx={{ padding: 2, marginY: 4, backgroundColor: '#FAFAFA' }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              {preview && !previewLoading ? (
                <Paper>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <BaseDataGrid
                      rows={previewRow}
                      columns={previewColumn}
                      getRowIdFunc={(row: GridRowModel) => `${row.rowNum + generateUuid()}`}
                    />
                  </div>
                </Paper>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            {previewLoading ? (
              <Grid item marginTop={2}>
                <CircularProgress color="inherit" size={20} />
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <Button
                variant="text"
                onClick={() => handlePreview()}
                fullWidth
                sx={{ marginTop: preview ? 2 : 0 }}
                disabled={auth.license?.isExpired || previewLoading || !submitButton}
              >
                プレビュー
              </Button>
            </Grid>
          </Grid>
        </Paper>

        <Grid container justifyContent="flex-end">
          <Box component="span" display="flex" justifyContent="space-evenly" alignItems="center">
            {formik.values.uuid === '' ? (
              <>
                <Button
                  type="submit"
                  variant="outlined"
                  startIcon={<BookmarkAddOutlined />}
                  onClick={() => handlePost()}
                  sx={{ marginRight: 2 }}
                  disabled={auth.license?.isExpired || !submitButton}
                >
                  条件保存
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => handleTempDownload()}
                  disabled={auth.license?.isExpired || !submitButton}
                >
                  データ抽出実行
                </Button>
              </>
            ) : (
              <>
                <Button sx={{ marginRight: 1 }} color="inherit" onClick={() => setDialogOpen(true)}>
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<BookmarkAddOutlined />}
                  onClick={() => handlePut()}
                  disabled={auth.license?.isExpired || !submitButton}
                >
                  条件更新
                </Button>
              </>
            )}
          </Box>
        </Grid>
      </form>
      <Progress open={connectionsIsLoading} />
      <ConfirmDialogDesregard
        isOpen={dialogOpen}
        cancelFunc={() => setDialogOpen(false)}
        okFunc={() => navigation('/queries')}
      />
    </>
  );
}
