import React from 'react';

const RightJoin: React.FC<{ className?: string; onClick?: () => void }> = ({
  className,
  onClick,
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <ellipse cx="16" cy="24" rx="14" ry="14" transform="rotate(-180 16 24)" fill="white" />
      <path
        d="M32 10C35.9 10 39.2083 11.3583 41.925 14.075C44.6417 16.7917 46 20.1 46 24C46 27.9 44.6417 31.2083 41.925 33.925C39.2083 36.6417 35.9 38 32 38C31.1 38 30.225 37.9167 29.375 37.75C28.525 37.5833 27.7 37.35 26.9 37.05C28.8333 35.4167 30.3333 33.4667 31.4 31.2C32.4667 28.9333 33 26.5333 33 24C33 21.4667 32.4667 19.0667 31.4 16.8C30.3333 14.5333 28.8333 12.5833 26.9 10.95C27.7 10.65 28.525 10.4167 29.375 10.25C30.225 10.0833 31.1 10 32 10ZM16 10C16.9 10 17.775 10.0833 18.625 10.25C19.475 10.4167 20.3 10.65 21.1 10.95C20.5333 11.4167 20 11.925 19.5 12.475C19 13.025 18.5333 13.6 18.1 14.2C17.7333 14.1333 17.3833 14.0833 17.05 14.05C16.7167 14.0167 16.3667 14 16 14C13.2333 14 10.875 14.975 8.925 16.925C6.975 18.875 6 21.2333 6 24C6 26.7667 6.975 29.125 8.925 31.075C10.875 33.025 13.2333 34 16 34C16.3667 34 16.7167 33.9833 17.05 33.95C17.3833 33.9167 17.7333 33.8667 18.1 33.8C18.5333 34.4 19 34.975 19.5 35.525C20 36.075 20.5333 36.5833 21.1 37.05C20.3 37.35 19.475 37.5833 18.625 37.75C17.775 37.9167 16.9 38 16 38C12.1 38 8.79167 36.6417 6.075 33.925C3.35833 31.2083 2 27.9 2 24C2 20.1 3.35834 16.7917 6.075 14.075C8.79167 11.3583 12.1 10 16 10ZM24 12.5C25.9 13.8 27.375 15.4667 28.425 17.5C29.475 19.5333 30 21.7 30 24C30 26.3 29.475 28.4667 28.425 30.5C27.375 32.5333 25.9 34.2 24 35.5C22.1 34.2 20.625 32.5333 19.575 30.5C18.525 28.4667 18 26.3 18 24C18 21.7 18.525 19.5333 19.575 17.5C20.625 15.4667 22.1 13.8 24 12.5Z"
        fill="#5F6368"
      />
      <circle cx="32" cy="24" r="14" transform="rotate(-180 32 24)" fill="white" />
      <path
        d="M32 10C35.9 10 39.2083 11.3583 41.925 14.075C44.6417 16.7917 46 20.1 46 24C46 27.9 44.6417 31.2083 41.925 33.925C39.2083 36.6417 35.9 38 32 38C31.1 38 30.225 37.9167 29.375 37.75C28.525 37.5833 27.7 37.35 26.9 37.05C28.8333 35.4167 30.3333 33.4667 31.4 31.2C32.4667 28.9333 33 26.5333 33 24C33 21.4667 32.4667 19.0667 31.4 16.8C30.3333 14.5333 28.8333 12.5833 26.9 10.95C27.7 10.65 28.525 10.4167 29.375 10.25C30.225 10.0833 31.1 10 32 10ZM24 12.5C25.9 13.8 27.375 15.4667 28.425 17.5C29.475 19.5333 30 21.7 30 24C30 26.3 29.475 28.4667 28.425 30.5C27.375 32.5333 25.9 34.2 24 35.5C22.1 34.2 20.625 32.5333 19.575 30.5C18.525 28.4667 18 26.3 18 24C18 21.7 18.525 19.5333 19.575 17.5C20.625 15.4667 22.1 13.8 24 12.5Z"
        fill="#1976D2"
      />
    </svg>
  );
};

export default RightJoin;
