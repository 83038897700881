import * as yup from 'yup';
import { DataSource } from '../../types/dataSource';

const baseValidationSchema = yup.object({
  name: yup.string().required('データソース名は必須です。'),
  user: yup.string().required('ユーザー名は必須です。'),
  passwd: yup.string().required('パスワードは必須です。'),
  db: yup.string().required('データベース名は必須です。'),
});

export const generateValidateSchemaByConnectionType = (
  connectionType: DataSource,
  isEdit: boolean
) => {
  if (connectionType === DataSource.SnowFlake) {
    return baseValidationSchema.shape({
      account: yup.string().required(),
      role: yup.string().required(),
      warehouse: yup.string().required(),
      schema: yup.string().required(),
      passwd: isEdit ? yup.string() : yup.string().required('パスワードは必須です。'),
    });
  }

  return baseValidationSchema.shape({
    host: yup.string().required('ホストは必須です。'),
    port: yup.number().min(0).max(65535).required('ポート番号は必須です。'),
    redshiftIamRole: yup.string().required('Redshift IAM Roleは必須です。'),
    passwd: isEdit ? yup.string() : yup.string().required('パスワードは必須です。'),
  });
};
