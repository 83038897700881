import React from 'react';

const InnerJoinError: React.FC<{ className?: string; onClick?: () => void }> = ({
  className,
  onClick,
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 35.4906C21.7323 37.0724 18.9745 38 16 38C8.26801 38 2 31.732 2 24C2 16.268 8.26801 10 16 10C18.9745 10 21.7323 10.9276 24 12.5094C26.2677 10.9276 29.0255 10 32 10C39.732 10 46 16.268 46 24C46 31.732 39.732 38 32 38C29.0255 38 26.2677 37.0724 24 35.4906Z"
        fill="white"
      />
      <path
        d="M16 38C12.1 38 8.79167 36.6417 6.075 33.925C3.35833 31.2083 2 27.9 2 24C2 20.1 3.35833 16.7917 6.075 14.075C8.79167 11.3583 12.1 10 16 10C16.9 10 17.775 10.0833 18.625 10.25C19.475 10.4167 20.3 10.65 21.1 10.95C20.5333 11.4167 20 11.925 19.5 12.475C19 13.025 18.5333 13.6 18.1 14.2C17.7667 14.1333 17.425 14.0833 17.075 14.05C16.725 14.0167 16.3667 14 16 14C13.2333 14 10.875 14.975 8.925 16.925C6.975 18.875 6 21.2333 6 24C6 26.7667 6.975 29.125 8.925 31.075C10.875 33.025 13.2333 34 16 34C16.3667 34 16.725 33.9833 17.075 33.95C17.425 33.9167 17.7667 33.8667 18.1 33.8C18.5333 34.4 19 34.975 19.5 35.525C20 36.075 20.5333 36.5833 21.1 37.05C20.3 37.35 19.475 37.5833 18.625 37.75C17.775 37.9167 16.9 38 16 38ZM32 38C31.1 38 30.225 37.9167 29.375 37.75C28.525 37.5833 27.7 37.35 26.9 37.05C27.4667 36.5833 28 36.075 28.5 35.525C29 34.975 29.4667 34.4 29.9 33.8C30.2667 33.8667 30.6167 33.9167 30.95 33.95C31.2833 33.9833 31.6333 34 32 34C34.7667 34 37.125 33.025 39.075 31.075C41.025 29.125 42 26.7667 42 24C42 21.2333 41.025 18.875 39.075 16.925C37.125 14.975 34.7667 14 32 14C31.6333 14 31.2833 14.0167 30.95 14.05C30.6167 14.0833 30.2667 14.1333 29.9 14.2C29.4667 13.6 29 13.025 28.5 12.475C28 11.925 27.4667 11.4167 26.9 10.95C27.7 10.65 28.525 10.4167 29.375 10.25C30.225 10.0833 31.1 10 32 10C35.9 10 39.2083 11.3583 41.925 14.075C44.6417 16.7917 46 20.1 46 24C46 27.9 44.6417 31.2083 41.925 33.925C39.2083 36.6417 35.9 38 32 38ZM24 35.5C22.1 34.2 20.625 32.5333 19.575 30.5C18.525 28.4667 18 26.3 18 24C18 21.7 18.525 19.5333 19.575 17.5C20.625 15.4667 22.1 13.8 24 12.5C25.9 13.8 27.375 15.4667 28.425 17.5C29.475 19.5333 30 21.7 30 24C30 26.3 29.475 28.4667 28.425 30.5C27.375 32.5333 25.9 34.2 24 35.5Z"
        fill="red"
      />
      <path
        d="M24 35.5C22.1 34.2 20.625 32.5333 19.575 30.5C18.525 28.4667 18 26.3 18 24C18 21.7 18.525 19.5333 19.575 17.5C20.625 15.4667 22.1 13.8 24 12.5C25.9 13.8 27.375 15.4667 28.425 17.5C29.475 19.5333 30 21.7 30 24C30 26.3 29.475 28.4667 28.425 30.5C27.375 32.5333 25.9 34.2 24 35.5Z"
        fill="red"
      />
    </svg>
  );
};

export default InnerJoinError;
