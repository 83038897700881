import { BaseContent } from '../components/BaseContent';
import CreateGroupForm from '../components/layoutparts/CreateGroupForm';
import EditGroupForm from '../components/layoutparts/EditGroupForm';
import { useParams } from 'react-router-dom';

export default function GroupPage() {
  const { firstId: firstId } = useParams();

  return (
    <>
      {firstId === undefined ? (
        <BaseContent title="グループ作成">
          <CreateGroupForm group={undefined} />
        </BaseContent>
      ) : (
        <BaseContent title="グループ編集">
          <EditGroupForm firstId={firstId} />
        </BaseContent>
      )}
    </>
  );
}
