import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useAuth } from '../hooks/use-auth';
import { useSnackbar } from '../hooks/SnackBar';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useProgress } from '../hooks/useProgress';

export default function ForgotPasswordConfirm() {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();

  const changePassword = async () => {
    if (formik.values.newPassword != formik.values.newPasswordConfirm) {
      showSnackbar('パスワードが一致していません。', 'error');
      return;
    }

    showProgress(true);

    const result = await auth.changePassword(formik.values.oldPassword, formik.values.newPassword);
    if (result.success) {
      showProgress(false);
      showSnackbar(result.message, 'success');
      formik.resetForm();
    } else {
      showProgress(false);
      showSnackbar(result.message, 'error');
    }
  };

  const initialValues = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
  };

  const newPasswordYup = yup
    .string()
    .required('必須項目です。')
    .min(8, '8文字以上入力してください。')
    .matches(/(?=.*[a-z])/, '小文字を含めてください。')
    .matches(/(?=.*[A-Z])/, '大文字を含めてください。')
    .matches(/(?=.*[0-9])/, '数字を含めてください。')
    .matches(/(?=.*[ -/:-@[-`{-~])/, '記号を含めてください。');

  const validationSchema = yup.object({
    oldPassword: yup.string().required('必須項目です。'),
    newPassword: newPasswordYup,
    newPasswordConfirm: newPasswordYup,
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      changePassword();
    },
  });

  return (
    <Box component="form" noValidate onSubmit={formik.handleSubmit}>
      <Stack
        direction={'row'}
        display="flex"
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'relative',
          bottom: '50px',
        }}
      >
        <Button component="button" type="submit" variant="contained">
          パスワードを変更する
        </Button>
      </Stack>

      <Stack spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Box width={700}>
          <Typography>
            現在のパスワード <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            required
            fullWidth
            type="password"
            autoComplete="current-password"
            id="oldPassword"
            name="oldPassword"
            placeholder="現在のパスワードを入力"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          />
        </Box>

        <Box width={700}>
          <Typography>
            新しいパスワード <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            required
            fullWidth
            type="password"
            autoComplete="new-password"
            id="newPassword"
            name="newPassword"
            placeholder="新しいパスワードを入力"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
          />
        </Box>

        <Box width={700}>
          <Typography>
            新しいパスワード（確認用） <span style={{ color: 'red' }}>*</span>
          </Typography>
          <TextField
            required
            fullWidth
            type="password"
            autoComplete="new-password"
            id="newPasswordConfirm"
            name="newPasswordConfirm"
            placeholder="新しいパスワードを入力"
            value={formik.values.newPasswordConfirm}
            onChange={formik.handleChange}
            error={formik.touched.newPasswordConfirm && Boolean(formik.errors.newPasswordConfirm)}
            helperText={formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm}
          />
        </Box>
      </Stack>
    </Box>
  );
}
