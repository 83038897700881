import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

import React from 'react';

type Props = {
  isOpen: boolean;
  cancelFunc: () => void;
  okFunc: () => void;
};
const ConfirmDialogDesregard: React.FC<Props> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.cancelFunc}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          変更した内容は破棄しますか？
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 2 }} onClick={props.cancelFunc} color="inherit">
          キャンセル
        </Button>
        <Button onClick={props.okFunc} color="error" autoFocus>
          破棄
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogDesregard;
