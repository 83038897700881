import { Amplify } from 'aws-amplify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import jwtAxios, { setAuthToken } from '../common/axios';
import {
  fetchAuthSession,
  signUp as signUpAws,
  signIn as signInAws,
  confirmSignUp as confirmSignUpAws,
  signOut as signOutAws,
  fetchUserAttributes,
  resetPassword,
  confirmResetPassword,
  updatePassword,
  SignInOutput,
  confirmSignIn,
} from 'aws-amplify/auth';
import AwsConfigAuth from '../aws-config/Auth';
import { keysToCamel, keysToSnake } from '../common/func/converter';
import { FrontendUserRole, License, User } from '../common/types';
import { MFAConfigResp } from '../common/types/Responses';

Amplify.configure({
  Auth: {
    Cognito: AwsConfigAuth,
  },
});

interface LeaderGroupList {
  groupId: string;
  role: string;
}

interface UseAuth {
  isLoading: boolean;
  isAuthenticated: boolean;
  role: string;
  superadmin: boolean;
  userId: string;
  userName: string;
  email: string;
  leaderGroupList: LeaderGroupList[];
  frontendUserRole: FrontendUserRole;
  license: License | undefined;
  isSettingMfa: boolean;
  signUp: (username: string, password: string) => Promise<Result>;
  confirmSignUp: (verificationCode: string) => Promise<Result>;
  signIn: (username: string, password: string) => Promise<Result>;
  signInMfa: (mfaCode: string, isSetup?: boolean) => Promise<Result>;
  setupMfa: (secret: string, mfaCode: string, isSetup?: boolean) => Promise<Result>;
  signOut: () => void;
  getSystemMfa: () => Promise<boolean>;
  forgotPassword: (username: string) => Promise<Result>;
  forgotPasswordConfirm: (username: string, code: string, password: string) => Promise<Result>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<Result>;
  setLicenseDataRevalidateOnMount: (license: License) => void;
  fetchUser: () => Promise<void>;
}

interface Result {
  success: boolean;
  message: string;
  detail?: SignInOutput;
}

const authContext = createContext({} as UseAuth);
type Props = {
  children?: React.ReactNode;
};

export const ProvideAuth: React.FC<Props> = ({ children }) => {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  return useContext(authContext);
};

const useProvideAuth = (): UseAuth => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState('');
  const [superadmin, setSuperadmin] = useState(false);
  const [userId, setUserId] = useState('');
  const [userName, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [leaderGroupList, setLeaderGroupList] = useState<LeaderGroupList[]>([]);
  const [password, setPassword] = useState('');
  const [frontendUserRole, setFrontendUserRole] = useState<FrontendUserRole>('pending');
  const [license, setLicense] = useState<License | undefined>();
  const [isSettingMfa, setIsSettingMfa] = useState(false);
  const USER_MFA_CONFIGURED = '1';

  useEffect(() => {
    const fetcher = async () => {
      try {
        const { tokens: session } = await fetchAuthSession();
        const attributes = await fetchUserAttributes();
        const firstId = attributes.sub ?? '';
        if (!firstId) {
          throw new Error('NotAuthorizedException');
        }
        setUserId(firstId);
        setUsername(attributes.name ?? '');
        setEmail(attributes.email ?? '');
        setIsAuthenticated(true);
        setIsLoading(false);
        setAuthToken(session?.idToken?.toString() ?? '');
        await setUserRole(firstId);
        setLicenseData();
      } catch (error: any) {
        if (error?.name === 'NotAuthorizedException') {
          localStorage.clear();
        }
        setRole('');
        setSuperadmin(false);
        setUserId('');
        setUsername('');
        setEmail('');
        setLeaderGroupList([]);
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };
    fetcher();
  }, []);

  const isWithin30DaysExpiration = (expirationDate: string) => {
    const thirtyDaysInMillis = 30 * 24 * 60 * 60 * 1000; // 30日分のミリ秒
    const expirationTimestamp = Date.parse(expirationDate);
    const currentTimestamp = Date.now();
    return expirationTimestamp - currentTimestamp < thirtyDaysInMillis;
  };

  const isLicenseExpired = (expirationDate: string) => {
    // 現在の日付を取得
    const today = new Date();
    // 1日前の日付を計算
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    // 1日前の最終時刻（23:59:59）をセット
    const expiredDateTime = yesterday.setHours(23, 59, 59, 999);

    return Date.parse(expirationDate) <= expiredDateTime;
  };

  const setLicenseDataRevalidateOnMount = (license: License) => {
    if (license.licenseKey) {
      license.isApproaching = isWithin30DaysExpiration(license.expireDate);
      license.isExpired = isLicenseExpired(license.expireDate);
    }
    setLicense(license);
  };

  const setLicenseData = async () => {
    try {
      await jwtAxios
        .get('/api/license/')
        .then(function (response) {
          const data = keysToCamel(response.data) as License;
          if (data.licenseKey) {
            data.isApproaching = isWithin30DaysExpiration(data.expireDate);
            data.isExpired = isLicenseExpired(data.expireDate);
          }
          setLicense(data);
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const setUserRole = async (firstId: string) => {
    try {
      const param = {
        firstId,
      };
      const systemMfaEnabled = await getSystemMfa();
      const userMfaEnabled = await jwtAxios
        .get('/api/user/', { params: keysToSnake(param) })
        .then(function (response) {
          const data = keysToCamel(response.data) as User;
          setRole(data.adminRole === 'superadmin' ? 'admin' : data.adminRole);
          setSuperadmin(data.adminRole === 'superadmin');
          setLeaderGroupList(data.group);
          const isSettingMfa = data.mfaStatus === USER_MFA_CONFIGURED;
          setIsSettingMfa(isSettingMfa);
          convertRoleToFrontendUserRole(data);
          return isSettingMfa;
        })
        .catch(function (error) {
          setRole('');
          setSuperadmin(false);
          setLeaderGroupList([]);
          setIsSettingMfa(false);
          throw Error(error);
        });

      if (systemMfaEnabled && !userMfaEnabled) {
        signOut();
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSystemMfa = async () => {
    // System MFA priority: sessionStorage > API
    let systemMfaEnabled = false;
    try {
      const response = await jwtAxios.get(`/api/mfa/system_config/`);
      const data = keysToCamel(response.data) as MFAConfigResp;
      systemMfaEnabled = data?.mfaConfiguration === 'OPTIONAL';
    } catch (error) {
      // 何もしない
    }
    return systemMfaEnabled;
  };

  // フロントエンドで取り扱うユーザー権限に変換
  const convertRoleToFrontendUserRole = async (user: User) => {
    if (user.adminRole === 'superadmin') {
      setFrontendUserRole(FrontendUserRole.SuperAdmin);
    } else if (user.adminRole === 'admin') {
      setFrontendUserRole(FrontendUserRole.Admin);
    } else if (user.adminRole === 'notAdmin') {
      if (user.group.length) {
        const leaders = user.group.filter((g) => g.role === 'leader');
        if (leaders.length) {
          setFrontendUserRole(FrontendUserRole.Edit);
        } else {
          setFrontendUserRole(FrontendUserRole.Read);
        }
      } else {
        setFrontendUserRole(FrontendUserRole.NotAffiliation);
      }
    }
  };

  const signUp = async (username: string, password: string) => {
    try {
      await signUpAws({ username, password });
      setUsername(username);
      setPassword(password);
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const confirmSignUp = async (verificationCode: string) => {
    try {
      await confirmSignUpAws({
        username: email,
        confirmationCode: verificationCode,
      });
      const result = await signIn(email, password);
      setPassword('');
      return result;
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const resultSignIn = await signInAws({
        username: email,
        password,
        options: {
          authFlowType: 'USER_SRP_AUTH',
        },
      });
      return {
        success: resultSignIn.isSignedIn,
        message: '',
        detail: resultSignIn,
      };
    } catch (error: any) {
      console.error(error);
      let err_message = 'メールアドレスまたはパスワードが間違っています。';
      if (error?.name === 'UserAlreadyAuthenticatedException') {
        err_message = 'すでにサインインしているユーザーがいます。';
      }
      return {
        success: false,
        message: err_message,
      };
    }
  };

  const fetchUser = async () => {
    const { tokens: session } = await fetchAuthSession();
    const attributes = await fetchUserAttributes();

    setUserId(attributes.sub ?? '');
    setUsername(attributes.name ?? '');
    setEmail(attributes.email ?? '');
    setIsLoading(false);
    setAuthToken(session?.idToken?.toString());
    await setUserRole(attributes.sub ?? '');
  };

  const signInMfa = async (mfaCode: string) => {
    try {
      const result = await confirmSignIn({
        challengeResponse: mfaCode,
      });

      if (result.isSignedIn) {
        await fetchUser();
      }
      return {
        success: true,
        message: '',
      };
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const setupMfa = async (secret: string, mfaCode: string) => {
    try {
      const { tokens: session } = await fetchAuthSession();
      await jwtAxios.post(
        `/api/mfa/user/`,
        keysToSnake({
          accessToken: session?.accessToken.toString(),
          secret: secret,
          value: mfaCode,
        })
      );

      await fetchUser();
      return {
        success: true,
        message: '',
      };
    } catch (error) {
      return {
        success: false,
        message: '認証に失敗しました。',
      };
    }
  };

  const signOut = async () => {
    try {
      await signOutAws();
      setRole('');
      setSuperadmin(false);
      setUserId('');
      setUsername('');
      setLeaderGroupList([]);
      setPassword('');
      setEmail('');
      setIsAuthenticated(false);
      window.location.reload();
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: 'ログアウトに失敗しました。',
      };
    }
  };

  const forgotPassword = async (username: string) => {
    try {
      await resetPassword({ username });
      return { success: true, message: '' };
    } catch (error) {
      return {
        success: false,
        message: '確認コードの送信に失敗しました。',
      };
    }
  };

  const forgotPasswordConfirm = async (username: string, code: string, password: string) => {
    try {
      await confirmResetPassword({
        username,
        confirmationCode: code,
        newPassword: password,
      });
      return {
        success: true,
        message: 'パスワードの再設定が完了しました。',
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: 'パスワードの再設定に失敗しました。',
      };
    }
  };

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      updatePassword({ oldPassword, newPassword });
      return {
        success: true,
        message: 'パスワードの変更が完了しました。',
      };
    } catch (error) {
      console.error(error);
      return {
        success: false,
        message: 'パスワードの変更に失敗しました。',
      };
    }
  };

  return {
    isLoading,
    isAuthenticated,
    role,
    superadmin,
    userId: userId,
    userName: userName,
    email,
    leaderGroupList,
    frontendUserRole,
    license,
    isSettingMfa,
    signUp,
    confirmSignUp,
    signIn,
    signInMfa,
    setupMfa,
    signOut,
    getSystemMfa,
    forgotPassword,
    forgotPasswordConfirm,
    changePassword,
    setLicenseDataRevalidateOnMount,
    fetchUser,
  };
};
