import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from '../hooks/use-auth';
import { NavLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from '../hooks/SnackBar';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useProgress } from '../hooks/useProgress';

const theme = createTheme({
  palette: {
    background: {
      default: '#E4E4E4',
    },
  },
});

export default function SignUp() {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();
  const navigate = useNavigate();

  const executeForgotPassword = async () => {
    showProgress(true);
    const result = await auth.forgotPassword(formik.values.email);
    if (result.success) {
      showProgress(false);
      navigate(`/forgotpassword-confirm/${formik.values.email}`);
    } else {
      showProgress(false);
      showSnackbar('検証用コードの送信に失敗しました', 'error');
    }
  };

  const initialValues = {
    email: '',
  };

  const validationSchema = yup.object({
    email: yup.string().email('メールアドレスを入力してください。').required('必須項目です。'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: () => {
      executeForgotPassword();
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item xs={12}>
            <CssBaseline />
            <Box
              sx={{
                width: 500,
                marginTop: 14,
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#FFFFFF',
                padding: 4,
              }}
            >
              <Typography component="h1" variant="h4">
                パスワードの再設定
              </Typography>
              <Typography component="h1" variant="body1">
                ご登録のメールアドレスをご入力ください。検証コードをお送りします。
              </Typography>
              <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      メールアドレス <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      type="email"
                      placeholder="メールアドレスを入力"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} alignItems="end" direction="column">
                  <Grid item xs={12}>
                    <NavLink to={'/signin'}>
                      <Typography variant="body2">サインイン画面へ戻る</Typography>
                    </NavLink>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={Object.keys(formik.errors).length > 0}
                >
                  送信
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
