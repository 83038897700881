import { Group } from '../types';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWRImmutable from 'swr/immutable';
import { useSnackbar } from '../../hooks/SnackBar';

const config: SWRConfiguration = {};

export const useGroupSwr = (firstId: string | undefined, revalidateOnMount = false) => {
  const { toggleIsLicValidity } = useSnackbar();
  config.revalidateOnMount = revalidateOnMount;
  const { data, error, isValidating } = useSWRImmutable<Group>(
    firstId ? `api/group/?first_id=${firstId}` : undefined,
    fetcherKeysToCamel,
    config
  );

  if (error) {
    toggleIsLicValidity(true, error.response.status, error.response.data['licmessage']);
  } else {
    toggleIsLicValidity(false);
  }

  return {
    group: data,
    isLoading: !error && !data,
    isError: error,
    isValidating: isValidating,
  };
};
