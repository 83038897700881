import { EditOutlined, DeleteOutlined, MoreHoriz, LockReset } from '@mui/icons-material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import ConfirmDialogDelete from './ConfirmDialogDelete';
import ConfirmDialog from './ConfirmDialog';
import {
  Box,
  Button,
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { MinimalGroup, User } from '../../common/types';
import { renderCellDate } from '../../common/func/functions';
import { useUsersSwr } from '../../common/swr/useUsersSwr';
import jwtAxios from '../../common/axios';
import { useSnackbar } from '../../hooks/SnackBar';
import { BaseDataGrid } from '../BaseDataGrid';
import { useProgress } from '../../hooks/useProgress';
import { useNavigate } from 'react-router';
import { useSWRConfig } from 'swr';
import { MAX_ARRAY_SIZE } from '../../common/const/maxArraySize';
import { useAuth } from '../../hooks/use-auth';
import { Link } from 'react-router-dom';
import { useMfaConfigSwr } from '../../common/swr/useMfaConfigSwr';

const BaseMenu = ({ children }: { children: React.ReactNode }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu-1"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </Menu>
    </>
  );
};
export default function UsersTable() {
  const { mfaConfig } = useMfaConfigSwr();
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();
  const navigation = useNavigate();
  const USER_MFA_CONFIGURED = '1';

  const auth = useAuth();
  const notAdmin = !(auth.role === 'admin' || auth.role === 'superadmin');

  // APIs
  const { users, isLoading, isError, mutate } = useUsersSwr();

  const { cache } = useSWRConfig();

  enum MFA_STATUS {
    OFF,
    SETUP,
    NOT_SETUP,
  }
  const mfaStatus: Record<MFA_STATUS, string> = {
    [MFA_STATUS.OFF]: '-',
    [MFA_STATUS.SETUP]: '設定済み',
    [MFA_STATUS.NOT_SETUP]: '未設定',
  };

  // swr error
  useEffect(() => {
    if (isError)
      showSnackbar(`ユーザー一覧取一覧取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  const [selectedUserName, setSelectedUserName] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');

  const [dialogDeleteOpen, setDialogDeleteOpen] = useState(false);
  const handleDelete = () => {
    setDialogDeleteOpen(false);
    showProgress(true);
    jwtAxios
      .delete(`/api/user/?first_id=${selectedUserId}`)
      .then(function () {
        showProgress(false);
        showSnackbar('ユーザーを削除しました。', 'success');
        if (users.length < MAX_ARRAY_SIZE) {
          const updatedUser = users.filter((user) => {
            return user.firstId !== selectedUserId;
          });
          mutate(updatedUser, true);
        } else {
          cache.delete(`api/users/`);
        }
        cache.delete(`api/groups/`);
        // 再検証
        void mutate();
      })
      .catch(function (error) {
        showProgress(false);
        showSnackbar(`ユーザーの削除に失敗しました。 (${error.response.data['detail']})`, 'error');
      });
  };

  const [dialogResetMfaOpen, setDialogResetMfaOpen] = useState(false);
  const handleResetMfa = () => {
    showProgress(true);
    setDialogResetMfaOpen(false);
    jwtAxios
      .post(`/api/mfa/adm_reset/${selectedUserId}`)
      .then(() => {
        showSnackbar('MFAリセットが完了しました。', 'success');
        cache.delete(`api/users/`);
        cache.delete(`api/groups/`);
        if (selectedUserId === auth.userId) {
          auth.fetchUser();
        }
        // 再検証
        void mutate();
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((error: any) => {
        showSnackbar(`MFAリセットできません。 (${error.response.data['detail']})`, 'error');
      })
      .finally(() => {
        showProgress(false);
      });
  };

  const getUserMfaStatus = (user: User): MFA_STATUS => {
    if (mfaConfig.mfaConfiguration === 'OFF') {
      return MFA_STATUS.OFF;
    }

    if (user.mfaStatus === USER_MFA_CONFIGURED) {
      return MFA_STATUS.SETUP;
    }

    return MFA_STATUS.NOT_SETUP;
  };

  const columns: GridColDef[] = [
    { field: 'userName', headerName: 'ユーザー', flex: 1.2 },
    { field: 'email', headerName: 'メールアドレス', flex: 1.5 },
    {
      field: 'adminRole',
      headerName: 'ユーザー種別',
      flex: 1,
      valueGetter(params) {
        let roleName = '';
        if (params.row.adminRole === 'admin') {
          roleName = '管理者ユーザー';
        } else if (params.row.adminRole === 'superadmin') {
          roleName = 'システム管理者';
        } else {
          const leaders = params.row.group.filter((group: MinimalGroup) => group.role === 'leader');
          const generals = params.row.group.filter(
            (group: MinimalGroup) => group.role === 'general'
          );
          roleName = `一般ユーザー (${leaders.length}/${generals.length})`;
        }
        return roleName;
      },
      renderCell: (params: GridRenderCellParams<User>) => (
        <Box>
          <Tooltip
            title={
              params.value === '管理者ユーザー' || params.value === 'システム管理者'
                ? ''
                : '一般ユーザー (編集権限の数/閲覧権限の数)'
            }
            placement="top"
          >
            <span>{params.value}</span>
          </Tooltip>
        </Box>
      ),
    },
    {
      field: 'mfaStatus',
      headerName: 'MFA',
      flex: 0.5,
      valueGetter(params) {
        return mfaStatus[getUserMfaStatus(params.row)];
      },
    },
    {
      field: 'createdAt',
      headerName: '作成日',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<User>) => {
        return renderCellDate(params, 'createdAt');
      },
    },
    {
      field: 'updatedAt',
      headerName: '更新日',
      minWidth: 100,
      renderCell: (params: GridRenderCellParams<User>) => {
        return renderCellDate(params, 'updatedAt');
      },
    },
    {
      field: 'firstId',
      headerName: '',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 0.8,
      align: 'right',
      renderCell: (params: GridRenderCellParams<User>) => (
        <Box>
          <Tooltip title={notAdmin ? '編集権限がありません' : '編集'} placement="top">
            <span>
              <IconButton
                aria-label="expand row"
                size="small"
                sx={{ marginRight: 2 }}
                onClick={() => {
                  navigation(`/user/new/${params.value}`);
                }}
                disabled={auth.license?.isExpired ? true : notAdmin}
              >
                <EditOutlined />
              </IconButton>
            </span>
          </Tooltip>
          <BaseMenu>
            <ListItemButton
              onClick={() => {
                setSelectedUserName(params.row.userName);
                setSelectedUserId(params.row.firstId);
                setDialogResetMfaOpen(true);
              }}
              disabled={
                !['superadmin', 'admin'].includes(auth.role) ||
                getUserMfaStatus(params.row) !== MFA_STATUS.SETUP
              }
            >
              <ListItemIcon>{<LockReset />}</ListItemIcon>
              <ListItemText primary={'MFAをリセット'} />
            </ListItemButton>

            <ListItemButton
              onClick={() => {
                setSelectedUserName(params.row.userName);
                setSelectedUserId(params.row.firstId);
                setDialogDeleteOpen(true);
              }}
              disabled={
                params.row.adminRole === 'superadmin' ||
                params.row.firstId === auth.userId ||
                notAdmin
              }
            >
              <ListItemIcon>{<DeleteOutlined />}</ListItemIcon>
              <ListItemText
                primary={
                  notAdmin
                    ? '削除権限がありません'
                    : params.row.adminRole === 'superadmin'
                      ? 'システム管理者は削除できません'
                      : '削除'
                }
              />
            </ListItemButton>
          </BaseMenu>
        </Box>
      ),
    },
  ];

  return (
    <>
      {!isLoading && (auth.role === 'admin' || auth.role === 'superadmin') ? (
        <Grid container justifyContent="flex-end">
          <Button
            sx={{ position: 'relative', bottom: '50px' }}
            component={Link}
            to={`/user/new/`}
            variant="contained"
            color="primary"
            disabled={
              auth.license?.isExpired
                ? true
                : auth.license?.userNum
                  ? auth.license.userNum <= users.length
                  : true
            }
          >
            新規ユーザー作成
          </Button>
        </Grid>
      ) : null}

      <div style={{ width: '100%' }}>
        <BaseDataGrid
          loading={isLoading}
          rows={users ? users : []}
          columns={columns}
          getRowIdFunc={(row: User) => row.firstId}
          initialState={{
            sorting: {
              sortModel: [{ field: 'updatedAt', sort: 'desc' }],
            },
            columns: {
              columnVisibilityModel: {
                updatedAt: false,
              },
            },
          }}
        />
      </div>
      <ConfirmDialogDelete
        isOpen={dialogDeleteOpen}
        tile={selectedUserName}
        cancelFunc={() => setDialogDeleteOpen(false)}
        okFunc={handleDelete}
      />

      <ConfirmDialog
        isOpen={dialogResetMfaOpen}
        tile={`"${selectedUserName}"のMFA設定をリセットしますか？`}
        cancelFunc={() => setDialogResetMfaOpen(false)}
        okNode={
          <Button onClick={handleResetMfa} variant="contained" autoFocus>
            リセット
          </Button>
        }
      />
    </>
  );
}
