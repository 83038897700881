import { JobsResp } from '../../common/types/Responses';
import type { SWRConfiguration } from 'swr';
import { fetcherKeysToCamel } from './fetcher';
import useSWR from 'swr';

const config: SWRConfiguration = {};

export const useJobsSwr = () => {
  const { data, error } = useSWR<JobsResp>(`api/jobs/`, fetcherKeysToCamel, config);
  const jobs = data ? data : [];
  return {
    jobs: jobs,
    isLoading: !error && !data,
    isError: error,
  };
};
