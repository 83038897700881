import { useState, ChangeEvent, useMemo } from 'react';

import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Tooltip,
  IconButton,
} from '@mui/material';
import { ConnectionReq, GetConnectionResp } from '../../common/types/Responses';

import jwtAxios from '../../common/axios';
import { FormikProps, FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../hooks/SnackBar';

import ConfirmDialogDesregard from './ConfirmDialogDesregard';
import { useProgress } from '../../hooks/useProgress';
import { DataSourceOption, DataSource } from '../../common/types/dataSource';
import {
  extractConnectionReqFromFormik,
  extractConnectionReqFromResp,
  generateValidateSchemaByConnectionType,
} from '../../common/func/dataSourceFuncs';
import { DATA_SOURCE_NAME, DATA_SOURCE_OPTIONS } from '../../common/const/dataSource';
import { keysToSnake } from '../../common/func/converter';
import { useSWRConfig } from 'swr';
import { useAuth } from '../../hooks/use-auth';
import { EditOutlined } from '@mui/icons-material';
import { generateUuid } from '../../modules/common';

type Props = {
  connection?: GetConnectionResp;
  connectionType?: DataSource;
};

export default function CreateDataSourceSettingForm({ connection, connectionType }: Props) {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();
  const auth = useAuth();

  const { mutate, cache } = useSWRConfig();

  const currentConnectionType = connectionType
    ? connectionType
    : auth.license?.datasourceType[0] ?? DataSource.RedShift;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<DataSourceOption>({
    value: currentConnectionType,
    label: DATA_SOURCE_NAME[currentConnectionType],
  });
  const [isPasswordEdit, setIsPasswordEdit] = useState<boolean>(false);

  const selectedConnectionType = selectedItem.value;
  const isRedshift = selectedConnectionType === DataSource.RedShift;
  const isSnowflake = selectedConnectionType === DataSource.SnowFlake;
  const updateDataSourceUrl = useMemo(
    () => `/api/${selectedItem.value.toLowerCase()}/connection/`,
    [selectedItem]
  );
  const formik: FormikProps<ConnectionReq> = useFormik<ConnectionReq>({
    initialValues: extractConnectionReqFromResp(connection),
    validationSchema: generateValidateSchemaByConnectionType(
      selectedConnectionType,
      connection?.connectionId ? true : false
    ),
    onSubmit: (values) => {
      values;
    },
  });

  const handleTypeInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value as DataSource;
    setSelectedItem({ value: selectedValue, label: DATA_SOURCE_NAME[selectedValue] });
  };

  const handleConnectionTest = () => {
    formik.validateForm().then((result) => {
      if (Object.keys(result).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(result, true));
      } else {
        showProgress(true);
        const req: ConnectionReq = extractConnectionReqFromFormik(
          selectedConnectionType,
          formik.values
        );

        if (connection?.connectionId) {
          // 編集時
          req.connectionId = connection.connectionId;
          jwtAxios
            .put(
              `/api/${selectedConnectionType.toLocaleLowerCase()}/connectiontest/`,
              keysToSnake(req)
            )
            .then(() => {
              showProgress(false);
              showSnackbar('接続のテストに成功しました。', 'success');
            })
            .catch((error) => {
              showProgress(false);
              showSnackbar(
                `接続のテストに失敗しました。 (${error.response.data['detail']})`,
                'error'
              );
            });
        } else {
          // 新規作成時
          jwtAxios
            .post(
              `/api/${selectedConnectionType.toLocaleLowerCase()}/connectiontest/`,
              keysToSnake(req)
            )
            .then(() => {
              showProgress(false);
              showSnackbar('接続のテストに成功しました。', 'success');
            })
            .catch((error) => {
              showProgress(false);
              showSnackbar(
                `接続のテストに失敗しました。 (${error.response.data['detail']})`,
                'error'
              );
            });
        }
      }
    });
  };

  const handlePost = () => {
    formik.validateForm().then((result) => {
      if (Object.keys(result).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(result, true));
      } else {
        showProgress(true);
        const req: ConnectionReq = extractConnectionReqFromFormik(
          selectedConnectionType,
          formik.values
        );

        jwtAxios
          .post(updateDataSourceUrl, keysToSnake(req))
          .then(() => {
            showProgress(false);
            showSnackbar('設定を保存しました。', 'success');
            cache.delete(`api/connections/`);
            navigate('/datasources');
          })
          .catch((error) => {
            showProgress(false);
            showSnackbar(`設定の保存に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const handlePut = () => {
    formik.validateForm().then((result) => {
      if (Object.keys(result).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(result, true));
      } else {
        showProgress(true);
        const formReq: ConnectionReq = extractConnectionReqFromFormik(
          selectedConnectionType,
          formik.values
        );

        const req: ConnectionReq = { ...formReq, connectionId: formik.values.connectionId };

        jwtAxios
          .put(updateDataSourceUrl, keysToSnake(req))
          .then(() => {
            showProgress(false);
            cache.delete(`api/connections/`);
            const updatedConnection = { ...connection, ...req };
            mutate(
              `api/${currentConnectionType.toLowerCase()}/connection/?connection_id=${
                formik.values.connectionId
              }`,
              updatedConnection
            );
            showSnackbar('設定を更新しました。', 'success');
            navigate('/datasources');
          })
          .catch((error) => {
            showProgress(false);
            showSnackbar(`設定の更新に失敗しました。 (${error.response.data['detail']})`, 'error');
          });
      }
    });
  };

  const checkValuesChanged = () => {
    const isAllEmptyValues = Object.values(formik.values).every((v) => v === '');
    if (isAllEmptyValues) {
      return navigate('/datasources');
    }
    return setDialogOpen(true);
  };

  return (
    <>
      <Grid item xs={12}>
        <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
          <div>
            <Typography sx={{ mt: 3, mb: 1 }}>
              データソース種類
              {connection?.connectionId ? null : (
                <Typography component="span" sx={{ color: 'error.main' }}>
                  *
                </Typography>
              )}
            </Typography>
            {auth.license?.datasourceType ? (
              <TextField
                select
                fullWidth
                id="type"
                name="type"
                value={selectedItem.value}
                defaultValue={
                  formik.values.name ? selectedItem.value : auth.license?.datasourceType[0]
                }
                onChange={handleTypeInputChange}
                variant="outlined"
                disabled={!!connection?.connectionId ?? false}
              >
                {formik.values.name
                  ? DATA_SOURCE_OPTIONS.map((item) => (
                      <MenuItem key={generateUuid()} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))
                  : auth.license?.datasourceType.map((item) => (
                      <MenuItem key={generateUuid()} value={item}>
                        {item}
                      </MenuItem>
                    ))}
              </TextField>
            ) : null}

            <Typography sx={{ mt: 3, mb: 1 }}>
              データソース名
              <Typography component="span" sx={{ color: 'error.main' }}>
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              id="name"
              name="name"
              placeholder="データソース名"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />

            {/* Redshift optional input  */}
            {isRedshift && (
              <>
                <Typography sx={{ mt: 3, mb: 1 }}>
                  ホスト
                  {connection?.connectionId ? null : (
                    <Typography component="span" sx={{ color: 'error.main' }}>
                      *
                    </Typography>
                  )}
                </Typography>
                <TextField
                  fullWidth
                  id="host"
                  name="host"
                  placeholder="ホスト"
                  value={formik.values.host}
                  onChange={formik.handleChange}
                  error={formik.touched.host && Boolean(formik.errors.host)}
                  helperText={formik.touched.host && formik.errors.host}
                  disabled={!!connection?.connectionId ?? false}
                />
                <Typography sx={{ mt: 3, mb: 1 }}>
                  ポート番号
                  <Typography component="span" sx={{ color: 'error.main' }}>
                    *
                  </Typography>
                </Typography>
                <TextField
                  fullWidth
                  id="port"
                  name="port"
                  placeholder="ポート番号"
                  type="number"
                  value={formik.values.port}
                  onChange={formik.handleChange}
                  error={formik.touched.port && Boolean(formik.errors.port)}
                  helperText={formik.touched.port && formik.errors.port}
                />
              </>
            )}

            {/* Snowflake optional input  */}
            {isSnowflake && (
              <>
                <Typography sx={{ mt: 3, mb: 1 }}>
                  アカウント
                  {connection?.connectionId ? null : (
                    <Typography component="span" sx={{ color: 'error.main' }}>
                      *
                    </Typography>
                  )}
                </Typography>
                <TextField
                  fullWidth
                  id="account"
                  name="account"
                  placeholder="アカウント"
                  value={formik.values.account}
                  onChange={formik.handleChange}
                  error={formik.touched.account && Boolean(formik.errors.account)}
                  helperText={formik.touched.account && formik.errors.account}
                  disabled={!!connection?.connectionId ?? false}
                />
                <Typography sx={{ mt: 3, mb: 1 }}>
                  ウェアハウス
                  {connection?.connectionId ? null : (
                    <Typography component="span" sx={{ color: 'error.main' }}>
                      *
                    </Typography>
                  )}
                </Typography>
                <TextField
                  fullWidth
                  id="warehouse"
                  name="warehouse"
                  placeholder="ウェアハウス"
                  value={formik.values.warehouse}
                  onChange={formik.handleChange}
                  error={formik.touched.warehouse && Boolean(formik.errors.warehouse)}
                  helperText={formik.touched.warehouse && formik.errors.warehouse}
                  disabled={!!connection?.connectionId ?? false}
                />
              </>
            )}

            <Typography sx={{ mt: 3, mb: 1 }}>
              データベース名
              {connection?.connectionId ? null : (
                <Typography component="span" sx={{ color: 'error.main' }}>
                  *
                </Typography>
              )}
            </Typography>
            <TextField
              fullWidth
              id="db"
              name="db"
              placeholder="データベース名"
              value={formik.values.db}
              onChange={formik.handleChange}
              error={formik.touched.db && Boolean(formik.errors.db)}
              helperText={formik.touched.db && formik.errors.db}
              disabled={!!connection?.connectionId}
            />

            <Typography sx={{ mt: 3, mb: 1 }}>
              ユーザー名
              <Typography component="span" sx={{ color: 'error.main' }}>
                *
              </Typography>
            </Typography>
            <TextField
              fullWidth
              id="user"
              name="user"
              autoComplete="off"
              placeholder="ユーザー名"
              value={formik.values.user}
              onChange={formik.handleChange}
              error={formik.touched.user && Boolean(formik.errors.user)}
              helperText={formik.touched.user && formik.errors.user}
            />
            <Typography sx={{ mt: 3, mb: 1 }}>
              パスワード
              {connection?.connectionId ? (
                isPasswordEdit ? (
                  <Typography component="span" sx={{ color: 'error.main' }}>
                    *
                  </Typography>
                ) : null
              ) : (
                <Typography component="span" sx={{ color: 'error.main' }}>
                  *
                </Typography>
              )}
            </Typography>
            <TextField
              fullWidth
              id="passwd"
              name="passwd"
              autoComplete="off"
              placeholder={
                connection?.connectionId ? (isPasswordEdit ? '' : '********') : 'パスワード'
              }
              type={'password'}
              value={formik.values.passwd}
              onChange={formik.handleChange}
              error={formik.touched.passwd && Boolean(formik.errors.passwd)}
              helperText={formik.touched.passwd && formik.errors.passwd}
              disabled={connection?.connectionId ? (isPasswordEdit ? false : true) : false}
              InputProps={{
                endAdornment: connection?.connectionId ? (
                  <Tooltip title={'編集'} placement="top">
                    <span>
                      <IconButton
                        onClick={() => {
                          setIsPasswordEdit(!isPasswordEdit);
                        }}
                      >
                        <EditOutlined />
                      </IconButton>
                    </span>
                  </Tooltip>
                ) : null,
              }}
            />

            {isRedshift && (
              <>
                <Typography sx={{ mt: 3, mb: 1 }}>
                  Redshift IAM Role
                  <Typography component="span" sx={{ color: 'error.main' }}>
                    *
                  </Typography>
                </Typography>
                <TextField
                  fullWidth
                  id="redshiftIamRole"
                  name="redshiftIamRole"
                  placeholder="Redshift IAM Role"
                  value={formik.values.redshiftIamRole}
                  onChange={formik.handleChange}
                  error={formik.touched.redshiftIamRole && Boolean(formik.errors.redshiftIamRole)}
                  helperText={formik.touched.redshiftIamRole && formik.errors.redshiftIamRole}
                />
              </>
            )}
            {/* Snowflake optional input  */}
            {isSnowflake && (
              <>
                <Typography sx={{ mt: 3, mb: 1 }}>
                  ロール
                  <Typography component="span" sx={{ color: 'error.main' }}>
                    *
                  </Typography>
                </Typography>
                <TextField
                  fullWidth
                  id="role"
                  name="role"
                  placeholder="ロール"
                  value={formik.values.role}
                  onChange={formik.handleChange}
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role}
                />

                <Typography sx={{ mt: 3, mb: 1 }}>
                  外部ステージを作成するスキーマ
                  <Typography component="span" sx={{ color: 'error.main' }}>
                    *
                  </Typography>
                </Typography>
                <TextField
                  fullWidth
                  id="schema"
                  name="schema"
                  placeholder="外部ステージを作成するスキーマ"
                  value={formik.values.schema}
                  onChange={formik.handleChange}
                  error={formik.touched.schema && Boolean(formik.errors.schema)}
                  helperText={formik.touched.schema && formik.errors.schema}
                />
              </>
            )}
          </div>
        </Box>
      </Grid>

      <Grid container justifyContent="flex-end">
        <Box
          component="span"
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ mt: 5 }}
        >
          <Grid container justifyContent="flex-end">
            {connection ? (
              <>
                <Button sx={{ marginRight: 2 }} color="inherit" onClick={() => setDialogOpen(true)}>
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  name="connectiontest"
                  sx={{ marginRight: 2 }}
                  disabled={!formik.isValid}
                  onClick={handleConnectionTest}
                >
                  接続テスト
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formik.isValid}
                  onClick={handlePut}
                >
                  設定を更新
                </Button>
              </>
            ) : (
              <>
                <Button
                  sx={{ marginRight: 2 }}
                  color="inherit"
                  onClick={() => checkValuesChanged()}
                >
                  キャンセル
                </Button>
                <Button
                  type="submit"
                  variant="outlined"
                  name="connectiontest"
                  sx={{ marginRight: 2 }}
                  disabled={!formik.dirty || !formik.isValid}
                  onClick={handleConnectionTest}
                >
                  接続テスト
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!formik.dirty || !formik.isValid}
                  onClick={handlePost}
                >
                  設定を保存
                </Button>
              </>
            )}
          </Grid>
        </Box>
      </Grid>

      <ConfirmDialogDesregard
        isOpen={dialogOpen}
        cancelFunc={() => setDialogOpen(false)}
        okFunc={() => navigate('/datasources')}
      />
    </>
  );
}
