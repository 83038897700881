import { SchemaTableSetting } from '../../common/types/Responses';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import { BaseDataGrid } from '../BaseDataGrid';

type Props = {
  isOpen: boolean;
  title: string;
  isLoading: boolean | undefined;
  rows: SchemaTableSetting[];
  selectionModel: GridRowSelectionModel;
  handleSelectionModel: Dispatch<SetStateAction<GridRowSelectionModel>>;
  selectedTable: SchemaTableSetting | undefined;
  handleSelectedTable: Dispatch<SetStateAction<SchemaTableSetting | undefined>>;
  cancelFunc: () => void;
  okFunc: () => void;
};

export default function ChooseTableDialog({
  isOpen,
  title,
  isLoading,
  rows,
  selectionModel,
  handleSelectionModel,
  selectedTable,
  handleSelectedTable,
  cancelFunc,
  okFunc,
}: Props): React.JSX.Element {
  const columns: GridColDef[] = [
    {
      field: 'radiobutton',
      headerName: '',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Radio
          checked={`${selectedTable?.schemaN}${selectedTable?.tableN}` === params.id}
          value={params.id}
        />
      ),
    },
    {
      field: 'schemaN',
      headerName: 'スキーマ',
      flex: 1,
    },
    {
      field: 'tableN',
      headerName: 'テーブル',
      flex: 1,
    },
  ];

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen}>
      <DialogContent>
        <Typography variant="h6" marginBottom={2}>
          {title}
        </Typography>
        <Grid container spacing={3} mb={2} justifyContent="center">
          <Grid item xs={12}>
            <Paper>
              <div style={{ display: 'flex', height: '100%' }}>
                <BaseDataGrid
                  loading={isLoading}
                  rows={rows}
                  columns={columns}
                  selectionModel={selectionModel}
                  disableSelectionOnClick={false}
                  getRowIdFunc={(row: SchemaTableSetting) => `${row.schemaN}${row.tableN}`}
                  onSelectionModelChangeFunc={(selectionModel: GridRowSelectionModel) => {
                    if (selectionModel.length !== 0) {
                      const selectedOption = rows.find(
                        (row) => `${row.schemaN}${row.tableN}` === selectionModel[0]
                      );
                      handleSelectedTable(selectedOption);
                    } else {
                      handleSelectedTable(undefined);
                    }
                    handleSelectionModel(selectionModel);
                  }}
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'schemaN', sort: 'asc' }],
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  sx={{
                    '.MuiDataGrid-row.Mui-selected': {
                      backgroundColor: 'rgba(25, 118, 240, 0.3)',
                      ':hover': { backgroundColor: 'rgba(25, 118, 240, 0.3)' },
                    },
                    '.MuiDataGrid-row': {
                      ':hover': { backgroundColor: 'rgba(25, 118, 240, 0.1)', cursor: 'pointer' },
                    },
                  }}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ margin: 4 }}>
        <Button onClick={cancelFunc} color="inherit" sx={{ marginRight: 2 }}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          onClick={okFunc}
          color="primary"
          autoFocus
          disabled={selectionModel.length === 0}
        >
          設定する
        </Button>
      </DialogActions>
    </Dialog>
  );
}
