export const DataSource = {
  RedShift: 'Redshift',
  SnowFlake: 'Snowflake',
} as const;

/* prettier-ignore */
export type DataSource = typeof DataSource[keyof typeof DataSource];

export type DataSourceOption = {
  label: string;
  value: DataSource;
};
