import { Box, Button, Container, CssBaseline, Grid, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useNavigate } from 'react-router';

const theme = createTheme({
  palette: {
    background: {
      default: '#E4E4E4',
    },
  },
});

export default function CompletePasswordReset() {
  const navigation = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item xs={12}>
            <CssBaseline />
            <Box
              sx={{
                width: 500,
                marginTop: 14,
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#FFFFFF',
                padding: 4,
              }}
            >
              <CheckCircleOutlinedIcon
                className="text-green-500 mr-1"
                color="success"
                fontSize="large"
              />
              <Box sx={{ mt: 1 }}>
                <Typography component="h1" variant="h4">
                  パスワードの再設定が完了しました。
                </Typography>
              </Box>
              <Box sx={{ mt: 1 }}>
                <Typography component="h1" variant="body1">
                  サインイン画面へお進みください。
                </Typography>
              </Box>
              <Grid container alignItems="center" justifyContent="flex-end" direction="row">
                <Button
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={() => {
                    navigation(`/signin`);
                  }}
                >
                  サインイン画面へ
                </Button>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
