export const JOIN_TYPES = {
  INNER_JOIN: {
    CODE: 'inner',
    LABEL: '内部結合',
    KEYWORD: 'INNER JOIN',
  },
  LEFT_JOIN: {
    CODE: 'left',
    LABEL: '左外部結合',
    KEYWORD: 'LEFT OUTER JOIN',
  },
  RIGHT_JOIN: {
    CODE: 'right',
    LABEL: '右外部結合',
    KEYWORD: 'RIGHT OUTER JOIN',
  },
};
export const MAX_NUMBER_OF_JOINED_TABLES = 10;
export const MAX_NUMBER_OF_JOIN_CONDITIONS = 5;
export const EQUAL_OPERATOR = '=';
