import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import React from 'react';

type Props = {
  isOpen: boolean;
  tile: string;
  cancelFunc: () => void;
  okFunc: () => void;
};
const ConfirmDialogDelete: React.FC<Props> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.cancelFunc}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
    >
      <DialogTitle id="alert-dialog-title">&quot;{props.tile}&quot;を削除しますか？</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          削除した項目は、復元できません。
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{ mr: 2 }} onClick={props.cancelFunc} color="inherit">
          キャンセル
        </Button>
        <Button onClick={props.okFunc} variant="contained" color="error" autoFocus>
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogDelete;
