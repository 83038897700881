import { JoinedTable, TableInfo } from '../types';
import type { SWRConfiguration } from 'swr';
import useSWRImmutable from 'swr/immutable';
import jwtAxios from '../axios';
import { keysToCamel } from '../func/converter';

const config: SWRConfiguration = {};
export const conditionType = {
  simple: 'simple',
  advanced: 'advanced',
};

export const useColumnsOfMultipleTablesSwr = (
  connectionId: string,
  newTables: JoinedTable[],
  conditionType: string
) => {
  const { data, error } = useSWRImmutable<TableInfo[]>(
    newTables.map(
      (e) =>
        `api/columns/?connection_id=${connectionId}&schema_n=${e.schemaN}&table_n=${e.tableN}&condition_type=${conditionType}`
    ),
    () =>
      Promise.all(
        newTables.map(async (table) => {
          const res = await jwtAxios.get(
            `api/columns/?connection_id=${connectionId}&schema_n=${table.schemaN}&table_n=${table.tableN}&condition_type=${conditionType}`
          );
          return {
            schemaN: table.schemaN,
            tableN: table.tableN,
            columns: Array.isArray(res.data) ? res.data.map((r) => keysToCamel(r)) : res.data,
          };
        })
      ),
    config
  );

  return {
    newTables: data,
    isLoading: newTables.length > 0 && Boolean(connectionId) && !error && !data,
    isError: error,
  };
};
