import { BaseContent } from '../components/BaseContent';
import LicenseForm from '../components/layoutparts/LicenseForm';

export default function LicensePage() {
  return (
    <BaseContent title="ライセンス">
      <LicenseForm />
    </BaseContent>
  );
}
