import { useCallback, useEffect, useRef, useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { moveArrayItem } from '../modules/common';
import { Box, Stack } from '@mui/material';
import { Select } from '../common/types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

enum ITEM_TYPE {
  CARD = 'Card',
}

export const DndWrapper: React.FC<{
  index: number;
  children: React.ReactNode;
  onMoveItem: (dragIndex: number, hoverIndex: number) => void;
  onDropEnd?: () => void;
}> = ({ children, onMoveItem, index, onDropEnd }) => {
  const ref = useRef<HTMLDivElement>(null);

  /**
   * define drop function from react-dnd
   * when user drag an element and hover to another element,
   * hover function will call and calculate new index for element then wap it
   */
  const [, drop] = useDrop<{ index: number }>({
    accept: ITEM_TYPE.CARD,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset() ?? { x: 0, y: 0 };
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      )
        return;

      onMoveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },

    drop: () => {
      onDropEnd?.();
    },
  });

  /**
   * define drag function from react-dnd
   * @param: isDragging => tracking when element is dragging
   */
  const [{ isDragging }, drag, preview] = useDrag<{ index: number }, null, { isDragging: boolean }>(
    {
      item: { index },
      type: ITEM_TYPE.CARD,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }
  );

  // register div element with ref to Drag and Drop monitor
  preview(ref);
  drop(ref);

  const opacity = isDragging ? 0 : 1;

  return (
    <Stack
      direction={'row'}
      ref={ref}
      sx={{
        opacity,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '5px',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        p: 1,
        mt: 1,
      }}
    >
      <Box ref={drag} sx={{ cursor: 'move', mt: 1, mr: 1 }}>
        <DragIndicatorIcon />
      </Box>
      {children}
    </Stack>
  );
};

export const DndContainer: React.FC<{
  data: Select[];
  renderItem: (item: Select, index: number) => React.ReactNode;
  onDropEnd?: (data: Select[]) => void;
}> = ({ data: dataP, renderItem, onDropEnd }) => {
  const [data, setData] = useState<Select[]>([]);

  const onMoveItem = useCallback((drag: number, hover: number) => {
    setData((s) => {
      return moveArrayItem(s, drag, hover);
    });
  }, []);

  useEffect(() => {
    setData(dataP);
  }, [dataP]);

  return (
    <DndProvider backend={HTML5Backend}>
      {data.length
        ? data.map((row, index) => {
            return (
              <DndWrapper
                onDropEnd={() => onDropEnd?.(data)}
                index={index}
                onMoveItem={onMoveItem}
                key={`drag_drop_${row.columnUuid}`}
              >
                {renderItem(row, index)}
              </DndWrapper>
            );
          })
        : null}
    </DndProvider>
  );
};
