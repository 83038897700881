const Auth = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userPoolId: (window as any)._env_.REACT_APP_AUTH_USER_POOL_ID,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userPoolClientId: (window as any)._env_.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  authenticationFlowType: 'USER_SRP_AUTH',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userPoolEndpoint: (window as any)._env_.REACT_APP_COGNITO_ENDPOINT,
};

export default Auth;
