import { AuthContext, initialAuthValue } from './AuthContext';
import React, { useState } from 'react';

type Props = {
  children: React.ReactNode;
};

const AuthProvider: React.FC<Props> = (props) => {
  const [auth, setAuth] = useState(initialAuthValue);

  return <AuthContext.Provider value={{ auth, setAuth }}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
