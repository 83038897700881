import * as yup from 'yup';
import { Cancel } from '@mui/icons-material';

import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FormikTouched, FormikValues, setNestedObjectValues, useFormik } from 'formik';
import { Group, GroupsTable, GroupsUser, SchemasTable } from '../../common/types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { createGroup, deepCopyObjectList, updateGroup } from '../../modules/common';
import { useEffect, useState, useMemo } from 'react';

import Progress from './Progress';
import { SchemaDetail } from '../../common/types/Responses';
import { useAuth } from '../../hooks/use-auth';
import { useConnectionsSwr } from '../../common/swr/useConnectionsSwr';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from '../../hooks/SnackBar';
import { useTablesSwr } from '../../common/swr/useTablesSwr';
import { useUsersSwr } from '../../common/swr/useUsersSwr';
import ConfirmDialogDesregard from './ConfirmDialogDesregard';
import ModalAddItems from './ModalAddItems';
import { BaseDataGrid } from '../BaseDataGrid';
import { useProgress } from '../../hooks/useProgress';
import { useGroupsSwr } from '../../common/swr/useGroupsSwr';
import { useSWRConfig } from 'swr';
import { MAX_ARRAY_SIZE } from '../../common/const/maxArraySize';

type GroupRowUser = {
  firstId: string;
  userName: string;
  email: string;
  role: string;
};

const usersSchema = yup.object().shape({
  firstId: yup.string().required(),
  role: yup.string().required(),
});

const tablesSchema = yup.object().shape({
  connectionId: yup.string().required(),
  dbN: yup.string().required(),
  schemaN: yup.string().required(),
});

const validationSchema = yup.object({
  groupName: yup.string().required('グループ名は必須です。'),
  schemas: yup.array().of(tablesSchema).required('ユーザーは必須です。'),
  users: yup.array().of(usersSchema),
});

type Props = {
  group: Group | undefined;
};

export default function CreateGroupForm(props: Props) {
  const { showSnackbar, toggleIsLicValidity } = useSnackbar();
  const { showProgress } = useProgress();
  const navigation = useNavigate();
  const auth = useAuth();
  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstId: props.group ? props.group.firstId : '',
      groupName: props.group ? props.group.groupName : '',
      tables: deepCopyObjectList<GroupsTable>(props.group?.tables),
      schemas: deepCopyObjectList<SchemasTable>(props.group?.schemas),
      users: deepCopyObjectList<GroupsUser>(props.group?.users),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values;
    },
  });

  // APIs
  const { users, isLoading: userIsLoading, isError: usersIsError } = useUsersSwr();
  const { groups, mutate } = useGroupsSwr();
  const { cache } = useSWRConfig();

  const {
    connections,
    isLoading: connectionsIsLoading,
    isError: connectionsIsError,
  } = useConnectionsSwr();

  const {
    tables,
    isLoading: tablesIsLoading,
    isError: tablesIsError,
  } = useTablesSwr(
    connections.map((connection) => connection.connectionId),
    !connectionsIsLoading,
    true
  );

  // swr error
  useEffect(() => {
    if (usersIsError)
      showSnackbar(`テーブル取得APIエラー (${usersIsError.response.data['detail']})`, 'error');
    if (connectionsIsError)
      showSnackbar(
        `テーブル取得APIエラー (${connectionsIsError.response.data['detail']})`,
        'error'
      );
    if (tablesIsError)
      showSnackbar(`テーブル取得APIエラー (${tablesIsError.response.data['detail']})`, 'error');
  }, [usersIsError, connectionsIsError, tablesIsError, showSnackbar]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [tableDialogOpen, setTableDialogOpen] = useState(false);

  // 一覧用
  const [userRows, setUserRows] = useState<GroupRowUser[]>([]);
  const [dialogUserRows, setDialogUserRows] = useState<GroupRowUser[]>([]);
  const [tmpUserRows, setTmpUserRows] = useState<GroupRowUser[]>([]);

  const [schemaRows, setSchemaRows] = useState<SchemaDetail[]>([]);
  const [dialogSchemaRows, setDialogSchemaRows] = useState<SchemaDetail[]>([]);
  const [tmpSchemaRows, setTmpSchemaRows] = useState<SchemaDetail[]>([]);

  // 全ユーザー
  const allUsers = useMemo(() => {
    return users.filter((user) => user.adminRole !== 'admin' && user.adminRole !== 'superadmin');
  }, [userIsLoading]);

  // 全スキーマ
  const allSchemas = useMemo(() => {
    const allSchemas: SchemaDetail[] = [];
    try {
      tables?.forEach((schemas, index) => {
        const connection = connections[index];

        schemas.forEach((schema) => {
          allSchemas.push({ ...connection, schemaName: schema.schemaN });
        });
      });
    } catch (error) {
      console.error(error);
    }

    return allSchemas;
  }, [connectionsIsLoading, tablesIsLoading]);

  useEffect(() => {
    // 登録済みユーザー
    const initialUserRows: GroupRowUser[] = [];
    allUsers.map((user) => {
      const gUser = props.group?.users.find((u) => u.firstId == user.firstId);
      if (gUser) {
        initialUserRows.push({
          firstId: user.firstId,
          userName: user.userName,
          email: user.email,
          role: gUser.role,
        });
      }
    });

    // 登録済みスキーマ
    const initialSchemaRows: SchemaDetail[] = [];
    allSchemas.forEach((schema) => {
      const gSchema = props.group
        ? props.group?.schemas.some(
            (t) =>
              t.connectionId == schema.connectionId &&
              t.dbN == schema.db &&
              t.schemaN == schema.schemaName
          )
        : false;
      if (gSchema) {
        initialSchemaRows.push(schema);
      }
    });

    setUserRows(initialUserRows);
    setSchemaRows(initialSchemaRows);
    // 初期値チェック(新規作成時の作成ボタンdisable)
    formik.validateForm();
  }, [userIsLoading, connectionsIsLoading, tablesIsLoading]);

  const handleRoleChange = (event: SelectChangeEvent) => {
    const firstId = event.target.name;
    const role = event.target.value;
    // rowsを書き換える
    const newUserRows = userRows.map((user: GroupRowUser) => {
      if (user.firstId === firstId) {
        user['role'] = role;
        return user;
      } else {
        return user;
      }
    });
    setUserRows(newUserRows);
    const changedUsers = formik.values.users.map((user: GroupsUser) => {
      if (user.firstId === firstId) {
        return {
          firstId: firstId,
          role: role,
        };
      } else {
        return user;
      }
    });
    formik.setFieldValue('users', changedUsers);
  };
  const handleDialogRoleChange = (event: SelectChangeEvent) => {
    const firstId = event.target.name;
    const role = event.target.value;
    // rowsを書き換える
    const newUserRows = tmpUserRows.map((user: GroupRowUser) => {
      if (user.firstId === firstId) {
        user['role'] = role;
        return user;
      } else {
        return user;
      }
    });
    setTmpUserRows(newUserRows);
  };
  const handleAddDialogRoleChange = (event: SelectChangeEvent) => {
    const firstId = event.target.name;
    const role = event.target.value;
    // rowsを書き換える
    const newUserRows = dialogUserRows.map((user: GroupRowUser) => {
      if (user.firstId === firstId) {
        user['role'] = role;
        return user;
      } else {
        return user;
      }
    });
    setDialogUserRows(newUserRows);
  };

  // ユーザー追加用モーダルOpen
  const handleAddUser = () => {
    // 追加用ユーザー
    const addDialogUserRows: GroupRowUser[] = [];
    allUsers.forEach((user) => {
      const gUser = userRows.some((u) => u.firstId === user.firstId);
      if (gUser) {
        return;
      }
      const tUser = tmpUserRows.some((u) => u.firstId === user.firstId);
      if (tUser) {
        return;
      }
      addDialogUserRows.push({
        firstId: user.firstId,
        userName: user.userName,
        email: user.email,
        role: 'general',
      });
    });
    setDialogUserRows(addDialogUserRows);
    setUserDialogOpen(true);
  };

  // スキーマ追加用モーダルOpen
  const handleAddSchema = () => {
    // 追加用テーブル
    const addDialogSchemaRows: SchemaDetail[] = [];
    allSchemas.forEach((schema) => {
      const gSchema = schemaRows.some((t) => t === schema);
      if (gSchema) {
        return;
      }
      const tSchema = tmpSchemaRows.some((t) => t === schema);
      if (tSchema) {
        return;
      }
      addDialogSchemaRows.push(schema);
    });
    setDialogSchemaRows(addDialogSchemaRows);
    setTableDialogOpen(true);
  };

  const usersColumns: GridColDef[] = [
    { field: 'userName', headerName: 'ユーザー名', flex: 2 },
    { field: 'email', headerName: 'メールアドレス', flex: 2 },
    {
      field: 'role',
      headerName: '権限',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams<GroupRowUser>) => (
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <Select
            labelId={`${params.row.firstId}-select-label`}
            id={`${params.row.firstId}-select-label`}
            name={`${params.row.firstId}`}
            value={params.row.role}
            onChange={handleRoleChange}
            defaultValue="general"
          >
            <MenuItem value={'leader'}>編集</MenuItem>
            <MenuItem value={'general'}>閲覧</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<GroupRowUser>) => (
        <Tooltip title="削除" placement="top">
          <IconButton onClick={() => deleteUser(params.row)}>
            <Cancel />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  const dialogUsersColumns: GridColDef[] = [
    { field: 'userName', headerName: 'ユーザー名', flex: 2 },
    { field: 'email', headerName: 'メールアドレス', flex: 2 },
    {
      field: 'role',
      headerName: '権限',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams<GroupRowUser>) => (
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <Select
            labelId={`${params.row.firstId}-select-label`}
            id={`${params.row.firstId}-select-label`}
            name={`${params.row.firstId}`}
            value={params.row.role}
            onChange={handleDialogRoleChange}
            defaultValue="general"
          >
            <MenuItem value={'leader'}>編集</MenuItem>
            <MenuItem value={'general'}>閲覧</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<GroupRowUser>) => (
        <Tooltip title="削除" placement="top">
          <IconButton
            onClick={() => {
              setDialogUserRows([...dialogUserRows, params.row]);
              setTmpUserRows(tmpUserRows.filter((user) => user !== params.row));
            }}
          >
            <Cancel />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  const dialogAddUsersColumns: GridColDef[] = [
    { field: 'userName', headerName: 'ユーザー名', flex: 2 },
    { field: 'email', headerName: 'メールアドレス', flex: 2 },
    {
      field: 'role',
      headerName: '権限',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 2,
      renderCell: (params: GridRenderCellParams<GroupRowUser>) => (
        <FormControl sx={{ m: 1, minWidth: 200 }} size="small">
          <Select
            labelId={`${params.row.firstId}-select-label`}
            id={`${params.row.firstId}-select-label`}
            name={`${params.row.firstId}`}
            value={params.row.role}
            onChange={handleAddDialogRoleChange}
            defaultValue="general"
          >
            <MenuItem value={'leader'}>編集</MenuItem>
            <MenuItem value={'general'}>閲覧</MenuItem>
          </Select>
        </FormControl>
      ),
    },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<GroupRowUser>) => (
        <Button
          onClick={() => {
            setDialogUserRows(dialogUserRows.filter((user) => user !== params.row));
            setTmpUserRows([...tmpUserRows, params.row]);
          }}
          variant="outlined"
          color="primary"
          autoFocus
        >
          + 追加
        </Button>
      ),
    },
  ];

  const dataSourcesColumns: GridColDef[] = [
    { field: 'name', headerName: 'データソース名', flex: 2 },
    { field: 'db', headerName: 'データベース', flex: 2 },
    { field: 'schemaName', headerName: 'スキーマ', flex: 2 },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<SchemaDetail>) => {
        if (auth.role !== 'admin' && auth.role !== 'superadmin') {
          return null;
        }
        return (
          <Tooltip title="削除" placement="top">
            <IconButton
              onClick={() => {
                deleteSchema(params.row);
              }}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const dialogDataSourcesColumns: GridColDef[] = [
    { field: 'name', headerName: 'データソース名', flex: 2 },
    { field: 'db', headerName: 'データベース', flex: 2 },
    { field: 'schemaName', headerName: 'スキーマ', flex: 2 },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<SchemaDetail>) => (
        <Tooltip title="削除" placement="top">
          <IconButton
            onClick={() => {
              setDialogSchemaRows([...dialogSchemaRows, params.row]);
              setTmpSchemaRows(tmpSchemaRows.filter((schema) => schema !== params.row));
            }}
          >
            <Cancel />
          </IconButton>
        </Tooltip>
      ),
    },
  ];
  const dialogAddDataSourcesColumns: GridColDef[] = [
    { field: 'name', headerName: 'データソース名', flex: 1 },
    { field: 'db', headerName: 'データベース', flex: 1 },
    { field: 'schemaName', headerName: 'スキーマ', flex: 1 },
    {
      field: 'cancel',
      headerName: '',
      flex: 0.5,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      align: 'right',
      renderCell: (params: GridRenderCellParams<SchemaDetail>) => (
        <Button
          onClick={() => {
            setDialogSchemaRows(dialogSchemaRows.filter((schema) => schema !== params.row));
            setTmpSchemaRows([...tmpSchemaRows, params.row]);
          }}
          variant="outlined"
          color="primary"
          autoFocus
        >
          + 追加
        </Button>
      ),
    },
  ];

  const deleteUser = (userId: GroupRowUser) => {
    const changedUsers = userRows.filter((u) => u !== userId);
    formik.setFieldValue(
      'users',
      changedUsers.map((u) => {
        return {
          firstId: u.firstId,
          role: u.role,
        };
      })
    );
    setUserRows(changedUsers);
  };
  const addUser = () => {
    const users = [...userRows, ...tmpUserRows];
    formik.setFieldValue(
      'users',
      users.map((u) => {
        return {
          firstId: u.firstId,
          role: u.role,
        };
      })
    );
    setUserRows(users);
  };

  const deleteSchema = (schemaId: SchemaDetail) => {
    const changedSchema = schemaRows.filter((t) => t !== schemaId);
    formik.setFieldValue(
      'schemas',
      changedSchema.map((t) => {
        return {
          connectionId: t.connectionId,
          dbN: t.db,
          schemaN: t.schemaName,
        };
      })
    );
    setSchemaRows(changedSchema);
  };

  const addSchema = () => {
    const schemas = [...schemaRows, ...tmpSchemaRows];

    formik.setFieldValue(
      'schemas',
      schemas.map((t) => {
        return {
          connectionId: t.connectionId,
          dbN: t.db,
          schemaN: t.schemaName,
        };
      })
    );

    setSchemaRows(schemas);
  };

  // Submits
  const handlePost = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
      } else {
        showProgress(true);
        const groupData = {
          groupName: formik.values.groupName,
          tables: [],
          schemas: formik.values.schemas,
          users: formik.values.users,
        };
        createGroup(groupData).then((result) => {
          if (result.success) {
            toggleIsLicValidity(false);
            showProgress(false);
            showSnackbar('グループを作成しました。', 'success');
            cache.delete(`api/groups/`);
            cache.delete(`api/users/`);
            navigation('/groups');
          } else {
            toggleIsLicValidity(true, result.status, result.error?.message);
            showProgress(false);
            showSnackbar(
              `グループの作成に失敗しました。 (${result.error.response.data['detail']})`,
              'error'
            );
          }
        });
      }
    });
  };

  const handlePut = () => {
    formik.validateForm().then((error) => {
      if (Object.keys(error).length !== 0) {
        formik.setTouched(setNestedObjectValues<FormikTouched<FormikValues>>(error, true));
      } else {
        showProgress(true);
        const groupData = {
          firstId: formik.values.firstId,
          groupName: formik.values.groupName,
          tables: [],
          schemas: formik.values.schemas,
          users: formik.values.users,
        };
        updateGroup(groupData).then((result) => {
          if (result.success) {
            toggleIsLicValidity(false);
            showProgress(false);
            showSnackbar('グループを更新しました。', 'success');
            if (groups.length < MAX_ARRAY_SIZE) {
              const updatedUser = groups.map((group) => {
                if (group.firstId == formik.values.firstId) {
                  return { ...group, ...groupData };
                }
                return group;
              });
              mutate(updatedUser, true);
            } else {
              mutate();
              cache.delete(`api/groups/`);
            }
            cache.delete(`api/users/`);
            navigation('/groups');
          } else {
            toggleIsLicValidity(true, result.status, result.error?.message);
            showProgress(false);
            showSnackbar(
              `グループの更新に失敗しました。 (${result.error.response.data['detail']})`,
              'error'
            );
          }
        });
      }
    });
  };

  return (
    <div>
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item xs={12}>
          <Box component="form" autoComplete="off" onSubmit={formik.handleSubmit}>
            <div>
              <Typography sx={{ mt: 3 }}>
                グループ名
                <Typography component="span" sx={{ color: 'error.main' }}>
                  *
                </Typography>
              </Typography>
              <TextField
                fullWidth
                id="groupName"
                name="groupName"
                placeholder="グループ名"
                value={formik.values.groupName}
                onChange={formik.handleChange}
                error={formik.touched.groupName && Boolean(formik.errors.groupName)}
                helperText={formik.touched.groupName && formik.errors.groupName}
              />
              <Typography sx={{ mt: 3 }}>ユーザーの設定</Typography>
              {userRows.length ? (
                <Paper>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                      <BaseDataGrid
                        loading={userIsLoading}
                        rows={userRows}
                        columns={usersColumns}
                        getRowIdFunc={(row: GroupRowUser) => row.firstId}
                      />
                    </div>
                  </div>
                </Paper>
              ) : (
                <></>
              )}
              <Button color="primary" onClick={() => handleAddUser()}>
                + 追加
              </Button>
              <Typography sx={{ mt: 3 }}>閲覧可能なスキーマの設定</Typography>
              {schemaRows.length ? (
                <Paper>
                  <div style={{ display: 'flex', height: '100%' }}>
                    <BaseDataGrid
                      loading={connectionsIsLoading || tablesIsLoading}
                      rows={schemaRows}
                      columns={dataSourcesColumns}
                      getRowIdFunc={(row: SchemaDetail) =>
                        `${row.connectionId}-${row.db}-${row.schemaName}`
                      }
                    />
                  </div>
                </Paper>
              ) : (
                <></>
              )}
              {(auth.role === 'admin' || auth.role === 'superadmin') && (
                <Button
                  color="primary"
                  onClick={() => {
                    handleAddSchema();
                  }}
                >
                  + 追加
                </Button>
              )}
            </div>
          </Box>
        </Grid>

        <Box
          component="span"
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{ mt: 3 }}
        >
          {formik.values.firstId === '' ? (
            <Grid container justifyContent="flex-end">
              <Button sx={{ mr: 1 }} color="inherit" onClick={() => setDialogOpen(true)}>
                キャンセル
              </Button>
              <Button
                disabled={Object.keys(formik.errors).length > 0}
                variant="contained"
                onClick={() => handlePost()}
              >
                新規作成
              </Button>
            </Grid>
          ) : (
            <Grid container justifyContent="flex-end">
              <Button sx={{ mr: 1 }} color="inherit" onClick={() => setDialogOpen(true)}>
                キャンセル
              </Button>
              <Button
                disabled={Object.keys(formik.errors).length > 0}
                variant="contained"
                onClick={() => handlePut()}
              >
                更新
              </Button>
            </Grid>
          )}
        </Box>
      </Grid>
      <ConfirmDialogDesregard
        isOpen={dialogOpen}
        cancelFunc={() => setDialogOpen(false)}
        okFunc={() => navigation('/groups')}
      />
      <ModalAddItems
        isOpen={userDialogOpen}
        cancelFunc={() => {
          setTmpUserRows([]);
          setUserDialogOpen(false);
        }}
        okFunc={() => {
          addUser();
          setTmpUserRows([]);
          setUserDialogOpen(false);
        }}
      >
        <Typography variant="h6" marginBottom={2}>
          ユーザーの設定
        </Typography>
        <Paper>
          <div style={{ display: 'flex', height: '100%' }}>
            <BaseDataGrid
              loading={userIsLoading}
              rows={dialogUserRows}
              columns={dialogAddUsersColumns}
              getRowIdFunc={(row: GroupRowUser) => row.firstId}
            />
          </div>
        </Paper>
        <Typography variant="h6" marginTop={3} marginBottom={2}>
          追加したユーザー
        </Typography>
        {tmpUserRows.length ? (
          <Paper elevation={0}>
            <div style={{ display: 'flex', height: '100%' }}>
              <BaseDataGrid
                loading={userIsLoading}
                hideFooter={true}
                headerHeight={0}
                rows={tmpUserRows}
                columns={dialogUsersColumns}
                getRowIdFunc={(row: GroupRowUser) => row.firstId}
                sx={{
                  '.MuiDataGrid-columnHeaders': {
                    border: 0,
                  },
                }}
              />
            </div>
          </Paper>
        ) : (
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="body2">追加したユーザーが表示されます</Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </ModalAddItems>
      <ModalAddItems
        isOpen={tableDialogOpen}
        cancelFunc={() => {
          setTmpSchemaRows([]);
          setTableDialogOpen(false);
        }}
        okFunc={() => {
          addSchema();
          setTmpSchemaRows([]);
          setTableDialogOpen(false);
        }}
      >
        <Typography variant="h6" marginBottom={2}>
          閲覧可能なスキーマの設定
        </Typography>
        <Paper>
          <div style={{ display: 'flex', height: '100%' }}>
            <BaseDataGrid
              loading={connectionsIsLoading || tablesIsLoading}
              rows={dialogSchemaRows}
              columns={dialogAddDataSourcesColumns}
              getRowIdFunc={(row: SchemaDetail) =>
                `${row.connectionId}-${row.db}-${row.schemaName}`
              }
            />
          </div>
        </Paper>
        <Typography variant="h6" marginTop={3} marginBottom={2}>
          追加したスキーマ
        </Typography>
        {tmpSchemaRows.length ? (
          <Paper elevation={0}>
            <div style={{ display: 'flex', height: '100%' }}>
              <BaseDataGrid
                hideFooter={true}
                headerHeight={0}
                loading={connectionsIsLoading || tablesIsLoading}
                rows={tmpSchemaRows}
                columns={dialogDataSourcesColumns}
                getRowIdFunc={(row: SchemaDetail) =>
                  `${row.connectionId}-${row.db}-${row.schemaName}`
                }
                sx={{
                  '.MuiDataGrid-columnHeaders': {
                    border: 0,
                  },
                }}
              />
            </div>
          </Paper>
        ) : (
          <Paper variant="outlined" sx={{ padding: 2 }}>
            <Grid container justifyContent="center">
              <Grid item>
                <Typography variant="body2">追加したスキーマが表示されます</Typography>
              </Grid>
            </Grid>
          </Paper>
        )}
      </ModalAddItems>
      <Progress open={userIsLoading || connectionsIsLoading || tablesIsLoading} />
    </div>
  );
}
