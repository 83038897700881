import { Backdrop, CircularProgress } from '@mui/material';

type Props = {
  open: boolean;
};

export default function Progress(props: Props) {
  return (
    <Backdrop open={props.open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 3 }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
