import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import React from 'react';

type Props = {
  isOpen: boolean;
  children: React.ReactNode;
  cancelFunc: () => void;
  okFunc: () => void;
};
const ConfirmDialogDelete: React.FC<Props> = (props) => {
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.cancelFunc}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="lg"
    >
      <DialogContent>{props.children}</DialogContent>
      <DialogActions sx={{ margin: 3 }}>
        <Button sx={{ mr: 2 }} onClick={props.cancelFunc} color="inherit">
          キャンセル
        </Button>
        <Button onClick={props.okFunc} variant="contained" color="primary" autoFocus>
          設定する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialogDelete;
