import { Join, JoinedTable } from '../../common/types';
import React, { Dispatch, SetStateAction, SyntheticEvent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  EQUAL_OPERATOR,
  JOIN_TYPES,
  MAX_NUMBER_OF_JOIN_CONDITIONS,
} from '../../common/const/joinTable';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import JoinTableIcon from '../join-icons';

type Props = {
  isOpen: boolean;
  isColumnLoading: boolean;
  joinIndex: number | undefined;
  joinSetting: Join;
  handleJoinSetting: Dispatch<SetStateAction<Join>>;
  joinedTables: JoinedTable[];
  okFunc: () => void;
  cancelFunc: () => void;
};

const ITEM_HEIGHT = 36;
const selectStyles = makeStyles({
  paper: {
    maxHeight: ITEM_HEIGHT * 5,
    width: 400,
    overflowX: 'auto',
  },
});

export default function JoinSettingDialog({
  isOpen,
  isColumnLoading,
  joinIndex,
  joinSetting,
  handleJoinSetting,
  joinedTables,
  okFunc,
  cancelFunc,
}: Props) {
  // Select Component Style
  const selectComponentStyles = selectStyles();

  const handleJoinTypeSelection = (event: SyntheticEvent, newValue: string) => {
    handleJoinSetting({
      ...joinSetting,
      type: newValue,
    });
  };

  const localColumns =
    joinIndex !== undefined && isOpen
      ? joinedTables.map((e, i) => (i - 1 < joinIndex ? e.columns ?? [] : [])).flat(1)
      : [];

  const foreignColumns =
    joinIndex !== undefined && isOpen ? joinedTables[joinIndex + 1]?.columns ?? [] : [];

  const handleChangeConditionKey = (
    event: SelectChangeEvent,
    index: number,
    isChangingLocalKey = true
  ) => {
    const currentSetting = joinSetting.conditions;
    if (!currentSetting[index]) return;
    if (isChangingLocalKey) {
      currentSetting[index].localKey = localColumns.find(
        (e) => e.columnUuid === event.target.value
      );
    } else {
      currentSetting[index].foreignKey = foreignColumns.find(
        (e) => e.columnUuid === event.target.value
      );
    }
    handleJoinSetting({
      ...joinSetting,
      conditions: currentSetting,
    });
  };

  const addCondition = () => {
    handleJoinSetting({
      ...joinSetting,
      conditions: [
        ...joinSetting.conditions,
        {
          localKey: undefined,
          foreignKey: undefined,
          operator: EQUAL_OPERATOR,
        },
      ],
    });
  };

  const removeCondition = (index: number) => {
    handleJoinSetting({
      ...joinSetting,
      conditions: joinSetting.conditions.filter((e, i) => i !== index),
    });
  };

  return (
    <Dialog fullWidth maxWidth="md" open={isOpen}>
      <DialogContent>
        <Typography variant="h6" marginBottom={2}>
          テーブル結合
        </Typography>
        <Grid container spacing={3} mb={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography marginBottom={2}>
              結合方法 <span style={{ color: 'red' }}>*</span>
            </Typography>
            <Tabs
              value={joinSetting.type}
              onChange={handleJoinTypeSelection}
              aria-label="Join Type Selection"
              TabIndicatorProps={{ style: { display: 'none' } }}
              variant="fullWidth"
            >
              {Object.values(JOIN_TYPES).map((type) => (
                <Tab
                  key={type.CODE}
                  value={type.CODE}
                  icon={<JoinTableIcon type={type.CODE} className={'join-setting-dialog'} />}
                  label={type.LABEL}
                  style={joinSetting.type === type.CODE ? { border: '2px solid #1976d2' } : {}}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Typography marginBottom={2}>
              結合条件 <span style={{ color: 'red' }}>*</span>
            </Typography>
            {joinSetting.conditions.map((value, index) => {
              const onlyOneCondition = joinSetting.conditions.length === 1;
              return (
                <Grid
                  container
                  key={`join_setting_dialog_${index}`}
                  alignItems={'center'}
                  marginTop={index > 0 ? 1 : 0}
                >
                  <Grid item width={onlyOneCondition ? '47%' : '45%'}>
                    <Select
                      fullWidth
                      variant="outlined"
                      value={value.localKey?.columnUuid ?? ''}
                      disabled={isColumnLoading}
                      MenuProps={{
                        classes: {
                          paper: selectComponentStyles.paper,
                        },
                      }}
                      onChange={(e) => handleChangeConditionKey(e, index)}
                    >
                      {localColumns.map((column) => {
                        const table = joinedTables.find((e) => e.tableUuid === column.tableUuid);
                        return (
                          <MenuItem key={`local_${column.columnUuid}`} value={column.columnUuid}>
                            {`${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${column.name} (${column.dataType.type})`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    textAlign={'center'}
                    fontSize={'1.5em'}
                    width={onlyOneCondition ? '6%' : '5%'}
                  >
                    {value.operator}
                  </Grid>
                  <Grid item width={onlyOneCondition ? '47%' : '45%'}>
                    <Select
                      fullWidth
                      variant="outlined"
                      value={value.foreignKey?.columnUuid ?? ''}
                      disabled={isColumnLoading}
                      MenuProps={{
                        classes: {
                          paper: selectComponentStyles.paper,
                        },
                      }}
                      onChange={(e) => handleChangeConditionKey(e, index, false)}
                    >
                      {foreignColumns.map((column) => {
                        const table = joinedTables.find((e) => e.tableUuid === column.tableUuid);
                        return (
                          <MenuItem key={`foreign_${column.columnUuid}`} value={column.columnUuid}>
                            {`${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${column.name} (${column.dataType.type})`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  {!onlyOneCondition && (
                    <Grid item textAlign={'center'} fontSize={'1.5em'} width={'5%'}>
                      <Tooltip title="削除" placement="top">
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => removeCondition(index)}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              );
            })}
            <Button
              style={{ marginTop: '1em' }}
              variant="outlined"
              aria-describedby={'Add'}
              onClick={addCondition}
              disabled={joinSetting.conditions.length >= MAX_NUMBER_OF_JOIN_CONDITIONS}
            >
              + 追加
            </Button>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ margin: 4 }}>
        <Button onClick={cancelFunc} color="inherit" sx={{ marginRight: 2 }}>
          キャンセル
        </Button>
        <Button variant="contained" onClick={okFunc} color="primary" autoFocus>
          設定する
        </Button>
      </DialogActions>
    </Dialog>
  );
}
