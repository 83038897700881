import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useAuth } from '../hooks/use-auth';
import { useLocation } from 'react-router-dom';
import { useSnackbar } from '../hooks/SnackBar';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useProgress } from '../hooks/useProgress';
import { useEffect, useMemo } from 'react';
import { QRCodeSVG } from 'qrcode.react';

const theme = createTheme({
  palette: {
    background: {
      default: '#E4E4E4',
    },
  },
});

export default function SetupMfa() {
  const auth = useAuth();
  const { showSnackbar } = useSnackbar();
  const { showProgress } = useProgress();
  const location = useLocation();

  const sharedSecret = useMemo(() => location.state?.sharedSecret, [location]);
  const email = useMemo(() => location.state?.email, [location]);

  const appLabel = 'CSA Downloader';
  const mfaLink = useMemo(
    () => `otpauth://totp/${appLabel}: ${email}?secret=${sharedSecret}`,
    [sharedSecret, email]
  );
  const executeSignInMfa = async () => {
    showProgress(true);
    const result = await auth.setupMfa(sharedSecret, formik.values.mfaCode);
    if (!result.success) {
      showProgress(false);
      showSnackbar('認証コードが正しくありません。再度入力してください。', 'error');
    }
  };

  const initialValues = {
    mfaCode: '',
  };

  const validationSchema = yup.object({
    mfaCode: yup
      .string()
      .length(6, 'MFA Codeは6文字でなければなりません。')
      .required('必須項目です。'),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: () => {
      executeSignInMfa();
    },
  });

  const handleBackToLogin = () => {
    window.location.replace('/signIn');
  };

  // サインイン後権限に応じて画面遷移先を制御する
  useEffect(() => {
    if (auth.frontendUserRole !== 'pending') {
      showProgress(false);
      if (auth.frontendUserRole === 'notAffiliation') {
        window.location.href = '/download_history';
      } else if (auth.frontendUserRole === 'superAdmin') {
        window.location.href = '/license';
      } else if (auth.frontendUserRole === 'read') {
        window.location.href = '/queries';
      } else {
        window.location.href = '/download';
      }
    }
  }, [auth.role]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item xs={12}>
            <CssBaseline />
            <Box
              sx={{
                width: 500,
                marginTop: 14,
                display: 'flex',
                flexDirection: 'column',
                bgcolor: '#FFFFFF',
                padding: 4,
              }}
            >
              <Typography component="h1" variant="h4">
                MFA設定画面
              </Typography>
              <Stack direction="row" justifyContent="center" mt={1}>
                <QRCodeSVG size={256} value={mfaLink} />
              </Stack>
              <Typography mt={2} sx={{ wordWrap: 'break-word' }} align="center">
                {sharedSecret}
              </Typography>
              <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      認証コード <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      autoFocus
                      required
                      fullWidth
                      id="mfaCode"
                      type="text"
                      placeholder="認証コードを入力"
                      name="mfaCode"
                      autoComplete="mfaCode"
                      value={formik.values.mfaCode}
                      onChange={formik.handleChange}
                      error={formik.touched.mfaCode && Boolean(formik.errors.mfaCode)}
                      helperText={formik.touched.mfaCode && formik.errors.mfaCode}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={1} alignItems="end" direction="column">
                  <Grid item xs={12}>
                    <Link style={{ cursor: 'pointer' }} onClick={handleBackToLogin}>
                      <Typography variant="body2">サインイン画面へ戻る</Typography>
                    </Link>
                  </Grid>
                </Grid>
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                  設定
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
}
