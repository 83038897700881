import type { SWRConfiguration } from 'swr';
import { User } from '../types';
import { fetcherKeysToCamel } from './fetcher';
import useSWR from 'swr';

const config: SWRConfiguration = {};

export const useUsersSwr = () => {
  const { data, error, mutate } = useSWR<User[]>(`api/users/`, fetcherKeysToCamel, config);
  const users = data === undefined ? [] : data;
  return {
    users: users,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  };
};
