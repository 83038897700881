import { Add, Cached, Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useLicenseSwr } from '../../common/swr/useLicenseSwr';
import { useEffect, useState } from 'react';
import ConfirmDialog from './ConfirmDialog';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useProgress } from '../../hooks/useProgress';
import { PostPutLicense } from '../../common/types/Responses';
import jwtAxios from '../../common/axios';
import { useSnackbar } from '../../hooks/SnackBar';
import { keysToSnake } from '../../common/func/converter';
import { utcToJstFormat } from '../../common/func/functions';

export default function LicenseForm() {
  const { showProgress } = useProgress();
  const { showSnackbar } = useSnackbar();

  // ライセンス暗号キー設定ダイアログ
  const [isOpenLicenseEncryptionKeySettingDialog, setIsOpenLicenseEncryptionKeySettingDialog] =
    useState(false);
  // 初回登録時かどうか
  const [isInitialRegistration, setIsInitialRegistration] = useState(false);

  // APIs
  const { license, isLoading, isError, mutate } = useLicenseSwr(true);

  // SWR Error
  useEffect(() => {
    if (isError)
      showSnackbar(`ライセンス取得APIエラー (${isError.response.data['detail']})`, 'error');
  }, [isError, showSnackbar]);

  // ライセンスキーの有無で初回登録か更新かを判断
  useEffect(() => {
    if (license.licenseKey) {
      setIsInitialRegistration(false);
    } else {
      setIsInitialRegistration(true);
    }
  }, [license]);

  const postPutLicense = async () => {
    showProgress(true);

    const params: PostPutLicense = {
      licenseKey: formik.values.licenseEncryptionKey,
    };

    if (isInitialRegistration) {
      // 初回
      jwtAxios
        .post('/api/license/', keysToSnake(params))
        .then(() => {
          // 再検証
          void mutate();
          showProgress(false);
          setIsOpenLicenseEncryptionKeySettingDialog(false);
          formik.resetForm();
          showSnackbar('ライセンスの登録に成功しました。', 'success');
        })
        .catch((error) => {
          showProgress(false);
          setIsOpenLicenseEncryptionKeySettingDialog(false);
          formik.resetForm();
          showSnackbar(
            `ライセンスの登録に失敗しました。 (${error.response.data['detail']})`,
            'error'
          );
        });
    } else {
      // 更新
      jwtAxios
        .put('/api/license/', keysToSnake(params))
        .then(() => {
          // 再検証
          void mutate();
          showProgress(false);
          setIsOpenLicenseEncryptionKeySettingDialog(false);
          formik.resetForm();
          showSnackbar('ライセンスの更新に成功しました。', 'success');
        })
        .catch((error) => {
          showProgress(false);
          setIsOpenLicenseEncryptionKeySettingDialog(false);
          formik.resetForm();
          showSnackbar(
            `ライセンスの更新に失敗しました。 (${error.response.data['detail']})`,
            'error'
          );
        });
    }
  };

  const initialValues = {
    licenseEncryptionKey: '',
  };
  const validationSchema = yup.object({
    licenseEncryptionKey: yup.string().required('必須項目です。'),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: () => {
      postPutLicense();
    },
  });

  return (
    <>
      {isLoading ? (
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {isInitialRegistration ? (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    sx={{ paddingBottom: 0 }}
                    title={
                      <Typography variant="subtitle1">
                        <strong>ライセンスを登録してください</strong>
                      </Typography>
                    }
                  />
                  <CardActions>
                    <Button
                      startIcon={<Add />}
                      onClick={() => {
                        setIsOpenLicenseEncryptionKeySettingDialog(
                          !isOpenLicenseEncryptionKeySettingDialog
                        );
                      }}
                    >
                      登録
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid container marginTop={2} justifyContent="center">
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    sx={{ paddingBottom: 2 }}
                    action={
                      <Tooltip title="ライセンスを更新" placement="top">
                        <IconButton
                          edge="end"
                          aria-label="download"
                          onClick={() => {
                            setIsOpenLicenseEncryptionKeySettingDialog(
                              !isOpenLicenseEncryptionKeySettingDialog
                            );
                          }}
                          sx={{ marginRight: 1 }}
                        >
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    }
                    title={
                      <Grid container columnSpacing={4} justifyContent="center">
                        <Grid item xs={6} md={6} lg={3}>
                          <Typography variant="subtitle1">
                            <strong>ライセンスキー</strong>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={6} lg={9}>
                          <Typography color="text.secondary">{license.licenseKey}</Typography>
                        </Grid>
                      </Grid>
                    }
                  />
                </Card>
              </Grid>

              <Grid item xs={12} marginTop={2}>
                <Card variant="outlined">
                  <CardContent sx={{ paddingTop: 0 }}>
                    <Grid
                      container
                      columnSpacing={4}
                      marginTop={2}
                      paddingX={0}
                      justifyContent="center"
                    >
                      <Grid item xs={6} md={6} lg={4}>
                        <Typography textAlign={'end'} variant="subtitle1">
                          <strong>有効期限</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={8}>
                        <Typography color="text.secondary">{license.expireDate}</Typography>
                      </Grid>

                      <Grid item xs={6} md={6} lg={4}>
                        <Typography textAlign={'end'} variant="subtitle1">
                          <strong>ユーザー数</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={8}>
                        <Typography color="text.secondary">{license.userNum}</Typography>
                      </Grid>

                      <Grid item xs={6} md={6} lg={4}>
                        <Typography textAlign={'end'} variant="subtitle1">
                          <strong>ライセンスの最終チェック日</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={8}>
                        <Typography color="text.secondary">{license.checkedAt}</Typography>
                      </Grid>

                      <Grid item xs={6} md={6} lg={4}>
                        <Typography textAlign={'end'} variant="subtitle1">
                          <strong>データ作成日</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={8}>
                        <Typography color="text.secondary">
                          {utcToJstFormat(license.createdAt)}
                        </Typography>
                      </Grid>

                      <Grid item xs={6} md={6} lg={4}>
                        <Typography textAlign={'end'} variant="subtitle1">
                          <strong>データ更新日</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6} lg={8}>
                        <Typography color="text.secondary">
                          {utcToJstFormat(license.updatedAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}

          <ConfirmDialog
            isOpen={isOpenLicenseEncryptionKeySettingDialog}
            tile={isInitialRegistration ? 'ライセンスの登録' : 'ライセンスの更新'}
            okNode={
              <Button
                variant="contained"
                startIcon={isInitialRegistration ? <Add /> : <Cached />}
                onClick={() => formik.handleSubmit()}
              >
                {isInitialRegistration ? '登録' : '更新'}
              </Button>
            }
            cancelFunc={() => {
              setIsOpenLicenseEncryptionKeySettingDialog(false);
              formik.resetForm();
            }}
            maxWidth={'sm'}
          >
            <Box component="form" noValidate onSubmit={formik.handleSubmit}>
              <TextField
                multiline
                minRows={4}
                maxRows={10}
                id="licenseEncryptionKey"
                name="licenseEncryptionKey"
                placeholder="入力してください"
                value={formik.values.licenseEncryptionKey}
                onChange={formik.handleChange}
                error={
                  formik.touched.licenseEncryptionKey && Boolean(formik.errors.licenseEncryptionKey)
                }
                helperText={
                  formik.touched.licenseEncryptionKey && formik.errors.licenseEncryptionKey
                }
                fullWidth
              />
            </Box>
          </ConfirmDialog>
        </>
      )}
    </>
  );
}
