import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Typography,
  colors,
} from '@mui/material';

import React, { startTransition, useEffect, useState } from 'react';
import { PostPreviewReq } from '../../common/types/Responses';
import { FormikProps } from 'formik';
import jwtAxios from '../../common/axios';
import { keysToCamel, keysToSnake } from '../../common/func/converter';
import { useSnackbar } from '../../hooks/SnackBar';
import { JoinedTable, SimpleQueryForm, SqlInfo } from '../../common/types';
import { formatSimpleQueryData } from '../../common/func/functions';

type Props = {
  isOpen: boolean;
  joinedTables: JoinedTable[];
  formik: FormikProps<SimpleQueryForm>;
  onClose: () => void;
};

const keyColor = colors.purple['600'];

const valueColor = colors.common.black;

const SqlPreviewDialog: React.FC<Props> = ({ isOpen, joinedTables, formik, onClose }) => {
  const { showSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string>('');
  const [sql, setSql] = useState<SqlInfo>({
    select: {
      key: '',
      value: '',
    },
    from: {
      key: '',
      value: '',
    },
    join: [],
    where: {
      key: '',
      value: '',
    },
    orderBy: {
      key: '',
      value: '',
    },
  });

  const handlePreviewSql = () => {
    const params: PostPreviewReq = {
      datasource: formik.values.datasource,
      queryName: formik.values.queryName,
      description: formik.values.description,
      schemaN: formik.values.schemaN,
      tableN: formik.values.tableN,
      simple: formatSimpleQueryData(formik.values.simple, joinedTables),
      enclosedText: formik.values.enclosedText,
    };

    startTransition(() => {
      setIsLoading(true);
      setIsError('');
    });

    jwtAxios
      .post('/api/sql_preview/', keysToSnake(params))
      .then((response) => {
        startTransition(() => {
          setSql(keysToCamel(response.data));
          setIsLoading(false);
          setIsError('');
        });
      })
      .catch((error) => {
        startTransition(() => {
          setIsLoading(false);
          setIsError(error.response.data['detail']);
        });
      });
  };

  const onClickCopy = () => {
    const sqlText =
      `${sql.select?.key ?? ''} ${sql.select?.value ?? ''}` +
      ` ${sql.from?.key ?? ''} ${sql.from?.value ?? ''}` +
      `${(sql.join ?? []).length !== 0 ? (sql.join ?? []).map((e) => ` ${e.key ?? ''} ${e.value}`).join('') : ''}` +
      `${sql.where?.value.length ? ` ${sql.where.key ?? ''} ${sql.where.value}` : ''}` +
      `${sql.orderBy?.value.length ? ` ${sql.orderBy.key ?? ''} ${sql.orderBy.value}` : ''}`;
    navigator.clipboard.writeText(sqlText);
    showSnackbar('SQL文をコピーしました', 'success');
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={20} />
        </Box>
      );
    }

    if (isError) {
      return (
        <Box mt={2} display="flex" justifyContent="center" alignItems="center">
          <Typography sx={{ wordBreak: 'break-word' }} variant="body1">
            {isError}
          </Typography>
        </Box>
      );
    }

    return (
      <Paper elevation={0} sx={{ paddingY: 2, backgroundColor: '#FAFAFA' }}>
        <Box
          sx={{
            paddingX: 2,
            maxHeight: 240,
            overflowY: 'auto',
            wordBreak: 'break-word',
            whiteSpaceCollapse: 'preserve',
          }}
        >
          <Typography sx={{ color: keyColor }} variant="body1">
            {sql.select?.key ?? ''}
          </Typography>
          <Typography sx={{ color: valueColor }} variant="body1">
            {sql.select?.value ?? ''}
          </Typography>
          <Typography sx={{ color: keyColor }} variant="body1">
            {sql.from?.key ?? ''}
          </Typography>
          <Typography sx={{ color: valueColor }} variant="body1">
            {sql.from?.value ?? ''}
          </Typography>
          {(sql.join ?? []).length > 0 &&
            (sql.join ?? []).map((join, index) => (
              <React.Fragment key={index}>
                <Typography sx={{ color: keyColor }} variant="body1">
                  {join.key ?? ''}
                </Typography>
                <Typography sx={{ color: valueColor }} variant="body1">
                  {join.value ?? ''}
                </Typography>
              </React.Fragment>
            ))}
          {sql.where?.value ? (
            <>
              <Typography sx={{ color: keyColor }} variant="body1">
                {sql.where?.key ?? ''}
              </Typography>
              <Typography sx={{ color: valueColor }} variant="body1">
                {sql.where?.value ?? ''}
              </Typography>
            </>
          ) : null}
          {sql.orderBy?.value ? (
            <>
              <Typography sx={{ color: keyColor }} variant="body1">
                {sql.orderBy?.key ?? ''}
              </Typography>
              <Typography sx={{ color: valueColor }} variant="body1">
                {sql.orderBy.value ?? ''}
              </Typography>
            </>
          ) : null}
        </Box>
      </Paper>
    );
  };

  const disabledCopyButton = isLoading || !!isError;

  useEffect(() => {
    if (isOpen) {
      handlePreviewSql();
    }
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="sql-preview-dialog-title"
      aria-describedby="sql-preview-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle id="sql-preview-dialog-title">SQL文</DialogTitle>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions sx={{ margin: 4 }}>
        <Button onClick={onClose} color="inherit" sx={{ marginRight: 2 }}>
          閉じる
        </Button>
        <Button
          variant="contained"
          disabled={disabledCopyButton}
          onClick={onClickCopy}
          color="primary"
          autoFocus
        >
          コピー
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SqlPreviewDialog;
