import * as React from 'react';

import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

import { Snackbar, SnackbarOrigin } from '@mui/material';

/** スナックバーの表示をカスタマイズ */
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
  open: boolean;
  message: string;
  severity?: AlertColor;
  /** error | warning | info | success */
  vertical?: SnackbarOrigin['vertical'];
  horizontal?: SnackbarOrigin['horizontal'];

  onClose?: () => void;
};

export const GlobalSnackbar: React.FC<Props> = ({
  open,
  message,
  severity = 'info',
  vertical = 'bottom',
  horizontal = 'left',
  onClose,
}) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      sx={[{ maxWidth: '80%' }]}
    >
      <Alert
        sx={{
          width: '100%',
          '.MuiAlert-message': {
            wordBreak: 'break-word',
          },
        }}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};
