import { Cancel, EditOutlined, HorizontalRule } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Popover,
  PopoverActions,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  colors,
} from '@mui/material';
import {
  DesktopDatePicker,
  DesktopDatePickerProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import React, { startTransition, useEffect, useRef, useState } from 'react';
import {
  BooleanComparisonValue,
  NumberComparisonOperator,
  TextComparisonOperator,
} from '../../common/types/Responses';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import utc from 'dayjs/plugin/utc';
import { FormikProps } from 'formik';
import { convertWhereConditionToString, shortenColumnName } from '../../common/func/functions';
import { MAX_COLUMN_TEXT_LENGTH } from '../../common/const/maxTextLength';
import {
  SimpleQueryForm,
  TimeBetweenCondition,
  TimeComparisonCondition,
  BooleanComparisonCondition,
  NumberComparisonCondition,
  TextComparisonCondition,
  Where,
  JoinedTable,
} from '../../common/types';
import { EQUAL_OPERATOR } from '../../common/const/joinTable';

dayjs.extend(utc);

type Props = {
  formik: FormikProps<SimpleQueryForm>;
  joinedTables: JoinedTable[];
  isColumnLoading: boolean;
};
const WhereConditionParts: React.FC<Props> = ({ formik, joinedTables, isColumnLoading }: Props) => {
  const [disabledAddButton, setDisabledAddButton] = useState<boolean>(false);

  const columnsRow = joinedTables.map((e) => e.columns ?? []).flat(1);
  const prevIndex = useRef<number>(-1);

  // 選択項目変更
  const onClickSelectionModelChange = (columnUuid: string) => {
    setDisabledAddButton(false);
    const chosenColumn = columnsRow.find((row) => row.columnUuid === columnUuid);
    if (chosenColumn === undefined) {
      return;
    }

    const table = joinedTables.find((e) => e.tableUuid === chosenColumn.tableUuid);
    const columnTemp = {
      tableUuid: chosenColumn.tableUuid,
      columnUuid: chosenColumn.columnUuid,
      name: `${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${chosenColumn.name} (${chosenColumn.dataType.type})`,
      type: chosenColumn.dataType.type,
    };
    if (textTypes.includes(chosenColumn.dataType.type)) {
      const condition: TextComparisonCondition = {
        type: 'text',
        tableUuid: chosenColumn.tableUuid,
        columnUuid: chosenColumn.columnUuid,
        operator: EQUAL_OPERATOR,
        value: '',
      };
      if (textRadio === 'match') {
        setDisabledAddButton(!matchValue);
      } else if (textRadio === 'like') {
        setDisabledAddButton(!likeValue);
      }
      setDialogWhereCondition(condition);
    } else if (numberTypes.includes(chosenColumn.dataType.type)) {
      const condition: NumberComparisonCondition = {
        type: 'num',
        tableUuid: chosenColumn.tableUuid,
        columnUuid: chosenColumn.columnUuid,
        operator: EQUAL_OPERATOR,
        value: '',
      };
      setDialogWhereCondition(condition);
    } else if (timeTypes.includes(chosenColumn.dataType.type)) {
      const condition: TimeComparisonCondition = {
        type: 'time',
        tableUuid: chosenColumn.tableUuid,
        columnUuid: chosenColumn.columnUuid,
        operator: '>=',
        value: 1,
        unit: 'year',
      };
      setDialogWhereCondition(condition);
    } else if (booleanTypes.includes(chosenColumn.dataType.type)) {
      const condition: BooleanComparisonCondition = {
        type: 'bool',
        tableUuid: chosenColumn.tableUuid,
        columnUuid: chosenColumn.columnUuid,
        value: 'true',
      };
      setDisabledAddButton(!boolValue);
      setDialogWhereCondition(condition);
    }

    setSelectedColumn(columnTemp);
  };

  // 条件設定を編集ボタンでダイアログを開く
  const onClickUpdateSettingWhereCondition = (condition: Where, index: number) => {
    onClickConditionSetting(condition.columnUuid);
    prevIndex.current = index ?? -1;

    // fill prev value to input
    startTransition(() => {
      const chosenColumn = columnsRow.find((row) => row.columnUuid === condition.columnUuid);
      if (!chosenColumn) return;
      // check column type is text
      if (textTypes.includes(chosenColumn.dataType.type)) {
        const textCondition = condition as TextComparisonCondition;
        // check operator is match
        if (textCondition.operator === '=' || textCondition.operator === '!=') {
          setTextRadio('match');
          setMatchOperator(textCondition.operator);
          setMatchValue(textCondition.value);
        }
        // check operator is like
        if (textCondition.operator === 'LIKE' || textCondition.operator === 'NOT LIKE') {
          setTextRadio('LIKE');
          setLikeOperator(textCondition.operator);
          setLikeValue(textCondition.value);
        }

        return;
      }
      // check column type is number
      if (numberTypes.includes(chosenColumn.dataType.type)) {
        const numberCondition = condition as NumberComparisonCondition;

        setNumOperator(numberCondition.operator);
        setNumValue(numberCondition.value);
        return;
      }
      // check column type is time
      if (timeTypes.includes(chosenColumn.dataType.type)) {
        const timeComparisonCondition = condition as TimeComparisonCondition;
        if (timeComparisonCondition.value) {
          setTimeRadio('sinse');
          setTimeValue(`${timeComparisonCondition.value ?? 0}`);
          setUnit(timeComparisonCondition.unit);
        }
        const timeBetweenCondition = condition as TimeBetweenCondition;
        if (timeBetweenCondition.values) {
          setTimeRadio('between');
          setFromDate(dayjs(timeBetweenCondition.values[0]));
          setToDate(dayjs(timeBetweenCondition.values[1]));
        }
        return;
      }
      // check column type is boolean
      if (booleanTypes.includes(chosenColumn.dataType.type)) {
        const booleanCondition = condition as BooleanComparisonCondition;
        setBoolValue(booleanCondition.value);
      }
    });
  };

  const onClickDeleteWhereCondition = (condition: Where) => {
    const newConditions = formik.values.simple.where.filter(
      (stateCondition) => stateCondition !== condition
    );
    formik.setFieldValue('simple.where', newConditions);
  };

  // 抽出条件の設定Dialog
  const [conditionSettingDialogOpen, setConditionSettingDialogOpen] = useState(false);
  const [dialogWhereCondition, setDialogWhereCondition] = useState<Where | undefined>();

  const handleDialogOk = () => {
    let newCondition: Where | undefined = undefined;
    if (timeTypes.includes(selectedColumn.type)) {
      if (timeRadio === 'sinse') {
        newCondition = {
          type: 'time',
          tableUuid: selectedColumn.tableUuid,
          columnUuid: selectedColumn.columnUuid,
          operator: '>=',
          value: parseFloat(timeValue),
          unit: unit,
        };
      } else {
        const from = fromDate ? fromDate?.toISOString() : '';
        const to = toDate ? toDate?.toISOString() : '';
        newCondition = {
          type: 'time',
          tableUuid: selectedColumn.tableUuid,
          columnUuid: selectedColumn.columnUuid,
          operator: 'between',
          values: [from, to],
        };
      }
    } else if (textTypes.includes(selectedColumn.type)) {
      if (textRadio === 'match') {
        newCondition = {
          type: 'text',
          tableUuid: selectedColumn.tableUuid,
          columnUuid: selectedColumn.columnUuid,
          operator: matchOperator,
          value: matchValue,
        };
      } else {
        newCondition = {
          type: 'text',
          tableUuid: selectedColumn.tableUuid,
          columnUuid: selectedColumn.columnUuid,
          operator: likeOperator,
          value: likeValue,
        };
      }
    } else if (numberTypes.includes(selectedColumn.type)) {
      newCondition = {
        type: 'num',
        tableUuid: selectedColumn.tableUuid,
        columnUuid: selectedColumn.columnUuid,
        operator: numOperator,
        value: numValue,
      };
    } else if (booleanTypes.includes(selectedColumn.type)) {
      newCondition = {
        type: 'bool',
        tableUuid: selectedColumn.tableUuid,
        columnUuid: selectedColumn.columnUuid,
        value: boolValue,
      };
    } else {
      return;
    }
    setDialogWhereCondition(newCondition);
    const newConditionTemp: Where | undefined = newCondition;
    if (prevIndex.current >= 0 && prevIndex.current < formik.values.simple.where.length) {
      formik.values.simple.where[prevIndex.current] = newConditionTemp;
    } else {
      formik.values.simple.where.push(newConditionTemp);
    }
    formik.setFieldValue('simple.where', formik.values.simple.where);
  };

  const [selectedColumn, setSelectedColumn] = React.useState<{
    tableUuid: string;
    columnUuid: string;
    name: string;
    type: string;
  }>({
    tableUuid: '',
    columnUuid: '',
    name: '',
    type: '',
  });

  const otherTypes = ['variant', 'object', 'array'];
  const timeTypes = [
    'date',
    'time',
    'timetz',
    'timestamp',
    'timestamptz',
    'timestamp',
    'timestamp_ltz',
    'timestamp_tz',
    'timestamp_ntz',
  ];
  const textTypes = [
    'char',
    'character',
    'nchar',
    'varchar',
    'character varying',
    'nvarchar',
    'bpchar',
    'text',
    'binary',
  ];
  const numberTypes = [
    'fixed',
    'number',
    'smallint',
    'int2',
    'integer',
    'int',
    'int4',
    'bigint',
    'int8',
    'decimal',
    'numeric',
    'real',
    'float',
    'float4',
    'double precision',
    'float8',
  ];
  const booleanTypes = ['boolean', 'bool'];

  // 時間条件
  const [timeRadio, setTimeRadio] = React.useState('sinse');
  const handleChangeTimeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeRadio((event.target as HTMLInputElement).value);
  };
  const [timeValue, setTimeValue] = React.useState('1');
  const handleChangeTimeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeValue(event.target.value);
  };
  const [unit, setUnit] = React.useState<'year' | 'month' | 'day' | 'hour'>('year');
  const handleChangeUnit = (event: SelectChangeEvent) => {
    if (
      event.target.value === 'year' ||
      event.target.value === 'month' ||
      event.target.value === 'day' ||
      event.target.value === 'hour'
    ) {
      setUnit(event.target.value);
    }
  };
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(dayjs(new Date().toString()));
  const handleFromDateChange: DesktopDatePickerProps<Dayjs>['onChange'] = (newValue) => {
    setFromDate(newValue);
  };
  const [toDate, setToDate] = React.useState<Dayjs | null>(dayjs(new Date().toString()));
  const handleToDateChange: DesktopDatePickerProps<Dayjs>['onChange'] = (newValue) => {
    setToDate(newValue);
  };

  // 文字列条件
  const [textRadio, setTextRadio] = React.useState('match');
  const handleChangeTextRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextRadio((event.target as HTMLInputElement).value);
  };
  const [matchOperator, setMatchOperator] = React.useState<'=' | '!='>('=');
  const handleChangeMatchOperator = (event: SelectChangeEvent) => {
    if (event.target.value === '=' || event.target.value === '!=') {
      setMatchOperator(event.target.value);
    }
  };
  const [likeOperator, setLikeOperator] = React.useState<TextComparisonOperator>('LIKE');
  const handleChangeLikeOperator = (event: SelectChangeEvent) => {
    setLikeOperator(event.target.value as TextComparisonOperator);
  };
  const [matchValue, setMatchValue] = React.useState('');
  const handleChangeMatchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMatchValue(event.target.value);
  };
  const [likeValue, setLikeValue] = React.useState('');
  const handleChangeLikeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLikeValue(event.target.value);
  };
  // 数値条件
  const [numOperator, setNumOperator] = React.useState<NumberComparisonOperator>('=');
  const handleChangeNumOperator = (event: SelectChangeEvent) => {
    setNumOperator(event.target.value as NumberComparisonOperator);
  };
  const [numValue, setNumValue] = React.useState('0');
  const handleChangeNumValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumValue(event.target.value);
  };
  // 真偽値条件
  const [boolValue, setBoolValue] = React.useState<BooleanComparisonValue>('false');
  const handleChangeBoolValue = (event: SelectChangeEvent) => {
    setBoolValue(event.target.value as BooleanComparisonValue);
  };

  // target to show popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const popoverAction = useRef<PopoverActions>(null);

  const [search, setSearch] = useState<string>('');

  const handleUpdatePopoverPosition = () => {
    // find scroll element (main)
    const mainElement = document.querySelector('main.MuiBox-root');
    setTimeout(() => {
      if (mainElement) {
        // get y & height of add button
        const anchorElY = anchorEl?.getBoundingClientRect().y ?? 0;
        const anchorElHeight = anchorEl?.getBoundingClientRect().height ?? 0;
        // caculate distance
        const distance = window.innerHeight - anchorElY - anchorElHeight - 320;

        // if button is over bottom by popup scroll button into view
        if (distance < 0) {
          mainElement.scrollTo({
            top: mainElement.scrollTop - distance,
            behavior: 'smooth',
          });
        }
      }
      setTimeout(() => {
        popoverAction.current?.updatePosition();
      }, 100);
    }, 100);
  };

  // 条件設定ボタン
  const onClickConditionSetting = (columnUuid: string) => {
    onClickSelectionModelChange(columnUuid);
    setConditionSettingDialogOpen(true);
  };

  useEffect(() => {
    boolValue ? setDisabledAddButton(false) : setDisabledAddButton(true);
  }, [boolValue]);

  useEffect(() => {
    if (textRadio === 'match') {
      matchValue ? setDisabledAddButton(false) : setDisabledAddButton(true);
    } else if (textRadio === 'LIKE') {
      likeValue ? setDisabledAddButton(false) : setDisabledAddButton(true);
    }
  }, [textRadio, matchValue, likeValue]);

  const onClickShowDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const notSelectedWithSearchColumn = columnsRow.filter((i) => {
    if (!search) return true;
    const table = joinedTables.find((e) => e.tableUuid === i.tableUuid);
    return `${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${i.name} (${i.dataType.type})`.includes(
      search
    );
  });

  const isOpenPopover = Boolean(anchorEl);
  const popoverId = isOpenPopover ? 'where-condition-popover' : undefined;

  useEffect(() => {
    if (anchorEl && columnsRow.length) {
      handleUpdatePopoverPosition();
    }
  }, [anchorEl, formik.values.simple.where, columnsRow]);

  // clear form when popup is closed
  useEffect(() => {
    if (!conditionSettingDialogOpen) {
      prevIndex.current = -1;
      startTransition(() => {
        setTimeout(() => {
          setTimeRadio('sinse');
          setTimeValue('1');
          setUnit('year');
          setFromDate(dayjs());
          setToDate(dayjs());
          setTextRadio('match');
          setMatchOperator('=');
          setLikeOperator('LIKE');
          setMatchValue('');
          setLikeValue('');
          setNumOperator('=');
          setNumValue('0');
          setBoolValue('false');
        }, 500);
      });
    }
  }, [conditionSettingDialogOpen]);

  const renderColumnSearch = () => {
    return (
      <Grid
        container
        sx={{
          gap: 2,
          mb: 1,
          paddingTop: 2,
          position: 'sticky',
          top: 0,
          backgroundColor: colors.common.white,
          zIndex: 1,
        }}
      >
        <Grid item xs={12}>
          <TextField
            value={search}
            onChange={onChangeSearch}
            fullWidth
            placeholder="項目を入力してください"
          />
        </Grid>
      </Grid>
    );
  };

  const renderColumnArea = () => {
    if (!formik.values?.tableN || !formik.values?.datasource)
      return (
        <>
          {renderColumnSearch()}
          <Grid container item xs={12}>
            <Typography style={{ color: 'red' }}>
              データソースおよびテーブルを選択してください。
            </Typography>
          </Grid>
        </>
      );
    if (isColumnLoading)
      return (
        <Box flex={1} padding={2} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={20} />
        </Box>
      );
    if (columnsRow.length && notSelectedWithSearchColumn.length) {
      return (
        <>
          {renderColumnSearch()}
          {notSelectedWithSearchColumn.map((row) => {
            const table = joinedTables.find((e) => e.tableUuid === row.tableUuid);
            return (
              <MenuItem
                onClick={() => {
                  onClickConditionSetting(row.columnUuid);
                }}
                key={`where_option_${row.columnUuid}`}
                value={row.columnUuid}
              >
                <Box width="100%" flexDirection="row" display="flex">
                  <Box flex={1}>
                    <ListItemText
                      sx={{ wordBreak: 'break-word', whiteSpace: 'pre-wrap' }}
                      secondary={row.dataType.type}
                      primary={`${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${row.name} (${row.dataType.type})`}
                    />
                  </Box>
                  <Box sx={{ paddingLeft: 1 }}>
                    <ListItemText primary="条件設定" />
                  </Box>
                </Box>
              </MenuItem>
            );
          })}
        </>
      );
    }
    return (
      <>
        {renderColumnSearch()}
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ color: 'red' }}>有効な項目がありません。</Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid container marginTop={4} justifyContent="center">
        <Grid item xs={12}>
          <Typography>抽出条件の設定</Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          {formik.values.simple.where.length ? (
            <List sx={{ border: '1px solid #EEEEEE', borderRadius: '5px', padding: 0 }}>
              {formik.values.simple.where.map((condition, index) => {
                const table = joinedTables.find((e) => e.tableUuid === condition.tableUuid);
                const column = table?.columns?.find((e) => e.columnUuid === condition.columnUuid);
                const name = `${table?.alias ?? `${table?.schemaN}.${table?.tableN}`}.${column?.name} (${column?.dataType.type})`;
                return (
                  <div key={`where_condition_${condition.columnUuid}_${index}`}>
                    <ListItem>
                      {name.length > MAX_COLUMN_TEXT_LENGTH ? (
                        <Tooltip
                          placement="bottom-start"
                          sx={{ wordBreak: 'break-word' }}
                          title={convertWhereConditionToString(name, condition)}
                        >
                          <ListItemText
                            sx={{ wordBreak: 'break-word' }}
                            primary={convertWhereConditionToString(
                              shortenColumnName(name),
                              condition
                            )}
                          />
                        </Tooltip>
                      ) : (
                        <ListItemText
                          sx={{ wordBreak: 'break-word' }}
                          primary={convertWhereConditionToString(
                            shortenColumnName(name),
                            condition
                          )}
                        />
                      )}
                      <Tooltip title="編集" placement="top">
                        <IconButton
                          onClick={() => {
                            onClickUpdateSettingWhereCondition(condition, index);
                          }}
                          sx={{ marginRight: 1 }}
                        >
                          <EditOutlined />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="削除" placement="top">
                        <IconButton
                          onClick={() => {
                            onClickDeleteWhereCondition(condition);
                          }}
                        >
                          <Cancel />
                        </IconButton>
                      </Tooltip>
                    </ListItem>
                    {formik.values.simple.where.length === index + 1 ? null : <Divider />}
                  </div>
                );
              })}
            </List>
          ) : null}
          <Button variant="text" aria-describedby={popoverId} onClick={onClickShowDropDown}>
            + 追加
          </Button>
          <Popover
            id={popoverId}
            action={popoverAction}
            open={isOpenPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            PaperProps={{
              sx: {
                maxHeight: 300,
                width: 'min(450px, 100%)',
                paddingX: 2,
                paddingBottom: 2,
                position: 'relative',
              },
            }}
          >
            {renderColumnArea()}
          </Popover>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        maxWidth="md"
        open={conditionSettingDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography variant="h6" marginBottom={2}>
            条件設定
          </Typography>

          <Paper elevation={0} sx={{ backgroundColor: '#FAFAFA' }}>
            <Grid container padding={3} gap={2}>
              <Grid item xs={6}>
                <Typography variant="caption">カラム</Typography>
                <Typography sx={{ wordBreak: 'break-word' }}>{selectedColumn?.name}</Typography>
              </Grid>

              <Grid item xs={5}>
                <Typography variant="caption">データ型</Typography>
                <Typography>{selectedColumn?.type}</Typography>
              </Grid>
            </Grid>
          </Paper>

          {/* 時間条件 */}
          {/* 新デザイン適用OK */}
          {timeTypes.includes(selectedColumn?.type) ? (
            <Grid container padding={2}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    defaultValue="sinse"
                    value={timeRadio}
                    onChange={handleChangeTimeRadio}
                  >
                    <FormControlLabel value="sinse" control={<Radio />} label="直近" />
                    <Box
                      sx={{
                        marginLeft: 4,
                        marginBottom: 2,
                      }}
                    >
                      <TextField
                        id="outlined-number"
                        type="number"
                        value={timeValue}
                        onChange={handleChangeTimeValue}
                        sx={{ marginRight: 1 }}
                      />
                      <FormControl>
                        <Select id="demo-simple-select" value={unit} onChange={handleChangeUnit}>
                          <MenuItem value={'year'}>年</MenuItem>
                          <MenuItem value={'month'}>ヶ月</MenuItem>
                          <MenuItem value={'day'}>日</MenuItem>
                          <MenuItem value={'hour'}>時間</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <FormControlLabel value="between" control={<Radio />} label="日付指定" />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        sx={{
                          display: 'flex',
                          marginLeft: 4,
                          marginBottom: 2,
                        }}
                      >
                        <DesktopDatePicker
                          format="YYYY[年]MM[月]DD[日]"
                          value={fromDate}
                          onChange={handleFromDateChange}
                        />
                        <Typography margin={2}>
                          <HorizontalRule />
                        </Typography>
                        <DesktopDatePicker
                          format="YYYY[年]MM[月]DD[日]"
                          value={toDate}
                          onChange={handleToDateChange}
                        />
                      </Box>
                    </LocalizationProvider>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          {/* 文字列条件 */}
          {/* 新デザイン適用OK */}
          {textTypes.includes(selectedColumn?.type) && dialogWhereCondition?.type === 'text' ? (
            <Grid container padding={2}>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    defaultValue="match"
                    value={textRadio}
                    onChange={handleChangeTextRadio}
                  >
                    <FormControlLabel value="match" control={<Radio />} label="一致/不一致" />
                    <Box
                      sx={{
                        marginLeft: 4,
                        marginBottom: 2,
                      }}
                    >
                      <FormControl sx={{ marginRight: 1 }}>
                        <Select
                          id="match-operator"
                          value={matchOperator}
                          onChange={handleChangeMatchOperator}
                        >
                          <MenuItem value={'='}>=</MenuItem>
                          <MenuItem value={'!='}>!=</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        id="match-value"
                        type="text"
                        value={matchValue}
                        onChange={handleChangeMatchValue}
                      />
                    </Box>
                    <FormControlLabel value="LIKE" control={<Radio />} label="部分一致" />
                    <Box
                      sx={{
                        marginLeft: 4,
                        marginBottom: 2,
                      }}
                    >
                      <FormControl sx={{ marginRight: 1 }}>
                        <Select
                          id="like-operator"
                          value={likeOperator}
                          onChange={handleChangeLikeOperator}
                        >
                          <MenuItem value={'LIKE'}>LIKE</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        id="like-value"
                        type="text"
                        value={likeValue}
                        onChange={handleChangeLikeValue}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          {/* 数値条件 */}
          {/* 新デザイン適用OK */}
          {numberTypes.includes(selectedColumn?.type) ? (
            <Grid container padding={2}>
              <Grid item xs={12}>
                <Box>
                  <FormControl sx={{ marginRight: 1 }}>
                    <Select
                      id="number-operator"
                      value={numOperator}
                      onChange={handleChangeNumOperator}
                    >
                      <MenuItem value={'='}>=</MenuItem>
                      <MenuItem value={'<='}>{'<='}</MenuItem>
                      <MenuItem value={'>='}>{'>='}</MenuItem>
                      <MenuItem value={'!='}>!=</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    id="num-value"
                    type="number"
                    value={numValue}
                    onChange={handleChangeNumValue}
                  />
                </Box>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          {/* 真偽値条件 */}
          {/* 新デザイン適用OK */}
          {booleanTypes.includes(selectedColumn?.type) && dialogWhereCondition?.type === 'bool' ? (
            <Grid container padding={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <FormControl>
                    <Select id="number-operator" value={boolValue} onChange={handleChangeBoolValue}>
                      <MenuItem value={'true'}>True</MenuItem>
                      <MenuItem value={'false'}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          {/* 真偽値条件 */}
          {selectedColumn?.type !== undefined &&
          ![...textTypes, ...timeTypes, ...numberTypes, ...booleanTypes, ...otherTypes].includes(
            selectedColumn?.type
          ) ? (
            <Grid container padding={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <Typography>対応していないデータ型です</Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}

          {/* 空条件 */}
          {selectedColumn?.type === undefined ? (
            <Grid container padding={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    marginLeft: 4,
                    marginBottom: 2,
                  }}
                >
                  <Typography>カラムを選択してください</Typography>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </DialogContent>

        <DialogActions sx={{ margin: 4 }}>
          <Button
            onClick={() => setConditionSettingDialogOpen(false)}
            color="inherit"
            sx={{ marginRight: 2 }}
          >
            キャンセル
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleDialogOk(), setConditionSettingDialogOpen(false);
            }}
            color="primary"
            autoFocus
            disabled={
              disabledAddButton ||
              (selectedColumn?.type !== undefined &&
                ![...textTypes, ...timeTypes, ...numberTypes, ...booleanTypes].includes(
                  selectedColumn?.type
                ))
            }
          >
            追加
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WhereConditionParts;
